<template>
  <div>
    <div class="flex items-start justify-between mt-10 relative">
      <h2 class="intro-y text-lg font-medium -top-1">{{ $t('views.receipes.text1') }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-12">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <p class="mr-auto">{{ $t('views.receipes.text3') }}</p>
        <!-- <div class="cursor w-full sm:w-56 mt-3 sm:mt-0 sm:ml-auto md:ml-0 mr-5">
          <div class="w-full relative text-gray-700 dark:text-gray-300">
            <TomSelect
              class="w-full cursor"
              v-model="ingredients"
              :placeholder="$t('views.receipes.text4')"
            >
              <option value="0">{{ $t('views.receipes.text4') }}</option>
              <option v-for="(food, key) in foods" :key="key" :value="food.id">{{ food.name }}</option>
            </TomSelect>
          </div>
        </div> -->
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13"
              :placeholder="$t('views.receipes.text5')" v-model="searchInput" />
            <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-7 gap-6 w-full col-span-12 justify-between">
        <div class="grid grid-cols-3 col-span-7 xl:col-span-5 gap-6 order-2 xl:order-1">
          <div class="cursor w-full mt-3 sm:mt-0 col-span-3 sm:col-span-1">
            <h3 class="intro-y text-base font-medium mb-2">Usuario</h3>
            <div class="w-full h-12 xl:h-12 relative text-gray-700 dark:text-gray-300 categories-filter">
              <TomSelect class="w-full h-full cursor " v-model="user">
                <option :value="0">{{ "Seleccione" }}</option>
                <option v-for="(user, key) in users" :key="key" :value="user.id" :label="user.session_name">
                  <span>{{ user.session_name }}</span>
                </option>
              </TomSelect>
            </div>
          </div>
          <div class="col-span-3 sm:col-span-2">
            <h3 class="intro-y text-base font-medium mb-2">Rango de fechas</h3>
            <div class="flex gap-6 h-12 xl:h-12">
              <div class="cursor w-full h-full mt-3 sm:mt-0">
                <div class="w-full h-full relative text-gray-700 dark:text-gray-300">
                  <Litepicker id="date_start" :options="{ format: 'DD-MM-YYYY', noDefault: true }" v-model="date_start"
                    class="datepicker form-control rounded w-full h-full block" :placeholder="`Desde ${today}`">
                  </Litepicker>
                </div>
              </div>
              <div class="cursor w-full h-full mt-3 sm:mt-0">
                <div v-if="startDate !== false" class="w-full h-full relative text-gray-700 dark:text-gray-300">
                  <LitepickerEnd id="date_end" :options="{ format: 'DD-MM-YYYY', noDefault: true, minDate: startDate }"
                    v-model="date_end" class="datepicker form-control rounded w-full h-full block"
                    :placeholder="`Hasta ${today}`"></LitepickerEnd>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="flex gap-8 w-full col-span-7 xl:col-span-2 justify-start sm:justify-end order-1 xl:order-2">
          <div class="flex flex-col gap-2 justify-center items-center">
            <h3 class="intro-y text-base font-medium">Total cargados</h3>
            <span class="flex justify-center items-center rounded-full bg-custom-gray-9 w-16 h-16">{{ total_charge
              }}</span>
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2 min-w-max lg:min-w-0">
          <thead>
            <tr>
              <th class="whitespace-nowrap">
                <div class="grid grid-cols-4 w-full">
                  <span class="font-medium whitespace-nowrap w-full">{{ $t('views.receipes.text6') }}</span>
                  <span class="font-medium whitespace-nowrap truncate col-span-3 hidden">{{ $t('views.receipes.text7')
                    }}</span>
                </div>
              </th>
              <th class="text-center whitespace-nowrap">{{ $t('views.receipes.text8') }}</th>
            </tr>
          </thead>
          <tbody id="receipe-accordion-1" class="accordion">
            <template v-for="(item, itemKey) in receipes" :key="itemKey+item.id">
              <tr class="intro-x relative">
                <td class="p-0">
                  <div class="accordion-item">
                    <div :id="'receipe-content-' + item.id" class="accordion-header">
                      <button class="accordion-button collapsed text-left p-5 flex items-center gap-4 w-full"
                        type="button" data-bs-toggle="collapse" :data-bs-target="'#receipe-accordion-collapse-' + item.id"
                        :aria-expanded="item.show" :aria-controls="'receipe-accordion-collapse-' + item.id"
                        @click="setShow(item)">
                        <div class="grid grid-cols-3-custom w-full gap-4">
                          <span class="font-medium text-gray-800 dark:text-white">{{
        item.name
      }}</span>
                          <!--<div class=" flex justify-between">
                                  <span class="truncate description mr-4 text-gray-800 dark:text-white">
                                    <Tippy v-show="!item.show" data-theme="light" :content="item.food_recipe_pro_cons_unit">{{ item.food_recipe_pro_cons_unit}}</Tippy>
                                  </span>
                              </div>-->
                          <div class="flex"
                            v-bind:class="(item.show) ? ' absolute right-8 top-6 z-20 ' : 'justify-between'">
                            <div class="alert show py-1 px-4 w-max h-max-content text-white"
                              v-bind:class="item.g_index.color" role="alert">{{ $t('views.receipes.text9') }}: {{
        item.g_index.text }}</div>
                          </div>
                        </div>
                      </button>
                    </div>

                    <div :id="'receipe-accordion-collapse-' + item.id" class="accordion-collapse collapse p-5 pt-0"
                      :aria-labelledby="'receipe-content-' + item.id" data-bs-parent="#receipe-accordion-1"
                      style="display : none">
                      <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                        <p class="dark:text-white font-medium">{{ $t('views.receipes.text7') }}: </p>
                        <p>{{ item.description }}</p>
                        <!--<p class="dark:text-white mt-4 block font-medium">{{ $t('views.receipes.text10') }}: </p>
                            <div class="flex flex-wrap gap-4 max-w-full mt-1">
                              <template v-for="(procron, itemKey) in item.food_recipe_pro_cons" :key="itemKey">
                                <div class="receipe-item alert show py-1 px-4 w-max h-max-content btn-primary">{{ procron.description }}</div>
                              </template>
</div>-->
                        <h2 class="mt-4 dark:text-white font-medium">{{ $t('views.receipes.text11') }}:</h2>
                        <div class="grid grid-cols-1 md:grid-cols-2">
                          <div class="flex items-center mt-4"
                            v-for="(ingredient, ingKey) in item.food_recipe_ingredients" :key="ingKey">
                            <div class="w-2 h-2 bg-theme-13 rounded-full mr-3"></div>
                            <span class="truncate">{{ ingredient.food.name }} ({{ ingredient.quantity }} {{
        setUnit(ingredient.unit_id)
      }})</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="table-report__action w-56 relative" style="width:25px">
                  <div class="flex justify-center items-center">
                    <a class="flex items-center mr-3" href="javascript:;" @click="editReceipeItem(item)">
                      <CheckSquareIcon class="w-4 h-4 mr-1" />
                      {{ $t('views.edit') }}
                    </a>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-if="loading">
              <td colspan="2" class="p-4">
                {{ $t("views.loading") }}
                <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
              </td>
            </tr>
            <tr v-if="!loading && receipes.length === 0">
              <td colspan="2" class="p-4">
                {{ $t("views.no_data") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
        v-if="receipes.length > 0">
        <ul class="pagination" v-if="pagination.total_pages > 1">
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('prev')">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('first')"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('first') }" href="javascript:;"
              @click="goPage(getNumberPgae('first'))">{{ getNumberPgae("first") }}</a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('second')"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('second') }"
              href="javascript:;" @click="goPage(getNumberPgae('second'))">{{ getNumberPgae("second") }}</a>
          </li>
          <li v-if="getNumberPgae('second') != getNumberPgae('') && pagination.total_pages >= getNumberPgae('')">
            <a class="pagination__link"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('') }" href="javascript:;"
              @click="goPage(getNumberPgae(''))">{{ getNumberPgae("") }}</a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('next')">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(pagination.total_pages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div class="hidden md:block ml-auto text-gray-600 ml-auto">
          {{ $t('views.pagination.items', {total: receipes.length}) }}
        </div>
        <select class="w-20 form-select box mt-3 sm:mt-0 ml-5" v-model="pagination.per_page"
          @change="() => { changePerPage() }">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<style scoped>
.cursor {
  cursor: pointer !important;
}

.table td.p-0 {
  padding: 0px !important;
}

.bg-color-1 {
  background-color: #828181;
}

.truncate.description {
  max-width: 35vw;
}

.h-max-content {
  height: max-content;
}

@media (max-width: 1024px) {
  .truncate.description {
    max-width: 50vw;
  }
}

@media (max-width: 600px) {
  .truncate.description {
    max-width: 100vw;
  }
}
</style>

<style>
.tom-select .ts-dropdown .option.active {
  background: #6c869a !important;
}

.top-bar .breadcrumb>a:not(.breadcrumb--active) {
  color: #6c869a !important;
}

.detail-food {
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: initial;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pagination li,
.pagination a,
.pagination a:focus,
.pagination a:active {
  outline: none !important;
}

.btn-primary {
  background: #6c869a !important;
  border-color: #6c869a !important;
}

.grid-cols-3-custom {
  grid-template-columns: 1fr 2.25fr 0.75fr;
}
</style>

<script>
import ReceipeService from "/src/services/receipes.service";
import FoodService from "/src/services/food.service";
import UserService from "/src/services/user.service";
import _ from 'lodash';
import moment from 'moment';

export default {
  setup() {
  },
  data() {
    return {
      receipes: [],
      searchInput: "",
      quizToSearch: 0,
      pagination: {
        page: 1,
        total_pages: 3,
        per_page: 50,
        total: 0
      },
      categories: [],
      category: 0,
      foods: [],
      ingredients: "",
      glycemin_index: [
        { color: 'alert-secondary', text: this.$t('views.receipes.glycemic_index.none') },
        { color: 'bg-green-600', text: this.$t('views.receipes.glycemic_index.low') },
        { color: 'bg-yellow-600', text: this.$t('views.receipes.glycemic_index.moderate') },
        { color: 'bg-red-600', text: this.$t('views.receipes.glycemic_index.high') },
      ],
      loading: true,
      units: [],
      users: [],
      user: null,
      date_start: "",
      startDate: null,
      date_end: "",
      total_charge: null,
      moment: moment,
      today: moment().format('DD-MM-YYYY')
    }
  },
  watch: {
    searchInput: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getReceipeItems();
    }, 500),
    user: function () {
      this.pagination.page = 1
      this.getReceipeItems();
    },
    date_start: function () {
      this.startDate = false
      setTimeout(() => {
        this.startDate = (this.date_start !== "") ? `${this.date_start.split("-")[2]}-${this.date_start.split("-")[1]}-${this.date_start.split("-")[0]}` : null
      }, 20);
      this.pagination.page = 1
      this.getReceipeItems();
    },
    date_end: function () {
      this.pagination.page = 1
      this.getReceipeItems();
    },
    '$i18n.locale': function (l) {
      this.getReceipeItems();
      this.getFoods()
      this.getUnits()
    }
  },
  created() {
    window.vue = this
    this.getReceipeItems();
    this.getFoods()
    this.getUnits()
    this.getUsers()
  },
  methods: {
    getReceipeItems() {
      var params = {
        search: this.searchInput.replaceAll(" ", "_"),
        user_id: (this.user > 0) ? this.user : null,
        date_start: (this.date_start !== "") ? `${this.date_start.split("-")[2]}-${this.date_start.split("-")[1]}-${this.date_start.split("-")[0]}` : null,
        date_end: (this.date_end !== "") ? `${this.date_end.split("-")[2]}-${this.date_end.split("-")[1]}-${this.date_end.split("-")[0]}` : null,
        pagination: this.pagination,
        request_receipes_by_user: true
      }

      var _this = this
      ReceipeService.getAll(params).then((response) => {
        this.loading = false
        this.receipes = (response.data) ? response.data : response
        this.pagination.total_pages = (response.pagination.total && response.pagination.per_page) ? Math.ceil(response.pagination.total / response.pagination.per_page) : 0
        this.pagination.total = (response.pagination.total) ? response.pagination.total : response.pagination.length
        this.pagination.per_page = (response.pagination.per_page) ? response.pagination.per_page : 50
        this.total_charge = response.pagination.total;
        var _this = this
        this.receipes.map(function (f) {
          f.show = false
          f.g_index = _this.glycemin_index[_this.getGlycemicIndex(f)]
          f.food_recipe_pro_cons_unit = f.food_recipe_pro_cons.map(p => p.description)
          f.food_recipe_pro_cons_unit = f.food_recipe_pro_cons_unit.join(", ")
          return f
        })

      }).catch(function (e) {
        _this.loading = false
      });
    },
    getFoods: function () {
      var params = {}
      FoodService.getFoodItems(params).then((response) => {
        this.foods = (response.data) ? response.data : response
        this.foods = this.foods.map(function (f) {
          return { id: f.id, name: f.name }
        })
      })
    },
    getUnits: function (lang = null) {
      ReceipeService.getUnits(lang).then((response) => {
        this.units = (response.data) ? response.data : response
      })
    },
    setUnit(unit) {
      var u = this.units.find(u => u.id === unit)
      return u ? u.value : ""
    },
    getUsers() {
      UserService.getAdminUsers().then((response) => {
        this.users = (response.data) ? response.data : response
      })
    },
    editReceipeItem(receipeItem) {
      localStorage.setItem('receipeItem', JSON.stringify(receipeItem));
      this.$router.push({ path: '/receipe-edit' });
    },
    newReceipeItem(receipeItem) {
      localStorage.removeItem('receipeItem');
      this.$router.push({ path: '/receipe-new' });
    },
    goPage: function (param) {
      if (param == 'prev') {
        this.pagination.page = (this.pagination.page > 1) ? this.pagination.page - 1 : 1
      } else if (param == 'next') {
        this.pagination.page = (this.pagination.page < this.pagination.total_pages) ? this.pagination.page + 1 : this.pagination.total_pages
      } else {
        this.pagination.page = param
      }

      this.getReceipeItems();
    },
    getNumberPgae(type) {
      if (type == "first") {
        return (this.pagination.page < 3) ? 1 : this.pagination.page - 1
      } else if (type == "second") {
        return (this.pagination.page > 2) ? this.pagination.page : 2
      } else {
        return (this.pagination.page > 2) ? (this.pagination.page + 1 > this.pagination.total_pages) ? this.pagination.total_pages : this.pagination.page + 1 : 3
      }
    },
    changePerPage() {
      this.pagination.page = 1
      this.pagination.per_page = Number(this.pagination.per_page)
      this.getReceipeItems()
    },
    getGlycemicIndex(item) {
      var index = 0
      index = item.categories.includes(5) ? 1 : index
      index = item.categories.includes(6) ? 2 : index
      index = item.categories.includes(96) ? 3 : index
      return index
    },
    setShow(item) {
      var show = item.show
      this.receipes.map(function (f) {
        f.show = false
        return f
      })

      item.show = !show
    }
  }
}
</script>
