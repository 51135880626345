import { createRouter, createWebHistory } from "vue-router"
import SideMenu from "../layouts/side-menu/Main.vue"
import SimpleMenu from "../layouts/simple-menu/Main.vue"
import TopMenu from "../layouts/top-menu/Main.vue"
import Login from "../views/login/Main.vue"
import CheckAdmin from "../views/login/CheckAdmin.vue"
import Register from "../views/register/Main.vue"
import ErrorPage from "../views/error-page/Main.vue"

import LabAnalysisList from "../views/lab-analysis/Main.vue"
import LabAnalysisEdit from "../views/lab-analysis/Edit.vue"

import FoodList from "../views/food/Main.vue"
import FoodListByUser from "../views/food/ListByUser.vue"
import Food from "../views/food/Edit.vue"
import FoodEdit from "../views/food/Edit-2.vue"

import SupplementList from "../views/supplements/Main.vue"
import Supplement from "../views/supplements/Edit.vue"

import MedicationList from "../views/medication/Main.vue"
import MedicationEdit from "../views/medication/Edit.vue"
import MedicationFamilyList from "../views/medication/family/Main.vue"
import MedicationFamilyEdit from "../views/medication/family/Edit.vue"

import DiseaseList from "../views/disease/Main.vue"
import DiseaseEdit from "../views/disease/Edit.vue"

import SessionsList from "../views/recommendations-comparison/Sessions-list.vue"
import RecommendationComparison from "../views/recommendations-comparison/Recommendation-comparison.vue"

import ReceipesList from "../views/receipes/Main.vue"
import Receipe from "../views/receipes/Edit.vue"
import ReceipesListByUser from "../views/receipes/MainByUser.vue"

import ActivitiesList from "../views/activities/Main.vue"
import Activity from "../views/activities/Edit.vue"

import AIInput from "../views/ai/Input.vue"

import Cycle from "../views/cycle/Main.vue"

import Users from "../views/users/Main.vue"
import UsersResults from "../views/users/Results.vue"

import GlossaryList from "../views/glossary/Main.vue"
import Glossary from "../views/glossary/Edit.vue"

import { TokenService, Recipes } from "../services/storage.service"

import { useStore } from "@/store"

const routes = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "/",
        redirect: to => {
          const isAdmin = TokenService.isAdmin()
          if (!isAdmin) {
            return "/check"
          } else {
            return "/lab-analysis-list"
          }
        }
      }
    ]
  },
  {
    path: "/check",
    name: "check-admin",
    component: CheckAdmin
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage
  },
  {
    path: "/lab-analysis-list",
    component: SideMenu,
    children: [
      {
        path: "/lab-analysis-list",
        name: "lab-analysis-list",
        component: LabAnalysisList
      },
      {
        path: "/lab-analaysis-edit",
        name: "lab-analaysis-edit",
        component: LabAnalysisEdit
      }
    ]
  },
  {
    path: "/food",
    component: SideMenu,
    children: [
      {
        path: "/food-list",
        name: "food-list",
        component: FoodList
      },
      {
        path: "/food-list-by-user",
        name: "food-list-by-user",
        component: FoodListByUser
      },
      {
        path: "/food-edit",
        name: "food-edit",
        component: Food
      },
      {
        path: "/food-new",
        name: "food-new",
        component: Food
      },
      {
        path: "/food-edit-2",
        name: "food-edit-2",
        component: FoodEdit
      }
    ]
  },
  {
    path: "/supplement",
    component: SideMenu,
    children: [
      {
        path: "/supplement-list",
        name: "supplement-list",
        component: SupplementList
      },
      {
        path: "/supplement-edit",
        name: "supplement-edit",
        component: Supplement
      },
      {
        path: "/supplement-new",
        name: "supplement-new",
        component: Supplement
      }
    ]
  },
  {
    path: "/medication",
    component: SideMenu,
    children: [
      {
        path: "/medication-list",
        name: "medication-list",
        component: MedicationList
      },
      {
        path: "/medication-new",
        name: "medication-new",
        component: MedicationEdit
      },
      {
        path: "/medication-edit",
        name: "medication-edit",
        component: MedicationEdit
      },
      {
        path: "/medication-family-list",
        name: "medication-family-list",
        component: MedicationFamilyList
      },
      {
        path: "/medication-family-new",
        name: "medication-family-new",
        component: MedicationFamilyEdit
      },
      {
        path: "/medication-family-edit",
        name: "medication-family-edit",
        component: MedicationFamilyEdit
      }
    ]
  },
  {
    path: "/disease",
    component: SideMenu,
    children: [
      {
        path: "/disease-list",
        name: "disease-list",
        component: DiseaseList
      },
      {
        path: "/disease-edit",
        name: "disease-edit",
        component: DiseaseEdit
      },
      {
        path: "/disease-new",
        name: "disease-new",
        component: DiseaseEdit
      }
    ]
  },
  {
    path: "/sessions",
    component: SideMenu,
    children: [
      {
        path: "/sessions-list",
        name: "sessions-list",
        component: SessionsList
      },
      {
        path: "/recommendation-comparison",
        name: "recommendation-comparison",
        component: RecommendationComparison
      },
      {
        path: "/user-results",
        name: "user-results",
        component: UsersResults
      }
    ]
  },
  {
    path: "/receipes",
    component: SideMenu,
    children: [
      {
        path: "/receipes-list",
        name: "receipes-list",
        component: ReceipesList
      },
      {
        path: "/receipes-list-by-user",
        name: "receipes-list-by-user",
        component: ReceipesListByUser
      },
      {
        path: "/receipe-edit",
        name: "receipe-edit",
        component: Receipe
      },
      {
        path: "/receipe-new",
        name: "receipe-new",
        component: Receipe
      }
    ]
  },
  {
    path: "/glossary",
    component: SideMenu,
    children: [
      {
        path: "/glossary-list",
        name: "glossary-list",
        component: GlossaryList
      },
      {
        path: "/glossary-edit",
        name: "glossary-edit",
        component: Glossary
      },
      {
        path: "/glossary-new",
        name: "glossary-new",
        component: Glossary
      }
    ]
  },
  {
    component: SideMenu,
    children: [
      {
        path: "/ai-input",
        name: "ai-input",
        component: AIInput
      }
    ]
  },
  {
    path: "/cycle",
    component: SideMenu,
    children: [
      {
        path: "/cycle",
        name: "cycle",
        component: Cycle
      }
    ]
  },
  ,
  {
    path: "/activities",
    component: SideMenu,
    children: [
      {
        path: "/activities-list",
        name: "activities-list",
        component: ActivitiesList
      },
      {
        path: "/activity-edit",
        name: "activity-edit",
        component: Activity
      },
      {
        path: "/activity-new",
        name: "activity-new",
        component: Activity
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const isAdmin = TokenService.isAdmin()
  const isVerify = TokenService.isVerify()
  const isLocal = TokenService.isLocal()

  const recipeIsSaving = Recipes.isSaving()
  const store = useStore()

  if (
    ((from.path == "/receipe-new" && to.path != "/receipe-new") ||
      (from.path == "/receipe-edit" && to.path != "/receipe-edit")) &&
    recipeIsSaving
  ) {
    store.dispatch("main/setShowWarningSave", to.path)
    return next({
      path: from.path
    })
  }

  if (to.path == "/check") {
    //t when it comes from redirect console.
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("t")) {
      localStorage.token = urlParams.get("t")
    }
  }

  if ((!isAdmin || !isVerify) && to.path != "/check" && !isLocal) {
    return next({
      path: "/check",
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    })
  }

  if (isAdmin && isVerify && (to.path == "/check" || to.path == "/")) {
    var permissions = localStorage.backoffice_permissions
      ? JSON.parse(localStorage.backoffice_permissions)
      : []
    var key = permissions.length > 0 ? permissions[0] : null

    var paths = {
      LAB: "/lab-analysis-list",
      FOOD: "/food-list",
      RECIPES: "/receipes-list",
      SUPPLEMENTS: "/supplement-list",
      ACTIVITIES: "/activities",
      SESSIONS: "/sessions-list",
      AI: "/ai-input"
    }

    var path =
      key && key.name && paths[key.name]
        ? paths[key.name]
        : permissions.length === 0
        ? "/food-list"
        : null

    if (path === null) {
      localStorage.clear()
      window.location.href = process.env.VUE_APP_CLIENT_URL
    }

    return next({
      path: path
    })
  }

  next()
})

export default router
