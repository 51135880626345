import ApiService from "./api.service"

/**
 * Declara un objeto con las funciones de activities
 *
 * getAll --> función que realiza una petición POST al enterpoint, la cual indica con parámetros los datos que se van a enviar (información que se obtiene).
 * la función espera la RÉPONSE de la api y una vez que la recibe , devuelve los datos obtenidos en la respuesta.
 *
 * getALLMoods --> función que realiza una petición GET al enterpoint, a la cual le solicita datos basados en el idioma
 * la función espera la RÉPONSE de la API y una vez que se recibe, se ejecuta la función y devuelve la respuesta del servidor
 *
 * save --> función que realiza una petición POST al enterpoint, para enviar los datos junto con el idioma que se especificó  (información para crear o actualizar un recursos en el servidor).
 * la función espera la RÉPONSE de la api y una vez que la recibe , devuelve los datos obtenidos en la respuesta
 *
 * remove --> función que realiza una petición GET al enterpoint, a la cual le solicita que el id con el idioma especificado se elimine ,
 * la función espera la RÉPONSE de la api y muestra la respuesta.
 *
 * */

const ActivitiesService = {
  getAll: async function(params) {
    const response = await ApiService.post(
      "/api/admin/healthy-activity/get",
      params
    )
    return response.data
  },

  getAllMoods: async function(lang = null) {
    const response = await ApiService.get(
      "/api/admin/healthy-activity/get-moods",
      lang
    )
    return response.data
  },

  save: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/healthy-activity/save",
      params,
      lang
    )
    return response.data
  },

  remove: async function(id, lang = null) {
    const response = await ApiService.get(
      "/api/admin/healthy-activity/delete?id=" + id,
      lang
    )
    return response.data
  }
}

export default ActivitiesService
