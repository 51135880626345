import * as types from "./mutation-types"

const state = () => {
  return {
    darkMode:
      localStorage.getItem("darkMode") !== null
        ? localStorage.getItem("darkMode") === "true"
        : true,
    authError: false,
    verifyData: { title: "", text: "", placeholder: "", timeout: 0 },
    accessDenied: false,
    accessDeniedMessage: "",
    showWarningSave: false
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode,
  authError: state => state.authError,
  verifyData: state => state.verifyData,
  accessDenied: state => state.accessDenied,
  accessDeniedMessage: state => state.accessDeniedMessage,
  showWarningSave: state => state.showWarningSave
}

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
  },
  setAuthError({ commit }, authError) {
    commit(types.SET_AUTH_ERROR, { authError })
  },
  setVerifyData({ commit }, verifyData) {
    commit(types.SET_VERIFY_DATA, { verifyData })
  },
  setAccessDenied({ commit }, accessDenied) {
    commit(types.SET_ACCESS_DENIED, { accessDenied })
  },
  setAccessDeniedMessage({ commit }, accessDeniedMessage) {
    commit(types.SET_ACCESS_DENIED_MESSAGE, { accessDeniedMessage })
  },
  setShowWarningSave({ commit }, showWarningSave) {
    commit(types.SET_SHOW_WARNING_SAVE, { showWarningSave })
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode
    localStorage.darkMode = darkMode
  },
  [types.SET_AUTH_ERROR](state, { authError }) {
    state.authError = authError
  },
  [types.SET_VERIFY_DATA](state, { verifyData }) {
    state.verifyData = verifyData
  },
  [types.SET_ACCESS_DENIED](state, { accessDenied }) {
    state.accessDenied = accessDenied
  },
  [types.SET_ACCESS_DENIED_MESSAGE](state, { accessDeniedMessage }) {
    state.accessDeniedMessage = accessDeniedMessage
  },
  [types.SET_SHOW_WARNING_SAVE](state, { showWarningSave }) {
    state.showWarningSave = showWarningSave
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
