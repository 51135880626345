import Chart from "./chart/Main.vue"
import GoogleMapLoader from "./google-map-loader/Main.vue"
import Highlight from "./highlight/Main.vue"
import Litepicker from "./litepicker/Main.vue"
import LitepickerEnd from "./litepickerEnd/Main.vue"
import Tippy from "./tippy/Main.vue"
import TippyContent from "./tippy-content/Main.vue"
import TomSelect from "./tom-select/Main.vue"
import TomSelectV2 from "./tom-select-v2/Main.vue"
import LoadingIcon from "./loading-icon/Main.vue"
import CustomIcon from "./custom-icon/Main.vue"
import TinySlider from "./tiny-slider/Main.vue"
import CKEditor from "./ckeditor/Main.vue"
import Dropzone from "./dropzone/Main.vue"
import FullCalendar from "./calendar/Main.vue"
import FullCalendarDraggable from "./calendar/Draggable.vue"
import * as featherIcons from "@zhuowenli/vue-feather-icons"

export default app => {
  app.component("Chart", Chart)
  app.component("GoogleMapLoader", GoogleMapLoader)
  app.component("Highlight", Highlight)
  app.component("Litepicker", Litepicker)
  app.component("LitepickerEnd", LitepickerEnd)
  app.component("Tippy", Tippy)
  app.component("TippyContent", TippyContent)
  app.component("TomSelect", TomSelect)
  app.component("LoadingIcon", LoadingIcon)
  app.component("CustomIcon", CustomIcon)
  app.component("TinySlider", TinySlider)
  app.component("Dropzone", Dropzone)
  app.component("CKEditor", CKEditor)
  app.component("FullCalendar", FullCalendar)
  app.component("FullCalendarDraggable", FullCalendarDraggable)
  app.component("TomSelectV2", TomSelectV2)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24"
    app.component(key, icon)
  }
}
