<template>
  <div class="my-4 receipe-item">
    <div class="flex gap-y-8 flex-col sm:flex-row mt-10 sm:mt-auto">
      <TomSelect ref="receipeItem" class="w-full sm:w-1/3 cursor mr-8" v-model="receipeItem.food_recipe_child_id"
        :placeholder="'Seleccione una receta'" @change="changeReceipe" :maxOptions="receipes.length">
        <option value="0">{{ 'Seleccione una receta' }}</option>
        <option v-for="(r, key) in receipes" :key="key" :value="r.id">{{ r.name }}</option>
      </TomSelect>
      <div v-if="receipeItem.gi_category && this.receipeItem.gi_category != ''"
        class="alert show py-1 px-4 w-1/6 flex justify-center items-center mr-8" v-bind:class="getIndexGI('color')"
        role="alert">
        <Tippy data-theme="light" :content="$t('components.glycemic_index')">{{ getIndexGI('text') }}</Tippy>
      </div>
      <div v-else class="alert alert-secondary show py-1 px-4 w-1/6 flex justify-center items-center mr-8" role="alert">
        <Tippy data-theme="light" :content="$t('components.glycemic_index') + ':' + $t('components.not_apply')">-
        </Tippy>
      </div>
      <input type="text" name="chart-tooltip" class="form-control w-full sm:w-1/5 mr-8"
        :placeholder="$t('components.quantity')" aria-describedby="input-group-unit" v-model="receipeItem.quantity"
        @blur="add" v-on:keyup.enter="add" @change="enableGEquivalent" />
      <div
        :class="'w-full sm:w-1/4 cursor mr-6 rounded border ' + ((showReceipeItemErrors && (receipeItem.unit_id === '0' || receipeItem.unit_id === '') && receipeItem.food_recipe_child_id !== '0' && receipeItem.food_recipe_child_id !== '') ? 'border-red-600' : 'border-transparent')">
        <TomSelect ref="unit" :class="''" v-model="receipeItem.unit_id" :create="false"
          :placeholder="$t('components.select_unit')" @change="changeUnit">
          <option value="0">{{ $t('components.select_unit') }}</option>
          <option v-for="(unit, key) in units" :key="key" :value="unit.id">{{ unit.value }}</option>
        </TomSelect>
      </div>
      <input v-bind:class="`${receipeItem.unit_id == 2 ? 'opacity-50' : 'opacity-1'}`"
        :disabled="receipeItem.unit_id == 2" type="number" name="g_equivalent" class="form-control w-full sm:w-1/5 mr-8"
        placeholder="gr" aria-describedby="input-group-unit" v-model="receipeItem.g_equivalent"
        v-on:keydown="getGEquivalent" @blur="add" v-on:keyup.enter="add" />
      <button v-on:click="remove(receipeItem)" type="button" class="btn-outline-dark btn-rounded btn-sm remove-item">
        <XCircleIcon class="btn-source-icon" />
      </button>

    </div>
  </div>
</template>
<style>
.dark .tom-select .ts-dropdown {
  width: 100% !important;
}

.tom-select .ts-input .item {
  display: inline-flex;
  align-items: center;
}

.dark .tom-select .ts-input {
  height: 100%;
}
</style>
<script>
import { defineComponent, ref } from 'vue'


export default defineComponent({
  setup() {

  },
  props: ['receipes', 'receipeItem', 'units', 'setUnits', 'add', 'remove', 'changeReceipe', 'glycemics_index', 'showReceipeItemErrors', 'clearReceipeItem'],
  data() {
    return {
      unitsAll: [],
      g_index: null
    }
  },
  watch: {
    showReceipeItemErrors: {
      handler: function (val) {
        console.log("Show errors: ", this.showReceipeItemErrors, val)
      }
    },
    receipeItem: {
      handler: function (val) {
        var units = this.units
        var valUnit = val.unit_id
        var unit = units.find(function (cc) {
          return cc.id == valUnit
        })
        if (!unit) {
          units.push({ id: valUnit, value: valUnit })
        }

        if (unit && !this.receipeItem.edited_g_equivalent && this.receipeItem.quantity) {
          let quantity = this.receipeItem.quantity
          if (this.receipeItem.quantity.includes("/")) {
            let q = this.receipeItem.quantity.trim().replace(/ /g, "").split("/")
            if (q.length > 1)
              quantity = q[0] / q[1]
          }
          this.receipeItem.g_equivalent = unit.g_equivalent * quantity
        }

        const r = this.receipes.find(rr => rr.id === parseInt(this.receipeItem.food_recipe_child_id))
        if (r) {
          this.receipeItem.name = r.name
          this.receipeItem.gi_category = r.gi_category
          // if(this.receipeItem.gi_category && this.receipeItem.gi_category !== "")
          //     this.g_index = this.glycemics_index.find((d) => d.id === this.receipeItem.gi_category)
        }

        this.setUnits(units)
      },
      deep: true
    },
    receipes: {
      handler: function (val) {
        var r = this.receipes.find(function (f) {
          return f.id === parseInt(this.receipeItem.food_recipe_child_id)
        }.bind(this))

        if (!r && this.receipeItem.food_recipe_child_id !== "") {
          this.clearReceipeItem(this.receipeItem)
        }
      },
      deep: true
    }
  },
  mounted() {

  },
  methods: {
    getGEquivalent(ev) {
      this.receipeItem.edited_g_equivalent = true
    },
    getIndexGI(key) {
      const category = this.receipeItem.gi_category
      const g_index = this.glycemics_index.find((d) => d.id === category)
      return g_index[key]

    },
    enableGEquivalent(ev) {
      this.receipeItem.edited_g_equivalent = false
    },
    changeUnit() {
      this.receipeItem.edited_g_equivalent = false
      this.add()
    }
  }
})
</script>
