<template>
  <AlertMessage ref="alertMessage" v-bind:id_alert="'editFoodAlert'" class="dark" v-bind:message="'<p>Alerta</p>'" />
  <AlertMessage ref="alertListUsers" v-bind:id_alert="'listUsersAlert'" class="dark" v-bind:message="'<p>Alerta</p>'" />
  <div>
    <div id="tiny-slider-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mx-6">
              <div v-html="recomendations" class="center-mode"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="save-food-success" class="toastify-content flex items-center hidden">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t("views.food.text11") }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="remove-food-success" class="toastify-content flex items-center hidden">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t("views.food.text32") }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t("views.food.text12") }}: {{ lang_inputs[global_lang].name }}
      </h2>
    </div>
    <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="lang__tabs nav nav-tabs flex-col sm:flex-row text-gray-600 rounded-b-none" role="tablist">
          <a v-for="(lang, key) in langs" :key="key" data-toggle="tab" @click="changeLang('lang__' + lang.key)"
            :data-target="'#lang__' + lang.key" href="javascript:;"
            class="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center rounded-t-md"
            :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''" :id="'lang__' + lang.key + '-tab'" role="tab"
            :aria-controls="'lang__' + lang.key" aria-selected="false"> {{ $t('components.langs.' + lang.key + '.name')
            }}
          </a>
        </div>
        <div v-for="(lang, key) in langs" :key="key" :class="'post__lang__' + lang.key" class="tab-content">
          <div :id="'lang__' + lang.key" class="tab-pane " :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''"
            role="tabpanel" :aria-labelledby="'lang__' + lang.key + '-tab'">

            <!-- BEGIN: Form Layout -->
            <div class="intro-y box p-5 rounded-tl-none">

              <div :id="'lang-accordion-' + lang_key" class="accordion">
                <div class="accordion-item">
                  <div :id="'lang-accordion-content-' + lang_key" class="accordion-header">
                    <label :for="'crud-form-1-' + lang_key" class="form-label">
                      {{ $t('views.food.text13') }}*
                    </label>
                    <div class="input-group">
                      <input :id="'crud-form-1-' + lang_key" :readonly="isReadOnly"
                        :class="validations.name && 'border border-theme-6'" type="text" name="chart-tooltip"
                        class="form-control w-full bg-theme-32" :placeholder="$t('views.food.text13')"
                        v-model="lang_inputs[lang_key].name" />
                      <div id="input-group-unit" class="input-group-text ml-4 w-1/12 text-white">
                        {{ getTotalInputs() + " / " + total_inputs }}
                      </div>
                      <button class="accordion-button collapsed btn-outline-dark btn-rounded btn-sm remove-item w-auto"
                        type="button" data-tw-toggle="collapse" :data-tw-target="'#lang-accordion-collapse-' + lang_key"
                        aria-expanded="false" :aria-controls="'lang-accordion-collapse-' + lang_key">
                        <GlobeIcon class="btn-source-icon" />
                      </button>
                      <button @click="openListUsers()" class="btn-outline-dark btn-rounded btn-sm w-auto" type="button">
                        <UsersIcon class="btn-source-icon" />
                      </button>
                    </div>
                    <div v-if="validations.name" class="text-theme-6 mt-2">
                      {{ $t("views.food.text14") }}
                    </div>
                  </div>
                  <div :id="'lang-accordion-collapse-' + lang_key" class="accordion-collapse collapse"
                    :aria-labelledby="'lang-accordion-content-' + lang_key"
                    :data-tw-parent="'#lang-accordion-' + lang_key">
                    <div class="accordion-body text-slate-600 dark:text-slate-500 leading-relaxed">

                      <p class="mb-2">{{ $t('views.food.text37') }}</p>
                      <div class="input-group lang_item accordion-body"
                        v-for="(name_lang, keyLang) in lang_inputs[lang_key].langs.filter(ll => ll.lang !== lang_key && (ll.value !== 0 && ll.value !== '0'))"
                        :key="keyLang" :class="(keyLang > 0 ? 'mt-3' : '')">

                        <TomSelect ref="country-lang" :disabled="isReadOnly" class="w-72 mr-5" v-model="name_lang.lang"
                          :placeholder="$t('views.food.text22')" :flag="true" @change="addFlag(lang_key)">
                          <option :value="lang_key">{{ $t('views.food.text22') }}</option>
                          <option
                            v-for="(country, key) in name_lang.countries.filter(c => c.lang.includes(lang.key + '-'))"
                            :key="key" :data-flag="country.flag" :value="country.lang.toLowerCase()">
                            {{ country.name }}
                          </option>

                        </TomSelect>

                        <input :id="'crud-form-1-' + lang_key" :readonly="isReadOnly" type="text" name="chart-tooltip"
                          class="form-control w-full text-white" :placeholder="$t('views.food.text36')"
                          v-model="name_lang.value" @blur="addFlag(lang_key)" v-on:keyup.enter="addFlag(lang_key)" />

                        <TomSelect ref="difficulty-lang" class="w-72 ml-5" :disabled="isReadOnly"
                          v-model="name_lang.difficulty" :placeholder="$t('views.food.text24')">
                          <option value="0">{{ $t('views.food.text24') }}</option>
                          <option v-for="(item, key) in difficulties" :key="key" :value="item">
                            {{ $t('views.difficulties.' + item.toString()) }}
                          </option>

                        </TomSelect>
                        <button v-on:click="removeFlag(lang_key, name_lang)" :disabled="isReadOnly" type="button"
                          class="btn-outline-dark btn-rounded btn-sm remove-item">
                          <XCircleIcon class="btn-source-icon" />
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3 hidden">
                <label for="crud-form-3" class="form-label">{{ $t("views.food.text15") }}</label>
                <div class="input-group">
                  <input id="crud-form-3" :readonly="isReadOnly" type="text" class="form-control"
                    :placeholder="$t('views.food.text15')" aria-describedby="input-group-1"
                    v-model="food.description" />
                </div>
                <div v-if="validations.description" class="text-theme-6 mt-2">
                  {{ $t("views.food.text14") }}
                </div>
              </div>

              <div class="mt-3 categories">
                <label for="crud-form-3" class="form-label">{{ $t("views.food.text16") }}*</label>
                <div class="input-group input-bg-theme-32 rounded"
                  :class="validations.foodMainCategory && 'border border-theme-6'">
                  <TomSelect :disabled="isReadOnly" ref="mainCategory" class="w-full cursor " v-model="foodMainCategory"
                    :category="true" :placeholder="$t('views.food.text17')" :plugins="['dropdown_input']">
                    <option value="0">{{ $t("views.food.text17") }}</option>
                    <optgroup v-for="(main, key) in categoriesTree" :key="key" :label="main.name">
                      <option v-for="(cat, key) in main.childs" :key="key" :data-cat="main.name" :value="cat.id">–
                        {{ cat.name }}
                      </option>
                    </optgroup>

                  </TomSelect>
                </div>
                <div v-if="validations.foodMainCategory" class="text-theme-6 mt-2">
                  {{ $t("views.food.text17") }}
                </div>
              </div>

              <div class="p-4 bg-theme-32 rounded-md mt-6 ">
                <div class=" season">
                  <label for="crud-form-3" class="form-label ">{{ $t('views.receipes.text20') }}*</label>
                  <div
                    class="mt-2 gap-4 gap-x-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-6 xxl:grid-cols-6">
                    <div class="form-check mr-2"><input id="checkbox-switch-16"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="true"
                        v-model="food.omnivoro"> <label class="form-check-label" for="checkbox-switch-16">{{
    $t('views.food.text40') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-4"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.ovolactovegetarian"> <label class="form-check-label" for="checkbox-switch-4">{{
    $t('views.food.type_diet.ovolactovegetarian') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-5"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.pescetarian"> <label class="form-check-label" for="checkbox-switch-5">{{
    $t('views.food.type_diet.pescetarian') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-6"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.vegan"> <label class="form-check-label" for="checkbox-switch-6">{{
    $t('views.food.type_diet.vegan') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-7"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.ketogenic"> <label class="form-check-label" for="checkbox-switch-7">{{
    $t('views.food.type_diet.ketogenic') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-8"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.kosher"> <label class="form-check-label" for="checkbox-switch-8">{{
    $t('views.food.type_diet.kosher') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-9"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.halal"> <label class="form-check-label" for="checkbox-switch-9">{{
    $t('views.food.type_diet.halal') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-10"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.celiac"> <label class="form-check-label" for="checkbox-switch-10">{{
    $t('views.food.type_diet.celiac') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-11"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.flexitarian"> <label class="form-check-label" for="checkbox-switch-11">{{
    $t('views.food.type_diet.flexitarian') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-12"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.mediterranean"> <label class="form-check-label" for="checkbox-switch-12">{{
    $t('views.food.type_diet.mediterranean') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-13"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.paleo"> <label class="form-check-label" for="checkbox-switch-13">{{
    $t('views.food.type_diet.paleo') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-14"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.carnivorous"> <label class="form-check-label" for="checkbox-switch-14">{{
    $t('views.food.type_diet.carnivorous') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-15"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="food.rawvegan"> <label class="form-check-label" for="checkbox-switch-15">{{
    $t('views.food.type_diet.rawvegan') }}</label> </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-all"
                        class="form-check-input shrink-0 basis-4" type="checkbox" :disabled="isReadOnly"
                        v-model="selectAll"> <label class="form-check-label" for="checkbox-switch-all">{{
    $t('views.food.text45') }}</label> </div>
                  </div>
                </div>
              </div>

              <div class="season mt-6">
                <label for="crud-form-3" class="form-label ">{{ $t('views.food.text43') }}</label>
                <div
                  class="mt-2 gap-4 gap-x-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-6 xxl:grid-cols-6">
                  <div class="form-check mr-2"> <input id="checkbox-switch-healthy" class="form-check-input"
                      type="checkbox" :disabled="isReadOnly" v-model="food_healthy" @change="setHealty(130)"
                      :value="130">
                    <label class="form-check-label" for="checkbox-switch-healthy">{{ $t('views.food.text41') }}</label>
                  </div>
                  <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-occasionally"
                      class="form-check-input" type="checkbox" :disabled="isReadOnly" v-model="food_occasionally"
                      @change="setHealty(131)" :value="131"> <label class="form-check-label"
                      for="checkbox-switch-occasionally">{{ $t('views.food.text48') }}</label> </div>
                  <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-not-healthy"
                      class="form-check-input" type="checkbox" :disabled="isReadOnly" v-model="food_not_healthy"
                      @change="setHealty(107)" :value="107"> <label class="form-check-label"
                      for="checkbox-switch-not-healthy">{{ $t('views.food.text42') }}</label> </div>
                </div>
                <div v-if="validations.food_healthy" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text28') }}
                </div>
              </div>

              <div class="mt-5">
                <label for="crud-form-3" class="form-label">{{ $t('views.food.text38') }}</label>
                <div class="input-group">
                  <TomSelect :disabled="isReadOnly" ref="category" class="w-full cursor" v-model="foodCategories"
                    :placeholder="$t('views.food.text44')" :plugins="['dropdown_input']">
                    <option value="0">{{ $t("views.food.text44") }}</option>
                    <option v-for="(cat, key) in descriptiveTags" :key="key" :value="cat.id">{{ cat.name }}</option>
                  </TomSelect>
                </div>
              </div>
              <div class="mt-3">
                <div class="flex gap-4">
                  <div class="input-group w-3/4 flex-col">
                    <label for="crud-form-3" class="form-label">{{ $t("views.food.text31") }}</label>
                    <TomSelect ref="difficulty" :disabled="isReadOnly" class="w-full cursor" v-model="food.difficulty"
                      :placeholder="$t('views.food.text24')" :plugins="['dropdown_input']">
                      <option value="0">{{ $t('views.food.text24') }}</option>
                      <option v-for="(item, key) in difficulties" :key="key" :value="item">
                        {{ $t('views.difficulties.' + item.toString()) }}
                      </option>
                    </TomSelect>
                  </div>
                  <div class="w-1/4">
                    <label for="crud-form-3" class="form-label">{{ $t("views.food.text50") }}</label>
                    <div class="input-group w-full">
                      <input class="form-control placeholder-theme-13 w-full focus-none" v-model="food.intake_portion"
                        :placeholder="$t('views.food.text49')" />
                      <div class="input-group-text border-0 border-transparent">gr</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-6 season">
                <label for="crud-form-3" class="form-label ">{{ $t('views.food.text30') }}</label>
                <div
                  class="mt-2 gap-4 gap-x-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-6 xxl:grid-cols-6">
                  <div class="form-check mr-2"> <input id="checkbox-switch" class="form-check-input" type="checkbox"
                      :disabled="isReadOnly" v-model="food.seasson_fall"> <label class="form-check-label"
                      for="checkbox-switch">{{ $t('views.seasson.fall') }}</label> </div>
                  <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-1" class="form-check-input"
                      type="checkbox" :disabled="isReadOnly" v-model="food.seasson_spring"> <label
                      class="form-check-label" for="checkbox-switch-1">{{ $t('views.seasson.spring') }}</label> </div>
                  <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-2" class="form-check-input"
                      type="checkbox" :disabled="isReadOnly" v-model="food.seasson_summer"> <label
                      class="form-check-label" for="checkbox-switch-2">{{ $t('views.seasson.summer') }}</label> </div>
                  <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-3" class="form-check-input"
                      type="checkbox" :disabled="isReadOnly" v-model="food.seasson_winter"> <label
                      class="form-check-label" for="checkbox-switch-3">{{ $t('views.seasson.winter') }}</label> </div>
                </div>
              </div>

              <Nutrients :food="food" :type="typeNutrients" :setType="setType" :setServing="setServing"
                :setEnergy="setEnergy" :isReadOnly="isReadOnly" :errorEnergy="validations.energy" />

              <div class="mt-6">
                <label for="crud-form-3" class="form-label">{{ $t("views.food.text46") }}</label>
                <div class="input-group">
                  <textarea id="crud-form-3" :readonly="isReadOnly" type="text" class="form-control"
                    :placeholder="$t('views.food.text47')" rows="5" aria-describedby="input-group-1"
                    v-model="food.source" />
                </div>
              </div>

              <div class="flex justify-between mt-5">
                <div>
                  <button v-if="usersApproved.includes(user_id)"
                    v-on:click="() => { food.approved_by = food.approved_by ? null : user_id; saveFood() }"
                    :disabled="getValidation()" v-on:hover="() => getValidation()" type="button"
                    class="btn btn-custom-gray-1 w-auto mr-8"
                    :class="food.approved_by ? 'border-custom-green-1 bg-custom-green-1' : ''">
                    <LoadingIcon v-if="loading" icon="three-dots" class="" />
                    <span v-else>{{ $t('views.food.text35') }}</span>
                  </button>
                  <span v-else-if="food.approved_by"
                    class="block btn border-custom-green-1 bg-custom-green-1 cursor-default  w-auto">
                    {{ $t('views.food.text35') }}
                  </span>
                </div>
                <div @mouseover="() => focusOut()">
                  <input class="opacity-0" type="text" id="saveFood" />
                  <button v-on:click="confirmRemoveFood"
                    :disabled="food.approved_by && !usersApproved.includes(user_id)" type="button"
                    class="btn btn-custom-gray-1 w-24 mr-8">
                    <LoadingIcon v-if="loading" icon="three-dots" class="" />
                    <span v-else>{{ $t('views.delete') }}</span>
                  </button>
                  <button v-on:click="saveFood"
                    :class="(food.approved_by && !usersApproved.includes(user_id)) || getValidation() ? 'opacity-50 cursor-not-allowed' : ''"
                    type="button" class="btn btn-primary w-24">
                    <LoadingIcon v-if="loading" icon="three-dots" class="" />
                    <span v-else>{{ $t('views.food.text20') }}</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- END: Form Layout -->

          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.dark .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  --tw-text-opacity: 1;
  color: #fff;
}

.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}

.max-w-label {
  max-width: 90%;
}

.lang_item:last-child .remove-item {
  visibility: hidden;
}

.accordion .accordion-item .accordion-header .accordion-button {
  width: auto;
}

.season .form-check-input[type=checkbox],
.season .form-check-input[type=radio] {
  background-color: transparent;
  border-color: #6c869a;
  color: white;
  border-radius: 0.2em;
}

.season .form-check-input[type=radio]:before {
  background-color: transparent;
  border-radius: 0.2em;
}

.season .form-check-input[type=radio]:checked:before {
  background-position: center;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E")
}

.season .form-check-input[type=checkbox]:checked {
  background-color: #6c869a;
  border-color: #6c869a;
  color: #6c869a;
}

.dark .season .form-check-input[type=checkbox]:checked {
  background-color: transparent;
  border-color: #6c869a;
  color: white;
}

.input-group .input-group-text.text-white {
  color: white;
  border-radius: 0.2em;
  border: none;
  text-align: center;
}
</style>
<style>
.input-bg-theme-32 .ts-input {
  background-color: #84d9a50a !important;
}

.categories .tom-select .ts-dropdown .option {
  padding: 9px 13px 9px 26px;
}

.dark .form-control.border-theme-6 {
  border-color: rgba(211, 41, 41, 1);
}

.basis-4 {
  flex-basis: 1rem;
}

.shrink-0 {
  flex-shrink: 0;
}
</style>
<script>
import FoodService from "/src/services/food.service";
import ReceipeService from "/src/services/receipes.service";
import Toastify from "toastify-js";
import Nutrients from "@/components/nutrition/Main.vue"
import countries from '../../utils/countries';
import AlertMessage from "@/components/alert/Main.vue"
import moment from 'moment';

export default {
  components: {
    Nutrients,
    AlertMessage
  },
  setup() { },
  data() {
    return {
      name: "",
      validations: {
        name: false,
        description: false,
        foodMainCategory: false,
        food_healthy: false,
        energy: false
      },
      loading: false,
      recomendations: null,
      queryUrl: this.$route.query,
      hiddenKey: ['id', 'id_api', 'food_id', 'updatedAt', 'createdAt', 'deletedAt', 'vitamin_c_ascorbic_acid'],
      category: "0",
      food: (localStorage.foodItem) ? JSON.parse(localStorage.foodItem) : {
        name: "",
        category: {
          id: "",
          name: ""
        },
        difficulty: '',
        nutritional_content: {
          energy_value: null
        },
        nutritional_content_by_percent: {
          energy_value: null
        },
        langs: [],
        seasson_fall: true,
        seasson_spring: true,
        seasson_summer: true,
        seasson_winter: true,
        serving_quantity: 100,
        serving_unit_id: 2,
        approved_by: null,
        omnivoro: true,
        ovolactovegetarian: false,
        pescetarian: false,
        vegan: false,
        ketogenic: false,
        kosher: false,
        halal: false,
        celiac: false,
        flexitarian: false,
        mediterranean: false,
        paleo: false,
        carnivorous: false,
        rawvegan: false,
      },
      categories: [],
      categoriesTree: [],
      foodMainCategory: "",
      foodCategories: [],
      descriptiveTags: [],
      activeLang: 'lang__' + (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      langs: this.$global.getLangs(),

      lang_key: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      global_lang: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      lang_inputs: [],
      countries: countries,
      lang_countries: [],
      requestCountryLang: true,
      selectedLand: [],
      difficulties: [1, 2, 3],
      typeNutrients: false,
      units: [],
      serving_unit: 'gr',
      messageToastify: "",
      user_id: null,
      usersApproved: this.$global.getUsersApprovedFood(),
      isReadOnly: false,
      healthy: null,
      food_healthy: null,
      food_not_healthy: null,
      food_occasionally: null,
      hiddenKey: ['id', 'id_api', 'food_id', 'updatedAt', 'createdAt', 'deletedAt', 'vitamin_c', 'calculated_energy'],
      total_inputs: 8,
      selectAll: false,
      users: []
    };
  },
  watch: {
    category: function () {
      var category = this.category
      var cat = this.categories.find(function (c) {
        return c.id == category
      })
      this.food.category = (cat) ? cat : this.food.category
    },
    '$i18n.locale': function (l) {
      this.getCategories();
      this.units();
    },
    foodMainCategory: function () {
      var mainC = this.categoriesTree.find(c => {
        var secondC = c.childs.find(cc => cc.id === Number(this.foodMainCategory))
        return secondC
      })

      if (mainC) {

        console.log(mainC.id, this.foodMainCategory)

        let foodSubcategory = Number(this.foodMainCategory);

        this.food.vegan = true;
        this.food.rawvegan = true;
        this.food.ovolactovegetarian = true;
        this.food.carnivorous = true;
        this.food.pescetarian = true;
        this.food.flexitarian = true;
        this.food.mediterranean = true;
        this.food.ketogenic = true;
        this.food.paleo = true;
        this.food.kosher = true;
        this.food.celiac = true;
        this.food.halal = true;

        //carnes
        if (mainC.id == 122) {
          this.food.vegan = false;
          this.food.ovolactovegetarian = false;
          this.food.pescetarian = false;
          this.food.rawvegan = false;
        }

        //pescados y mariscos
        if (mainC.id == 121) {
          this.food.vegan = false;
          this.food.ovolactovegetarian = false;
          this.food.rawvegan = false;
          this.food.carnivorous = false;

          //mariscos
          if ([85].includes(foodSubcategory)) {
            this.food.kosher = false;
          }
        }

        //otros origen animal
        if (mainC.id == 124) {
          this.food.vegan = false;
          this.food.rawvegan = false;
          this.food.ovolactovegetarian = false;
          this.food.pescetarian = false;

          //lacteos 83 , huevos 72
          if ([83, 72].includes(foodSubcategory)) {
            this.food.ovolactovegetarian = true;
          }

          //lacteos 83, embutidos 125
          if ([83, 125].includes(foodSubcategory)) {
            this.food.paleo = false;
          }

          //huevos 72
          if ([72].includes(foodSubcategory)) {
            this.food.pescetarian = true;
          }
        }

        //dulces y snack 148
        if (mainC.id == 148) {
          this.food.celiac = false;
          this.food.ketogenic = false;
        }

        //vegetales 29
        //frutas 17
        //granos/semillas 126
        //condimentos y especies 68
        //bebidas y agua 129
        if ([29, 17, 126, 68, 129].includes(mainC.id)) {

          //legumbres 61, cereales 64, especies 128
          if ([61, 64, 128].includes(foodSubcategory)) {
            this.food.paleo = false;
          }

          //cereales 64, endulzantes 71,  especies 128
          if ([64, 71, 128].includes(foodSubcategory)) {
            this.food.ketogenic = false;
          }

          //vegetales 29, frutas 17, granos/semillas 126, legumbres 61
          if ([29, 17, 126].includes(mainC.id) || [61].includes(foodSubcategory)) {
            this.food.carnivorous = false;
          }


        }
      }

    },

    activeLang: function () {
      this.lang_key = this.activeLang.replace("lang__", "")
      // this.getFood(this.lang_key);
    },
    selectAll: function () {
      this.food.ovolactovegetarian = this.selectAll
      this.food.pescetarian = this.selectAll
      this.food.vegan = this.selectAll
      this.food.ketogenic = this.selectAll
      this.food.kosher = this.selectAll
      this.food.halal = this.selectAll
      this.food.celiac = this.selectAll
      this.food.flexitarian = this.selectAll
      this.food.mediterranean = this.selectAll
      this.food.paleo = this.selectAll
      this.food.carnivorous = this.selectAll
      this.food.rawvegan = this.selectAll
      this.food.omnivoro = true
    }
  },
  computed: {

  },
  created() {
    this.food.category = (this.food.category) ? this.food.category : { id: "", name: "" }
    this.food.difficulty = this.food.difficulty ? this.food.difficulty.toString() : ''
    var _this = this
    this.lang_inputs = this.langs.map(l => l.key).reduce((x, y) => (x[y] = { name: "", langs: [{ lang: "", value: "", countries: _this.countries, difficulty: "" }] }, x), {})
    this.lang_inputs[this.lang_key]["name"] = this.food["name"]

    this.getFood();
    this.getCategories();
    this.getUnist();
    this.countries = this.countries.sort(function (a, b) {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1;
      return 0;
    })

    if (localStorage.user) {
      var user = JSON.parse(localStorage.user)
      this.user_id = user.user_master_id
      this.isReadOnly = this.food.approved_by && !this.usersApproved.includes(this.user_id)
    }
    var n_contents = 0
    Object.keys(this.$global.getNutritionalContents()).forEach(n => {
      n_contents = n_contents + Number(!this.hiddenKey.includes(n))
    })
    this.total_inputs = this.total_inputs + n_contents
  },
  methods: {
    getFood: function () {
      var food = this.food;
      if (food.id) {
        Object.keys(this.lang_inputs).map(function (lang) {
          this.getFoodService(food.id, lang)
        }.bind(this));
      } else {
        this.getUnist();
      }
      this.food.nutritional_content = !this.food.nutritional_content ? this.$global.getNutritionalContents() : this.food.nutritional_content
      this.food.nutritional_content_by_percent = !this.food.nutritional_content_by_percent ? this.$global.getNutritionalContents() : this.food.nutritional_content_by_percent
      this.food.serving_quantity = this.food.serving_quantity ? this.food.serving_quantity : 100
      this.food.serving_unit_id = this.food.serving_unit_id ? this.food.serving_unit_id : 2
    },
    getCategories: function (lang = null) {
      FoodService.getCategories(lang).then((response) => {
        this.categories = (response.data) ? response.data : response

        FoodService.getCategoriesTree().then((response) => {
          this.categoriesTree = response

          var allC = this.categoriesTree.map(c => {
            return [c.id, c.childs.map(cc => cc.id)]
          })
          allC = allC.flat(2)
          this.descriptiveTags = this.categories.filter(c => !allC.includes(c.id) && ![107, 130].includes(c.id))

        });
      });

    },
    getFoodService: function (id, lang) {
      FoodService.getFood(id, lang).then((response) => {
        this.food = (response.data) ? response.data : response;
        this.foodCategories = this.food.categories
        lang = lang.split("-")[0]
        Object.keys(this.lang_inputs[lang]).map(function (key) {
          this.lang_inputs[lang][key] = this.food[key]
        }.bind(this))
        this.lang_inputs[lang].langs = this.lang_inputs[lang].langs.map(function (l) {
          l.countries = this.countries
          l.difficulty = l.difficulty ? l.difficulty.toString() : ''
          return l
        }.bind(this))
        this.food.nutritional_content = !this.food.nutritional_content ? this.$global.getNutritionalContents() : this.food.nutritional_content
        this.food.nutritional_content_by_percent = !this.food.nutritional_content_by_percent ? this.$global.getNutritionalContents() : this.food.nutritional_content_by_percent
        this.addFlag(lang)
        this.requestCountryLang = true
        this.food.seasson_fall = Boolean(this.food.seasson_fall)
        this.food.seasson_spring = Boolean(this.food.seasson_spring)
        this.food.seasson_summer = Boolean(this.food.seasson_summer)
        this.food.seasson_winter = Boolean(this.food.seasson_winter)
        this.food.omnivoro = true
        this.food.ovolactovegetarian = Boolean(this.food.ovolactovegetarian)
        this.food.pescetarian = Boolean(this.food.pescetarian)
        this.food.vegan = Boolean(this.food.vegan)
        this.food.ketogenic = Boolean(this.food.ketogenic)
        this.food.kosher = Boolean(this.food.kosher)
        this.food.halal = Boolean(this.food.halal)
        this.food.celiac = Boolean(this.food.celiac)
        this.food.flexitarian = Boolean(this.food.flexitarian)
        this.food.mediterranean = Boolean(this.food.mediterranean)
        this.food.paleo = Boolean(this.food.paleo)
        this.food.carnivorous = Boolean(this.food.carnivorous)
        this.food.rawvegan = Boolean(this.food.rawvegan)
        this.selectAll = this.food.ovolactovegetarian && this.food.pescetarian && this.food.vegan && this.food.ketogenic && this.food.kosher && this.food.halal && this.food.celiac && this.food.flexitarian && this.food.mediterranean && this.food.paleo && this.food.carnivorous && this.food.rawvegan
        this.food.serving_quantity = this.food.serving_quantity ? this.food.serving_quantity : 100
        this.food.serving_unit_id = this.food.serving_unit_id ? this.food.serving_unit_id : 2
        var unit_id = parseInt(this.food.serving_unit_id)
        this.food.serving_unit = this.units.find(u => u.id === unit_id)?.value
        this.food.difficulty = this.food.difficulty ? this.food.difficulty.toString() : ''
        this.foodMainCategory = this.food.secondary_category ? this.food.secondary_category.id.toString() : ""
        this.food_healthy = (this.food.categories.includes(130))
        this.food_not_healthy = (this.food.categories.includes(107))
        this.food_occasionally = (this.food.categories.includes(131))
        this.healthy = this.food.categories.includes(130) ? 130 : this.food.categories.includes(131) ? 131 : this.food.categories.includes(107) ? 107 : null
        this.users = response.users
      });
    },
    getUnist: function (lang = null) {
      ReceipeService.getUnits(lang).then((response) => {
        this.units = (response.data) ? response.data : response
        var unit_id = parseInt(this.food.serving_unit_id)
        this.food.serving_unit = this.units.find(u => u.id === unit_id)?.value
      })
    },
    showMessage(id) {

      Toastify({
        node: cash('#' + id)
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('foodItem');
      const returnTo = localStorage.getItem('returnTo') || ""
      localStorage.removeItem('returnTo');
      this.$router.push({ path: '/food-list' + returnTo });
    },
    setCategory() {
      var f_cat = this.food.category
      var cat = this.categories.find(function (c) {
        return c.name == f_cat.name
      })

      this.food.category = (cat) ? cat : this.food.category
      this.category = String(this.food.category.id);
    },
    async saveFood() {
      if (this.food.approved_by && !this.usersApproved.includes(this.user_id))
        return false;

      this.foodCategories = this.foodCategories.filter(c => c !== (this.food.main_category ? this.food.main_category.id : null) && c !== (this.food.secondary_category ? this.food.secondary_category.id : null))
      this.food.categories = this.foodCategories

      var mainC = this.categoriesTree.find(c => {
        var secondC = c.childs.find(cc => cc.id === Number(this.foodMainCategory))
        return secondC
      })

      if (mainC) {
        this.food.categories.push(mainC.id)
        this.food.categories.push(Number(this.foodMainCategory))
      }
      this.food.categories = this.food.categories.filter(c => Number(c) !== 130 && Number(c) !== 107 && Number(c) !== 131)
      if (this.healthy) {
        this.food.categories.push(this.healthy)
      }

      Object.keys(this.food.nutritional_content).map(function (key) {
        this.food.nutritional_content[key] = (this.food.nutritional_content[key] === "") ? null : this.food.nutritional_content[key];
      }.bind(this));
      Object.keys(this.food.nutritional_content_by_percent).map(function (key) {
        this.food.nutritional_content_by_percent[key] = (!this.food.nutritional_content_by_percent[key] || this.food.nutritional_content_by_percent[key] === "") ? null : parseFloat(this.food.nutritional_content_by_percent[key]);
      }.bind(this));
      var l_keys = Object.keys(this.lang_inputs)

      for (var i = 0; i < l_keys.length; i++) {
        var lang = l_keys[i]

        Object.keys(this.lang_inputs[lang]).map(function (key) {
          if (key === "langs") {
            this.lang_inputs[lang][key] = this.lang_inputs[lang][key].filter(ll => ll.lang !== "")
            let exist = this.lang_inputs[lang][key].find(l => l.lang == lang)
            if (!exist) {
              this.lang_inputs[lang][key].push({ lang: lang })
            }
            this.lang_inputs[lang][key].map(l => {
              l.value = l.lang == lang ? this.lang_inputs[lang].name : l.value
              return l
            })
          }
        }.bind(this))
      }

      this.food.name = this.lang_inputs["es"]["name"]
      const langInputs = JSON.parse(JSON.stringify(this.lang_inputs))

      Object.keys(langInputs).map(function (key) {
        langInputs[key]["langs"].map(l => {
          delete l.countries
          return l
        })
      }.bind(this))
      this.food.lang_inputs = Object.assign({}, langInputs);

      const nameL = new Object();
      this.food.langs = this.food.langs.filter(ll => ll.lang !== "")
      this.food.langs.map(nL => {
        // if(nL.lang !== "en" && nL.lang !== "es")
        nameL[nL.lang] = (nL.value === 0 || nL.value === "0") ? null : { "name": nL.value, "difficulty": nL.difficulty == '' ? null : parseInt(nL.difficulty) }
      })
      this.food.name_langs = nameL
      this.food.difficulty = this.food.difficulty == '' || this.food.difficulty == '0' ? null : parseInt(this.food.difficulty)
      if (this.food.deleted != 1) { //i === 0 &&
        if (this.getValidation(true))
          return
      }
      if (this.food.name !== "") {
        this.loading = true

        if (this.food.id) {
          var serverResponse = await FoodService.saveFood(this.food, "es")
        } else {
          var serverResponse = await FoodService.createFood(this.food, "es")

          if (serverResponse && serverResponse.createdFoodItem && serverResponse.createdFoodItem.id) {
            this.food.id = serverResponse.createdFoodItem.id
          }
        }
        this.loading = false
      }
      if (this.food.deleted === 1) {
        this.showMessage("remove-food-success")
        // i = 999;
        // break;  
      }

      // if(i === (l_keys.length - 1)){
      this.showMessage("save-food-success")
      // }

    },
    changeLang(l) {
      this.activeLang = l
    },
    addFlag(lang_key) {
      this.selectedLand = this.lang_inputs["es"].langs.filter(l => {
        return l.lang && parseInt(l.value) !== 0
      })
      this.selectedLand = this.selectedLand.concat(this.lang_inputs["en"].langs.filter(l => l.lang && parseInt(l.value) !== 0))
      this.selectedLand = this.selectedLand.map(l => l.lang)

      var empty = this.lang_inputs[lang_key].langs.filter(l => l.lang === "" || l.value === "")
      var c = this.countries.filter(function (c) {
        return !this.selectedLand.includes(c.lang.toLowerCase())
      }.bind(this))

      if (empty.length === 0)
        this.lang_inputs[lang_key].langs.push({ lang: "", value: "", countries: c, difficulty: "" })
    },
    removeFlag(lang_key, val) {
      var i = this.lang_inputs[lang_key].langs.findIndex(function (s) {
        return s.lang == val.lang
      })

      if (i !== -1 && this.lang_inputs[lang_key].langs.length > 1)
        this.lang_inputs[lang_key].langs[i].value = 0
    },
    verifyDisabled(c) {
      return this.selectedLand.includes(c.lang.toLowerCase())
    },
    setType(v) {
      this.typeNutrients = !this.typeNutrients
    },
    setServing() {
      var message = `<h2 class="text-2xl mb-5 font-bold">${this.$t('views.food.text28')}</h2>
      <p>${this.$t('views.food.text29')}</p>
      `
      this.$refs.alertMessage.show(message, true, true, this.confirmServing, false, this.units, { input: this.food.serving_quantity, select: this.food.serving_unit_id }, true, "Indique la porcion del alimento", true)
    },
    confirmServing(val) {
      this.food.serving_quantity = val.input
      this.food.serving_unit_id = val.select
      var unit_id = parseInt(this.food.serving_unit_id)
      this.food.serving_unit = this.units.find(u => u.id === unit_id).value
      this.$refs.alertMessage.hide()
    },
    removeFood() {
      this.$refs.alertMessage.hide()
      this.food.deleted = 1
      this.saveFood()
    },
    confirmRemoveFood() {
      var message = `<h2 class="text-2xl mb-5 font-bold"${this.$t('views.food.text33')}</h2>
      <p>${this.$t('views.food.text34')}</p>
      `
      this.$refs.alertMessage.show(message, true, true, this.removeFood, true)

    },
    getTotalInputs() {
      var inputsDefault = 1
      var n_contents = 0
      Object.keys(this.$global.getNutritionalContents()).forEach(n => {
        n_contents = n_contents + Number((this.food.nutritional_content[n] > 0) && !this.hiddenKey.includes(n))
      })

      return inputsDefault + Number(!(this.foodMainCategory === "") && !(this.foodMainCategory === "0")) + Number(!(this.lang_inputs[this.global_lang].name === "")) +
        Number(this.foodCategories.length > 0) + Number(!(this.food.difficulty === "") && !(this.food.difficulty === "0")) + Number(this.food_healthy !== null) +
        Number(this.food.seasson_fall || this.food.seasson_spring || this.food.seasson_summer || this.food.seasson_winter) +
        Number(this.food.omnivoro || this.food.carnivorous || this.food.celiac || this.food.flexitarian || this.food.halal || this.food.ketogenic || this.food.kosher || this.food.mediterranean || this.food.ovolactovegetarian || this.food.paleo || this.food.pescetarian || this.food.rawvegan || this.food.vegan) +
        n_contents
    },
    getValidation(save = false) {
      var validations_name = (this.food.id && (this.food.name == "" || this.lang_inputs[this.global_lang].name == "")) || (!this.food.id && this.lang_inputs[this.global_lang].name == "")
      var validations_foodMainCategory = (this.foodMainCategory == "" || this.foodMainCategory == "0")
      var validation_energy = !(this.food.nutritional_content.energy_value > 0) || !(this.food.nutritional_content_by_percent.energy_value > 0)

      if (save || this.validations.name || this.validations.foodMainCategory || this.validations.energy) {
        this.validations.name = validations_name
        this.validations.foodMainCategory = validations_foodMainCategory
        this.validations.energy = validation_energy
      }
      return (validations_name || validations_foodMainCategory || validation_energy)
    },
    focusOut() {
      document.getElementById("saveFood").focus();
    },
    openListUsers() {
      var message = `<h4 class="text-2xl mb-5 font-bold">${'Registro de usuarios'}</h4><div class="text-left max-h-80 overflow-auto">`
      this.users.forEach(u => {
        message += `<p class="capitalize">${moment(u.created_at).format("DD/MM/YYYY HH:mm")} hs - ${u.user.session_name}</p>`
      })
      message += `</div><div class="w-full h-4 mt-4 shadow-lg shadow-gray-500"></div>`

      this.$refs.alertListUsers.show(message, false, false, null, false, [], {}, false, "", false, true);
    },
    setEnergy() {
      var message = `<h2 class="text-2xl mb-5 font-bold">Energía</h2>
      <p>${'Introduzca los valores de la energía en el alimento'}</p>
      `
      this.$refs.alertMessage.show(message, true, true, this.confirmEnergy, false, this.units, { input: this.food.nutritional_content.energy_value, select: this.food.nutritional_content_by_percent.energy_value }, false, "Indique la energía del alimento", true, false, true)
    },
    confirmEnergy(val) {
      this.food.nutritional_content.energy_value = val.input
      this.food.nutritional_content_by_percent.energy_value = val.select
      this.$refs.alertMessage.hide()
      this.getValidation(true)
    },
    setHealty(val) {
      if (this.healthy === val)
        val = null

      this.food_healthy = null
      this.food_not_healthy = null
      this.food_occasionally = null

      if (val === 130)
        this.food_healthy = true
      if (val === 107)
        this.food_not_healthy = true
      if (val === 131)
        this.food_occasionally = true

      this.healthy = val
    }
  },
};

</script>
<style></style>
