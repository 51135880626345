<template>
  <svg v-if="icon == 'meds'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="22" height="20" x="0" y="0" viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512" xml:space="preserve" class="feather">
    <g>
      <path xmlns="http://www.w3.org/2000/svg"
        d="m472.429688 39.5625c-25.496094-25.511719-59.414063-39.5625-95.488282-39.5625-36.078125 0-69.984375 14.050781-95.492187 39.5625l-120.945313 120.941406-120.949218 120.957032c-25.503907 25.511718-39.554688 59.429687-39.554688 95.496093 0 36.066407 14.050781 69.984375 39.554688 95.480469 25.496093 25.511719 59.414062 39.5625 95.488281 39.5625s69.984375-14.050781 95.488281-39.5625l120.949219-120.941406 120.949219-120.957032c25.503906-25.511718 39.550781-59.429687 39.550781-95.496093 0-36.066407-14.046875-69.984375-39.550781-95.480469zm-267.359376 407.414062c-18.710937 18.707032-43.582031 29.015626-70.027343 29.015626-26.457031 0-51.320313-10.304688-70.027344-29.015626-18.707031-18.710937-29.003906-43.570312-29.003906-70.015624 0-26.445313 10.304687-51.328126 29.003906-70.035157l108.21875-108.226562 140.054687 140.070312zm241.898438-241.898437-108.21875 108.226563-140.054688-140.070313 108.21875-108.210937c18.707032-18.707032 43.578126-29.015626 70.027344-29.015626 26.453125 0 51.316406 10.304688 70.023438 29.015626 18.707031 18.710937 29.003906 43.570312 29.003906 70.015624 0 26.445313-10.300781 51.328126-29 70.039063zm0 0"
        stroke="currentColor" data-original="#000000" style="" class="" />
      <path xmlns="http://www.w3.org/2000/svg"
        d="m425.09375 121.414062c-5.742188 0-11.386719-2.742187-14.875-7.824218-8.425781-12.308594-22.324219-19.640625-37.191406-19.640625-12.027344 0-23.324219 4.675781-31.835938 13.1875-7.035156 7.03125-18.429687 7.03125-25.460937 0-7.03125-7.035157-7.03125-18.429688 0-25.460938 15.308593-15.316406 35.652343-23.738281 57.296875-23.738281 26.753906 0 51.765625 13.1875 66.90625 35.292969 5.617187 8.207031 3.527344 19.410156-4.683594 25.039062-3.105469 2.125-6.648438 3.144531-10.15625 3.144531zm0 0"
        stroke="currentColor" data-original="#000000" style="" class="" />
    </g>
  </svg>
  <svg v-if="icon == 'food'" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.59 99.59">
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path d="M17.78,62.37h0a1.72,1.72,0,1,0,1.74,1.71A1.72,1.72,0,0,0,17.76,62.37Z" />
        <path d="M63.16,62.72H26a1.37,1.37,0,1,0,0,2.73H63.16a1.37,1.37,0,1,0,0-2.73Z" />
        <path d="M17.78,72.69h0a1.72,1.72,0,1,0,1.74,1.72A1.72,1.72,0,0,0,17.76,72.69Z" />
        <path d="M26,73a1.37,1.37,0,1,0,0,2.74H54.9a1.37,1.37,0,0,0,0-2.74Z" />
        <path
          d="M20.45,49.62A1.37,1.37,0,0,0,21.81,51H61.1a1.37,1.37,0,0,0,1.37-1.37,1.37,1.37,0,0,0-1.37-1.36h-.7l0-.7V44.42A18.87,18.87,0,0,0,43.45,25.64l-.63-.06V25a1.39,1.39,0,0,0-1.36-1.48,1.36,1.36,0,0,0-1.37,1.36v.63l-.63.18A18.91,18.91,0,0,0,22.54,44.29l0,.27v3.7h-.7A1.36,1.36,0,0,0,20.45,49.62Zm4.83-5.3a16.18,16.18,0,0,1,32.36,0v3.91H25.24Z" />
        <path
          d="M41.46,31.71a1.37,1.37,0,1,0,0,2.73,10,10,0,0,1,10,10,1.37,1.37,0,1,0,2.73,0A12.75,12.75,0,0,0,41.46,31.71Z" />
        <path
          d="M69.24,99.59H11.34A11.35,11.35,0,0,1,0,88.25V11.34A11.36,11.36,0,0,1,11.34,0h57.9A11.36,11.36,0,0,1,80.59,11.34V88.25A11.35,11.35,0,0,1,69.24,99.59ZM11.34,2.8A8.55,8.55,0,0,0,2.8,11.34V88.25a8.56,8.56,0,0,0,8.54,8.55h57.9a8.57,8.57,0,0,0,8.55-8.55V11.34A8.56,8.56,0,0,0,69.24,2.8Z" />
      </g>
    </g>
  </svg>
  <svg v-if="icon == 'receipes'" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.26 81.03">
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M76,8.84H64.78a22,22,0,0,0-35.26,0H18.23C8.72,8.84.5,16.11,0,25.61A17.69,17.69,0,0,0,16.21,44.12V70.07a11,11,0,0,0,11,11H67.1a11,11,0,0,0,11-11V44.12A17.68,17.68,0,0,0,94.24,25.61C93.76,16.11,85.54,8.84,76,8.84Zm.54,32.41a1.47,1.47,0,0,0-1.46,1.48V70A8.13,8.13,0,0,1,67,78.09H27.28A8.13,8.13,0,0,1,19.15,70V42.73a1.47,1.47,0,0,0-1.46-1.48A14.76,14.76,0,0,1,3,25.61c.48-7.86,7.33-13.82,15.19-13.82h9.46A21.91,21.91,0,0,0,25.05,22.1a1.47,1.47,0,0,0,2.94,0A19.17,19.17,0,0,1,47.15,3,19.13,19.13,0,0,1,66.3,22.1a1.48,1.48,0,0,0,3,0,21.91,21.91,0,0,0-2.57-10.31H76.1c7.87,0,14.71,6,15.19,13.82A14.74,14.74,0,0,1,76.57,41.25Z" />
        <path d="M47.13,36.83a1.47,1.47,0,0,0-1.47,1.47v28a1.47,1.47,0,1,0,2.94,0v-28A1.47,1.47,0,0,0,47.13,36.83Z" />
        <path
          d="M33.87,39.78a1.47,1.47,0,0,0-1.47,1.47V63.34a1.47,1.47,0,0,0,2.94,0V41.25A1.46,1.46,0,0,0,33.87,39.78Z" />
        <path
          d="M60.39,39.78a1.47,1.47,0,0,0-1.47,1.47V63.34a1.47,1.47,0,0,0,2.94,0V41.25A1.46,1.46,0,0,0,60.39,39.78Z" />
      </g>
    </g>
  </svg>
  <svg v-if="icon == 'brain'" width="20" height="20" version="1.0" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128.000000 128.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)">
      <path d="M555 1190 c-9 -15 4 -30 26 -30 12 0 19 7 19 20 0 21 -33 29 -45 10z" />
      <path d="M660 1185 c-6 -8 -9 -19 -5 -24 8 -15 79 -32 138 -35 35 -1 53 -8 63
    -21 9 -12 38 -24 77 -32 45 -9 68 -20 85 -39 28 -32 25 -45 -12 -55 -55 -15
    -96 -75 -65 -95 6 -3 23 5 37 19 15 13 38 27 51 30 32 8 88 -19 97 -46 4 -12
    15 -33 25 -46 27 -34 24 -45 -22 -65 -43 -19 -77 -71 -66 -100 10 -26 37 -18
    48 13 6 15 21 33 33 39 20 9 27 7 44 -11 47 -50 53 -118 22 -220 -21 -67 -63
    -117 -98 -117 -9 0 -44 14 -76 30 -60 30 -110 38 -121 20 -11 -17 10 -34 57
    -48 24 -7 54 -22 68 -32 24 -18 24 -21 10 -46 -30 -54 -64 -69 -148 -67 -42 1
    -90 3 -107 5 -30 3 -30 2 -33 -49 -3 -46 -6 -54 -27 -59 -29 -7 -45 1 -45 24
    -1 29 -46 149 -69 180 -11 17 -21 34 -21 39 0 5 35 16 78 24 66 12 77 17 77
    34 0 18 -6 20 -50 17 -27 -2 -79 -10 -115 -18 -36 -8 -95 -15 -132 -15 -102
    -1 -153 34 -166 112 -7 39 -11 44 -32 43 -90 -6 -180 44 -180 99 0 17 -7 39
    -17 49 -13 14 -14 23 -5 42 6 13 8 41 5 62 -7 53 10 91 51 115 19 11 39 33 44
    49 12 34 45 54 79 47 18 -3 28 2 41 21 16 24 67 52 96 52 25 0 47 -56 44 -108
    -3 -41 -1 -47 17 -47 13 0 22 10 29 32 5 18 17 35 27 38 20 7 59 -10 59 -25 0
    -6 9 -10 20 -10 14 0 20 7 20 23 0 37 -35 62 -86 62 -35 0 -45 4 -54 23 -13
    30 -14 29 20 41 20 7 30 17 30 30 0 25 -16 26 -79 6 -25 -8 -65 -19 -88 -25
    -23 -6 -55 -21 -70 -33 -15 -13 -47 -29 -70 -35 -25 -8 -50 -23 -60 -39 -10
    -14 -37 -43 -60 -65 -48 -45 -65 -86 -63 -150 1 -23 -2 -55 -5 -70 -5 -19 -2
    -36 9 -53 9 -13 16 -36 16 -51 0 -17 13 -39 38 -64 34 -35 65 -49 144 -65 19
    -3 30 -14 37 -38 25 -76 106 -120 219 -119 l72 1 28 -42 c30 -44 50 -94 77
    -189 8 -31 19 -59 23 -62 7 -4 73 6 130 19 21 5 23 9 17 49 l-7 43 71 -5 c53
    -3 83 0 116 12 47 19 101 72 111 110 3 14 16 27 30 30 79 20 136 129 138 261
    1 71 -2 85 -28 129 -16 27 -29 66 -30 87 -1 21 -9 46 -18 56 -9 10 -23 33 -32
    50 -9 18 -29 39 -45 47 -19 10 -30 26 -35 47 -9 48 -62 94 -126 109 -30 7 -67
    22 -81 33 -18 12 -55 22 -109 28 -45 5 -86 12 -90 14 -4 3 -13 -2 -20 -10z" />
      <path d="M664 1065 c-9 -23 5 -35 40 -35 33 0 57 -18 69 -52 8 -23 36 -23 44
    -2 9 23 -24 79 -53 93 -36 16 -93 14 -100 -4z" />
      <path d="M184 925 c-4 -8 -4 -22 0 -30 8 -22 -13 -61 -40 -74 -26 -12 -32 -39
    -10 -47 8 -3 27 1 43 9 19 10 44 13 75 10 35 -4 55 -1 73 11 39 26 26 41 -36
    41 -50 0 -54 2 -52 22 6 52 -38 99 -53 58z" />
      <path d="M587 856 c-3 -8 6 -38 20 -67 l26 -52 -30 -26 c-17 -14 -29 -32 -26
    -38 8 -22 41 -14 75 16 27 23 37 27 58 19 13 -5 35 -6 48 -3 36 9 23 36 -26
    50 -53 16 -68 29 -93 77 -20 39 -43 49 -52 24z" />
      <path d="M800 830 c0 -10 8 -24 19 -29 10 -6 26 -27 36 -47 15 -33 16 -40 1
    -82 -10 -31 -12 -50 -5 -57 16 -16 43 8 57 52 17 53 2 115 -38 155 -34 33 -70
    38 -70 8z" />
      <path d="M370 718 c0 -46 -23 -63 -87 -63 -51 0 -58 -2 -58 -20 0 -16 8 -21
    40 -25 54 -8 63 -15 71 -56 7 -38 28 -54 47 -35 8 8 8 22 0 53 -10 36 -9 45 9
    68 11 15 20 43 21 61 2 28 -2 35 -20 37 -18 3 -23 -1 -23 -20z" />
      <path d="M970 611 c0 -10 15 -32 33 -48 26 -23 32 -36 33 -71 1 -43 25 -82 51
    -82 22 0 26 27 9 52 -27 38 -20 62 23 81 44 20 57 40 36 57 -10 9 -21 7 -45
    -5 -35 -18 -59 -14 -85 15 -23 26 -55 26 -55 1z" />
      <path d="M662 579 c-27 -25 -42 -30 -75 -29 -63 3 -67 2 -67 -19 0 -24 19 -31
    80 -31 41 0 55 5 85 31 23 21 35 39 33 52 -5 31 -19 30 -56 -4z" />
      <path d="M817 447 c-3 -8 -2 -20 3 -27 11 -18 42 -4 38 17 -4 22 -34 29 -41
    10z" />
    </g>
  </svg>
  <span v-if="icon == 'drug'" class="icon-drug"></span>
  <span v-if="icon == 'disease'" class="icon-disease"></span>
  <svg v-if="icon == 'arrow'" width="100%" height="100%" fill="currentcolor" version="1.1" id="Layer_1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 386.258 386.258" style="enable-background:new 0 0 386.258 386.258;" xml:space="preserve">
    <polygon points="96.879,0 96.879,386.258 289.379,193.129 " />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <span v-if="icon == 'glossary'" class="icon-glossary"></span>
  <span v-if="icon == 'activities'" class="icon-activities"></span>
</template>
<style scoped>
.side-menu__icon svg {
  fill: #a0aec0
}

.side-menu--active .side-menu__icon svg {
  fill: white
}
</style>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#a0aec0'
    }
  }
})
</script>
