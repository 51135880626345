<template>
  <AlertMessage ref="alertMessage" class="dark" v-bind:message="'<p>Alerta</p>'" />
  <div>

    <div class="intro-y mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t('views.ai.text1') }}
      </h2>

    </div>
    <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">

        <div class="tab-content">
          <div class="tab-pane active" role="tabpanel">

            <!-- BEGIN: Form Layout -->
            <div class="intro-y box p-5">

              <p>{{ $t('views.ai.text2') }}</p>


              <div class="mt-3">
                <div class="input-group">
                  <textarea id="textarea-content" type="text" class="form-control" :placeholder="$t('views.ai.text3')"
                    aria-describedby="input-group-1" v-model="content" rows="25" />
                </div>
              </div>

              <button v-on:click="generate" type="button" class="btn btn-primary w-24 mt-5">
                <LoadingIcon v-if="loading" icon="three-dots" class="" />
                <span v-else>{{ $t('views.ai.generate') }}</span>
              </button>

            </div>
            <!-- END: Form Layout -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.mt-3 {
  color: #fff;
}

.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}

.max-w-label {
  max-width: 90%;
}

/* When the checkbox is checked, add a blue background */
.form-check-input[type=checkbox]:checked {
  background-color: #6c869a;
  border-color: #6c869a;
  color: white;
}

.form-check-input[type=radio]:checked {
  border-color: #6c869a !important;
}

.form-check-input[type=radio]:before {
  background-color: #6c869a !important;
}
</style>
<script>

import ApiService from "/src/services/api.service";
import { TokenService } from '/src/services/storage.service'
import Toastify from "toastify-js";
import AIService from "/src/services/ai.service";

export default {
  components: {},
  setup() { },
  data() {
    return {
      validations: {},
      loading: false,
      langs: this.$global.getLangs(),
      lang_key: localStorage.lang,
      content: ""
    };
  },
  watch: {
  },
  computed: {

  },
  created() {
    var apiUri = ApiService.getApiUri()

  },
  mounted() {

  },
  methods: {
    generate() {

      this.loading = true;

      AIService.generateContent({ content: this.content }).then(result => {

        this.loading = false;

        if (result.choices && result.choices.length > 0) {

          this.content = this.content + " " + result.choices[0].text;

          this.$nextTick(() => {

            var textarea = document.getElementById('textarea-content');
            textarea.scrollTop = textarea.scrollHeight;

          })



        }

      });

    }
  },
};

</script>
<style></style>
