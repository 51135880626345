import ApiService from "./api.service"

const QuizService = {
  getQuizMenuToEdit: async function(params) {
    const response = await ApiService._get("/api/quiz/get-menu-to-edit", params)
    return response.data
  },

  getQuizToEdit: async function(params) {
    const response = await ApiService._get(
      "/api/quiz/get-user-response",
      params
    )
    return response.data
  },

  saveQuizEdited: async function(params) {
    const response = await ApiService.post("/quiz/edit-quiz-question", params)
    return response.data
  },

  getQuizQuestionToDeleteWhenEditing: async function(params) {
    const response = await ApiService._get(
      "/quiz/get-quiz-questions-to-delete-by-editing",
      params
    )
    return response.data
  },

  countPendingQuestionByQuiz: async function(params) {
    const response = await ApiService._get(
      "/quiz/count-pending-questions-by-quiz",
      params
    )
    return response.data
  },

  getQuestionByPage: async function(params) {
    try {
      const response = await ApiService._get(
        "/quiz/get-question-by-page",
        params
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  saveUserResponse: async function(params) {
    const response = await ApiService.post("/quiz/save-user-response", params)
    return response.data
  },

  getQuizResult: async function(params) {
    const response = await ApiService._get("/quiz/get-user-result", params)
    return response.data
  },

  getRecommendations: async function(params) {
    const response = await ApiService.post(
      "/api/user-health-plan/get-recommendation",
      params
    )
    return response.data
  },

  getRecommendationsPdf: async function(params) {
    const response = await ApiService._get("/api/user-health-plan/get", params)
    return response.data
  }
}

export default QuizService
