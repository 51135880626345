<template>
  <component :is="tagName" ref="editorRef" v-editor="{ props, emit, cacheData }" class="select"></component>
</template>
<style>
.ck.ck-editor,
.ck-editor {
  width: 100% !important;
}

.ck.ck-content h1,
.ck.ck-content h2,
.ck.ck-content h3,
.ck.ck-content h4 {
  font-size: revert;
  font-weight: revert;
}

.ck.ck-content ul,
.ck.ck-content ol {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.ck.ck-content a {
  text-decoration: underline;
}

.ck.ck-content p {
  margin: 0.25rem 0px;
}
</style>
<script>
import { defineComponent, inject, onMounted, ref } from 'vue'
import { init } from './index'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default defineComponent({
  directives: {
    editor: {
      mounted(el, { value }) {
        init(el, value)
      }
    }
  },
  props: {
    editor: {
      type: Function,
      default: ClassicEditor
    },
    modelValue: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({
        toolbar: {
          items: [
            'heading', '|',
            'bold', 'italic',
            // 'link',
            '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', '|',
            // 'insertTable', '|',
            'undo', 'redo'
          ],
          shouldNotGroupWhenFull: true
        }
      })
    },
    tagName: {
      type: String,
      default: 'div'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    refKey: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const editorRef = ref()
    const cacheData = ref('')

    const bindInstance = () => {
      if (props.refKey) {
        const bind = inject(`bind[${props.refKey}]`)
        if (bind) {
          bind(editorRef.value)
        }
      }
    }

    onMounted(() => {
      bindInstance()
    })

    return {
      props,
      ...context,
      cacheData,
      editorRef
    }
  }
})
</script>
