import axios from "axios"

const { MOCK_URL_API = "http://localhost:3000" } = process.env

const ApiMockService = {
  get(resource, x_lang = null) {
    let headers = {}
    if (x_lang) {
      headers = {
        "x-lang": x_lang
      }
    }
    return axios.get(`${MOCK_URL_API}/${resource}`, { headers })
  },

  _get(resource, data = {}, x_lang = null) {
    let headers = {}
    if (x_lang) {
      headers = {
        "x-lang": x_lang
      }
    }
    return axios.get(
      `${MOCK_URL_API}/${resource}`,
      { params: data, headers },
      { headers }
    )
  },

  post(resource, data, x_lang) {
    let headers = {}
    if (x_lang) {
      headers = {
        "x-lang": x_lang
      }
    }
    return axios.post(`${MOCK_URL_API}/${resource}`, data, { headers })
  },

  put(resource, data) {
    return axios.put(`${MOCK_URL_API}/${resource}`, data)
  },

  delete(resource) {
    return axios.delete(`${MOCK_URL_API}/${resource}`)
  },

  getApiUri() {
    return MOCK_URL_API
  }
}

export default ApiMockService
