const globals = {
  getLangs() {
    return [
      { key: "es", active: localStorage.lang === "es" },
      { key: "en", active: localStorage.lang === "en" }
    ]
  },
  debounce(fn, delay) {
    var timeoutID = null
    return function() {
      clearTimeout(timeoutID)
      var args = arguments
      var that = this
      timeoutID = setTimeout(function() {
        fn.apply(that, args)
      }, delay)
    }
  },
  getNutritionalContents() {
    return {
      alcohol: null,
      available_carbohydrates: null,
      calcium: null,
      calculated_energy: null,
      carbohydrates: null,
      cholesterol: null,
      energy_unit: null,
      energy_value: null,
      energy_value_kj: null,
      equivalents_of_retinol_activity_of_vitamin_a: null,
      fat: null,
      omega_3: null,
      omega_6: null,
      fatty_acids_monounsaturated_cis: null,
      fatty_acids_polyunsaturated_n3: null,
      fatty_acids_polyunsaturated_n6: null,
      fatty_acids_total_polyunsaturated: null,
      fatty_acids_trans: null,
      fiber: null,
      fiber_insoluble_in_water: null,
      folate: null,
      fructose: null,
      galactose: null,
      glucose: null,
      id: null,
      iodine: null,
      iron: null,
      lactose: null,
      magnesium: null,
      maltose: null,
      monounsaturated_fat: null,
      niacin_equivalents: null,
      phosphor: null,
      polysaccharides_non_cellulosic_soluble_in_water: null,
      polyunsaturated_fat: null,
      potassium: null,
      protein: null,
      riboflavin: null,
      saturated_fat: null,
      selenium: null,
      sodio: null,
      sodium: null,
      starch: null,
      sterols: null,
      sucrose: null,
      sugar: null,
      sugar_alcohols: null,
      thiamine_vitamin_b1: null,
      total_carotenoids: null,
      total_fatty_acids: null,
      total_organic_acids: null,
      total_saturated_fatty_acids: null,
      trans_fat: null,
      tryptophan: null,
      vitamin_a: null,
      vitamin_b12_cobalamin: null,
      vitamin_c: null,
      vitamin_c_ascorbic_acid: null,
      vitamin_d: null,
      vitamin_e_alphatocopferol: null,
      vitamin_k_total: null,
      vitamin_pyridoxine_hydrochloride: null,
      zinc: null,
      colina: null,
      tirosina: null,
      copper: null,
      manganese: null,
      chlorine: null,
      chromium: null,
      fluorine: null,
      leucine: null,
      isoleucine: null,
      valine: null,
      threonine: null,
      arginine: null,
      histidine: null,
      ornithine: null,
      methionine: null,
      vitamin_b5: null,
      biotin: null,
      soluble_fiber: null,
      unsaturated_fat: null,
      omega_9: null,
      tryptophan: null,
      oxalate: null,
      purine: null
    }
  },
  getUsersApprovedFood() {
    return [3, 4]
  },
  getPosologyKey() {
    return [
      "BEFORE_EAT",
      "AFTER_EAT",
      "AFTER_DINNER",
      "AFTER_WORKOUT",
      "WITH_FOOD",
      "ANYTIME",
      "1_HR_BEFORE_SLEEP",
      "FASTING"
    ]
  },
  getTypeNutrient() {
    return {
      macronutrients: [
        "protein",
        "carbohydrates",
        "available_carbohydrates",
        "cholesterol",
        "fat",
        "monounsaturated_fat",
        "polyunsaturated_fat",
        "unsaturated_fat",
        "saturated_fat",
        "trans_fat",
        "sugar",
        "fiber",
        "fiber",
        "fiber_insoluble_in_water",
        "soluble_fiber",
        "fructose",
        "galactose",
        "glucose",
        "lactose",
        "maltose"
      ],
      micronutrients: [
        "calcium",
        "chlorine",
        "copper",
        "chromium",
        "fluorine",
        "phosphor",
        "iron",
        "magnesium",
        "manganese",
        "potassium",
        "selenium",
        "sodio",
        "iodine",
        "zinc"
      ],
      vitamins: [
        "equivalents_of_retinol_activity_of_vitamin_a",
        "vitamin_a",
        "thiamine_vitamin_b1",
        "riboflavin",
        "niacin_equivalents",
        "vitamin_b5",
        "vitamin_pyridoxine_hydrochloride",
        "folate",
        "vitamin_b12_cobalamin",
        "vitamin_c_ascorbic_acid",
        "vitamin_d",
        "vitamin_e_alphatocopferol",
        "vitamin_k_total",
        "biotin",
        "colina"
      ],
      omega: ["omega_3", "omega_6", "omega_9"],
      aminoacid: [
        "tryptophan",
        "threonine",
        "isoleucine",
        "leucine",
        "methionine",
        "tirosina",
        "valine",
        "arginine",
        "histidine",
        "ornithine",
        "oxalate"
      ]
    }
  },
  getWeathers() {
    return [
      "CLEAR",
      "SUNNY",
      "FEW_CLOUDS",
      "CLOUDS",
      "MIST",
      "SNOW",
      "RAIN",
      "DRIZZLE",
      "THUNDERSTORM"
    ]
  },
  getMoments() {
    return ["MORNING", "NOON", "AFTERNOON", "AFTERNOON_NIGHT", "NIGHT"]
  },
  getTypeDiet() {
    return [
      "omnivorous",
      "ovolactovegetarian",
      "pescetarian",
      "vegan",
      "ketogenic",
      "kosher",
      "halal",
      "flexitarian",
      "mediterranean",
      "paleo",
      "carnivorous",
      "rawvegan"
    ]
  },
  getTypeCuisine() {
    return [
      "ASIAN",
      "MEDITERRANEAN",
      "LATINAMERICAN",
      "ARAB",
      "EUROPEAN",
      "INTERNATIONAL"
    ]
  },
  getTypeCuisineCountry() {
    return {
      ASIAN: ["CHINA", "JAPAN", "KOREA", "VIETNAM", "THAILAND", "INDIA"],
      MEDITERRANEAN: ["SPAIN", "ITALY", "GREECE", "TURKEY", "MOROCCO"],
      LATINAMERICAN: [
        "MEXICO",
        "PERU",
        "ARGENTINA",
        "BRAZIL",
        "COLOMBIA",
        "VENEZUELA"
      ],
      ARAB: [
        "MIDDLEEAST",
        "EGYPT",
        "SYRIA",
        "LEBANON",
        "ISRAEL",
        "SAUDIARABIA"
      ],
      EUROPEAN: ["FRANCE", "GERMANY", "ENGLAND", "PORTUGAL"],
      INTERNATIONAL: ["ALL"]
    }
  },
  getApplyTo() {
    return ["breakfast", "lunch", "snack", "dinner"]
  },
  getDificulty() {
    return ["EASY", "MODERATE", "ADVANCED"]
  },
  getStatus() {
    ["UNREVIEWED", "REVIEWED"]
  }
}

const install = app => {
  app.config.globalProperties.$global = globals
}

export { install as default, globals as global }
