<template>
    <div v-if="C.length > 0 || R.length > 0" class="grid grid-cols-12 gap-6" v-bind:class="{ 'mt-5': !isFinalResult }">

        <template v-if="!isFinalResult">
            <div class="intro-y box col-span-12 lg:col-span-6">
                <div :class="{ 'border-b': showDiv }"
                    class="flex items-center px-5 py-3 border-gray-200 dark:border-dark-5 accordion-header">
                    <h2 @click="showDiv = !showDiv" class="pointer font-medium text-base mr-auto"
                        style="font-size:1.05rem">
                        {{ title }}
                    </h2>
                    <span :class="[showCalculation ? 'opacity-100' : 'opacity-0']"
                        @click="showCalculation = !showCalculation;">{{ totalA }} vs {{ totalB }} | M: {{ totalMatch }}
                        | C:
                        {{ totalLabAddReduce }} | S: {{ totalLabSuspend }} | DE: {{ totalDoubleEvaluated }}</span>
                </div>
                <div :class="{ hidden: !showDiv }" class="p-5 pb-0">
                    <div>
                        <div>

                            <template v-for="(item, itemKey) in C" :key="itemKey">
                                <div class="flex justify-between">
                                    <div class="border-l-2 border-theme-1 pl-4 mb-5 flex">
                                        <div class="font-medium mr-3 text-white"
                                            v-bind:class="{ 'opacity-25': !item.existsA }">{{ item.name }} {{
        item.amountA }}
                                        </div>
                                        <img v-if="item.labExistsA" alt="lab-icon" class="lab-icon"
                                            src="@/assets/images/ilong/flask.png" />
                                        <template v-if="item.match">
                                            <template
                                                v-if="isAddReduceComponent && item.existsA && (item.addA || item.reduceA)">

                                                <img v-if="item.addA && item.increaseA && !item.increaseB"
                                                    src="@/assets/images/ilong/item-match-add.png" class="square-img" />
                                                <img v-if="item.addA && item.increaseA && item.increaseB"
                                                    src="@/assets/images/ilong/item-match-add.png" class="square-img" />
                                                <img v-if="item.addA && !item.increaseA && !item.increaseB"
                                                    src="@/assets/images/ilong/item-match.png" class="square-img" />

                                                <img v-if="!item.addA" src="@/assets/images/ilong/item-match-reduce.png"
                                                    class="square-img" />

                                            </template>
                                            <template v-else-if="isAddReduceComponent && item.existsA && item.suspendA">

                                                <img src="@/assets/images/ilong/item-suspend.png" class="square-img" />

                                            </template>
                                            <template v-else>

                                                <img src="@/assets/images/ilong/item-match.png" class="square-img" />

                                            </template>
                                        </template>
                                        <template v-else>
                                            <template
                                                v-if="isAddReduceComponent && item.existsA && (item.addA || item.reduceA)">

                                                <img v-if="item.addA && item.increaseA"
                                                    src="@/assets/images/ilong/item-add.png" class="square-img" />
                                                <img v-if="!item.addA" src="@/assets/images/ilong/item-reduce.png"
                                                    class="square-img" />

                                            </template>
                                            <template v-else-if="isAddReduceComponent && item.existsA && item.suspendA">

                                                <img src="@/assets/images/ilong/item-suspend.png" class="square-img" />

                                            </template>
                                        </template>

                                    </div>
                                    <div>
                                        <span v-if="item.whiteDotA" class="mb-2 mt-2 dot"></span>
                                        <!-- <span v-if="item.whiteDotA && !item.match" class="mb-2 mt-2 dot"></span> -->
                                        <!-- <span v-if="item.whiteDotA && item.match && ( (item.labRecommendedA && islabIncludedA) || !islabIncludedA )" class="mb-2 mt-2 dot"></span> -->

                                    </div>
                                </div>
                                <template v-if="item.items">
                                    <template v-for="(item2, itemKey2) in item.items" :key="itemKey2">
                                        <div class="flex justify-between">
                                            <div class="pl-8 mb-5">
                                                <div class="font-medium text-white"
                                                    v-bind:class="{ 'opacity-25': !item2.existsA }">{{ item2.name }}
                                                </div>
                                            </div>
                                            <div>
                                                <span v-if="item2.whiteDotA" class="mb-2 mt-2 dot"></span>
                                            </div>
                                        </div>

                                        <template v-if="item2.items">
                                            <div v-for="(item3, itemKey3) in item2.items" :key="itemKey3"
                                                class="flex justify-between">
                                                <div class="pl-12 mb-5">
                                                    <div class="font-medium text-white"
                                                        v-bind:class="{ 'opacity-25': !item3.existsA }">{{ item3.name }}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span v-if="item3.whiteDotA" class="mb-2 mt-2 dot"></span>
                                                </div>
                                            </div>
                                        </template>
                                    </template>

                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="intro-y box col-span-12 lg:col-span-6">
                <div :class="{ 'border-b': showDiv }"
                    class="flex items-center px-5 py-3 border-gray-200 dark:border-dark-5">
                    <h2 @click="showDiv = !showDiv" class="pointer font-medium text-base mr-auto"
                        style="font-size:1.05rem">
                        <Tippy v-if="message_B" data-theme="light" :content="message_B">
                            {{ title }}
                        </Tippy>
                        <span v-if="!message_B">{{ title }}</span>
                    </h2>
                </div>
                <div :class="{ hidden: !showDiv }" class="p-5 pb-0">
                    <div>
                        <div>

                            <template v-for="(item, itemKey) in C" :key="itemKey">
                                <div class="flex justify-between">
                                    <div class="border-l-2 border-theme-1 pl-4 mb-5 flex">
                                        <div class="font-medium mr-3 text-white"
                                            v-bind:class="{ 'opacity-25': !item.existsB }">{{ item.name }} {{
        item.amountB }}
                                        </div>
                                        <img v-if="item.labExistsB" alt="lab-icon" class="lab-icon"
                                            src="@/assets/images/ilong/flask.png" />

                                        <template v-if="item.match">
                                            <template
                                                v-if="isAddReduceComponent && item.existsB && (item.addB || item.reduceB)">

                                                <img v-if="item.addB && item.increaseB && !item.increaseA"
                                                    src="@/assets/images/ilong/item-match-add.png" class="square-img" />
                                                <img v-if="item.addB && item.increaseB && item.increaseA"
                                                    src="@/assets/images/ilong/item-match-add.png" class="square-img" />
                                                <img v-if="item.addB && !item.increaseB && !item.increaseA"
                                                    src="@/assets/images/ilong/item-match.png" class="square-img" />

                                                <img v-if="!item.addB" src="@/assets/images/ilong/item-match-reduce.png"
                                                    class="square-img" />

                                            </template>
                                            <template v-else-if="isAddReduceComponent && item.existsB && item.suspendB">

                                                <img src="@/assets/images/ilong/item-suspend.png" class="square-img" />

                                            </template>
                                            <template v-else>

                                                <img src="@/assets/images/ilong/item-match.png" class="square-img" />

                                            </template>
                                        </template>
                                        <template v-else>
                                            <template
                                                v-if="isAddReduceComponent && item.existsB && (item.addB || item.reduceB)">

                                                <img v-if="item.addB && item.increaseB"
                                                    src="@/assets/images/ilong/item-add.png" class="square-img" />
                                                <img v-if="!item.addB" src="@/assets/images/ilong/item-reduce.png"
                                                    class="square-img" />

                                            </template>
                                            <template v-else-if="isAddReduceComponent && item.existsB && item.suspendB">

                                                <img src="@/assets/images/ilong/item-suspend.png" class="square-img" />

                                            </template>
                                        </template>


                                    </div>
                                    <div>
                                        <span v-if="item.whiteDotB" class="mb-2 mt-2 dot"></span>
                                        <!-- <span v-if="item.whiteDotB && item.match && ( (item.labRecommendedB && islabIncludedB) || !islabIncludedB )" class="mb-2 mt-2 dot"></span> -->
                                    </div>
                                </div>
                                <template v-if="item.items">
                                    <template v-for="(item2, itemKey2) in item.items" :key="itemKey2">
                                        <div class="flex justify-between">
                                            <div class="pl-8 mb-5">
                                                <div class="font-medium text-white"
                                                    v-bind:class="{ 'opacity-25': !item2.existsB }">{{ item2.name }}
                                                </div>
                                            </div>
                                            <div>
                                                <span v-if="item2.whiteDotB" class="mb-2 mt-2 dot"></span>
                                            </div>
                                        </div>

                                        <template v-if="item2.items">
                                            <div v-for="(item3, itemKey3) in item2.items" :key="itemKey3"
                                                class="flex justify-between">
                                                <div class="pl-12 mb-5">
                                                    <div class="font-medium text-white"
                                                        v-bind:class="{ 'opacity-25': !item3.existsB }">{{ item3.name }}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span v-if="item3.whiteDotB" class="mb-2 mt-2 dot"></span>
                                                </div>
                                            </div>
                                        </template>
                                    </template>

                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="intro-y box col-span-12 mb-5">
                <div :class="{ 'border-b': showDiv }"
                    class="flex items-center px-5 py-3 border-gray-200 dark:border-dark-5 accordion-header">
                    <h2 @click="showDiv = !showDiv" class="pointer font-medium text-base mr-auto"
                        style="font-size:1.05rem">
                        <Tippy v-if="message_A" data-theme="light" :content="message_A">
                            {{ title }}
                        </Tippy>
                        <span v-if="!message_A">{{ title }}</span>
                    </h2>
                </div>
                <div :class="{ hidden: !showDiv }" class="p-5 pb-0">
                    <div>
                        <div>
                            <template v-for="(item, itemKey) in finalC" :key="itemKey">
                                <div class="flex justify-between">
                                    <div class="border-l-2 border-theme-1 pl-4 mb-5">
                                        <div class="font-medium text-white">{{ item.name }}</div>
                                    </div>
                                </div>
                                <template v-if="item.items">
                                    <template v-for="(item2, itemKey2) in item.items" :key="itemKey2">
                                        <div class="flex justify-between">
                                            <div class="pl-8 mb-5">
                                                <div class="font-medium text-white">{{ item2.name }}</div>
                                            </div>
                                        </div>

                                        <template v-if="item2.items">
                                            <div v-for="(item3, itemKey3) in item2.items" :key="itemKey3"
                                                class="flex justify-between">
                                                <div class="pl-12 mb-5">
                                                    <div class="font-medium text-white">{{ item3.name }}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>

                                </template>
                            </template>


                            <div v-if="finalR.length > 0" class="flex items-center mt-8 mb-5">
                                <div>
                                    <div class="text-white font-medium">{{ $t('views.sessions.text1') }}:</div>
                                </div>
                            </div>
                            <div v-for="(item, itemKey) in finalR" :key="itemKey" class="flex justify-between">
                                <div class="border-l-2 border-theme-1 pl-4 mb-5">
                                    <div class="font-medium text-white">{{ item.name }}</div>
                                </div>
                            </div>

                            <div v-if="finalS.length > 0" class="flex items-center mt-8 mb-5">
                                <div>
                                    <div class="text-white font-medium">{{ $t('views.sessions.text2') }}:</div>
                                </div>
                            </div>
                            <div v-for="(item, itemKey) in finalS" :key="itemKey" class="flex justify-between">
                                <div class="border-l-2 border-theme-1 pl-4 mb-5">
                                    <div class="font-medium text-white">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<style scoped>
.dot {
    height: 5px;
    width: 5px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
}

.pointer {
    cursor: pointer;
}

.lab-icon {
    width: 15px;
    height: 15px;
}

.square-icon {
    line-height: 15px;
    height: 15px;
    width: 15px;
    text-align: center;
}

.square-img {
    height: 15px;
    width: 15px;
    margin-top: 3px;
}
</style>
<script>

export default {
    components: {

    },
    setup() {
    },
    props: {
        title: "",
        message_A: null,
        message_B: null,
        isFinalResult: false
    },
    data() {
        return {
            C: [],
            finalC: [],
            R: [],
            finalR: [],
            S: [],
            finalS: [],
            type: "",
            showDiv: true,
            totalA: 0,
            totalB: 0,
            totalMatch: 0,
            totalLabAddReduce: 0,
            totalLabSuspend: 0,
            totalDoubleEvaluated: 0,
            islabIncludedA: false,
            islabIncludedB: false,
            component: "",
            showCalculation: false,
        }
    },
    watch: {

    },
    computed: {
        isAddReduceComponent() {
            return ['foodModality', 'foods', 'supplements', 'exercise'].includes(this.component);
        },
    },
    created() {

    },
    methods: {
        refresh(params) {

            this.islabIncludedA = params.isLabIncludedA;
            this.islabIncludedB = params.isLabIncludedB;
            this.component = params.component;

            this.display(params.A, params.B, "ADD", params.key);
            this.type = params.type;

            if (this.isFinalResult) {

                this.display(params.AReduce, params.BReduce, "REDUCE", params.key);
                this.display(params.ASuspend, params.BSuspend, "SUSPEND", params.key);

                this.mergeResult();
            }

            this.$emit("setMatch", params.component, this.totalA, this.totalB, this.totalMatch, this.totalLabAddReduce, this.totalLabSuspend, this.totalDoubleEvaluated);

        },
        display(A, B, type, key) {


            if (type == "ADD") {
                this.totalA = A.length;
                this.totalB = B.length;
                this.totalMatch = 0;
                this.totalLabSuspend = 0;
                this.totalLabAddReduce = 0;
                this.totalDoubleEvaluated = 0;
            }

            var C = A.concat(B);
            C = C.filter((v, i, a) => a.findIndex(t => (t[key] === v[key])) === i);
            C.sort((a, b) => a.name.localeCompare(b.name));
            C.forEach(element => {

                element.existsA = false;
                element.existsB = false;
                element.whiteDotA = false;
                element.whiteDotB = false;
                element.reduceA = false;
                element.reduceB = false;
                element.labExistsA = false;
                element.labExistsB = false;
                element.addA = false;
                element.addB = false;
                element.suspendA = false;
                element.suspendB = false;
                element.increaseA = false;
                element.increaseB = false;
                element.match = false;
                element.labRecommendedA = false;
                element.labRecommendedB = false;
                element.amountA = "";
                element.amountB = "";

                var existsA = A.find(x => x[key] == element[key]);
                if (existsA) {
                    element.existsA = true;
                    element.addA = true;
                    element.labRecommendedA = existsA.labRecommended || false;

                    if (existsA.exists) {
                        element.labExistsA = true;
                    } else {
                        element.whiteDotA = true;
                    }

                    if (existsA.labExists) {
                        element.labExistsA = true;
                        element.whiteDotA = false;
                    }

                    if (existsA.reduce) {
                        element.reduceA = true;
                        element.addA = false;
                    }

                    if (existsA.suspend) {
                        element.suspendA = true;
                        element.addA = false;
                    }

                    if (existsA.labRecommended && !element.labExistsA) {
                        element.whiteDotA = true;
                    }

                    element.increaseA = existsA.increase || false;

                    if (existsA.amount) {
                        element.amountA = existsA.amount
                    }


                }

                var existsB = B.find(x => x[key] == element[key]);
                element.existsB = false;
                if (existsB) {
                    element.existsB = true;
                    element.addB = true;
                    element.labRecommendedB = existsB.labRecommended || false;

                    if (existsB.exists) {
                        element.labExistsB = true;
                    } else {
                        element.whiteDotB = true;
                    }

                    if (existsB.labExists) {
                        element.labExistsB = true;
                        element.whiteDotB = false;
                    }

                    if (existsB.reduce) {
                        element.reduceB = true;
                        element.addB = false;
                    }

                    if (existsB.suspend) {
                        element.suspendB = true;
                        element.addB = false;
                    }

                    if (existsB.labRecommended && !element.labExistsB) {
                        element.whiteDotB = true;
                    }

                    element.increaseB = existsB.increase || false;

                    if (existsB.amount) {
                        element.amountB = existsB.amount
                    }

                }

                if (element.existsA && element.existsB) {

                    this.totalDoubleEvaluated++;

                    if (((element.addA && element.addA == element.addB) ||
                        (element.reduceA && element.reduceA == element.reduceB) ||
                        (element.suspendA && element.suspendA == element.suspendB))) {

                        if (type == "ADD") {
                            this.totalMatch++;
                        }

                        if (!element.labExistsA && !element.labExistsB) {
                            element.match = true;
                        }

                        if (element.addA && element.addA == element.addB) {

                            element.whiteDotA = false;
                            element.whiteDotB = false;

                            if (this.islabIncludedA) {
                                element.whiteDotA = true;
                            }

                            if (this.islabIncludedB) {
                                element.whiteDotB = true;
                            }

                            if (element.increaseA && !element.increaseB) {
                                element.whiteDotA = true;
                                element.whiteDotB = false;
                            }
                            else if (element.increaseB && !element.increaseA) {
                                element.whiteDotA = false;
                                element.whiteDotB = true;
                            }

                            if (element.labExistsA) {
                                element.whiteDotA = false;
                            }

                            if (element.labExistsB) {
                                element.whiteDotB = false;
                            }
                        }
                        else if (element.reduceA && element.reduceA == element.reduceB) {

                            element.whiteDotA = false;
                            element.whiteDotB = false;

                            if (element.labRecommendedA) {
                                element.whiteDotA = true;
                            }

                            if (element.labRecommendedB) {
                                element.whiteDotB = true;
                            }

                        }

                    } else {

                        if (element.labRecommendedA) {
                            element.whiteDotB = false;
                        }

                        if (element.labRecommendedB) {
                            element.whiteDotA = false;
                        }

                        if (this.islabIncludedA && !this.islabIncludedB) {

                            if (element.suspendA) {
                                this.totalLabSuspend++;
                            } else {
                                this.totalLabAddReduce++;
                            }

                        }

                        else if (!this.islabIncludedA && this.islabIncludedB) {

                            if (element.suspendB) {
                                this.totalLabSuspend++;
                            } else {
                                this.totalLabAddReduce++;
                            }

                        }

                    }

                }

            });

            if (type == "ADD") {
                this.C = C;
            } else if (type == "REDUCE") {
                this.R = C;
            } else {
                this.S = C
            }

        },
        mergeResult() {
            this.finalC = this.C.filter(x => !(x.reduceA || x.reduceB));
            this.finalC = this.C.filter(x => !(x.suspendA || x.suspendB));
            this.finalC = this.finalC.filter(x => !x.exists);
            this.finalC = this.finalC.filter(x => !x.labExists);
            this.finalR = this.R;
            this.finalS = this.S;

        },
        refreshTree(params) {

            var A = params.A;
            var B = params.B;
            var component = params.component;

            this.totalA = this.countChildrenInTree(A);
            this.totalB = this.countChildrenInTree(B);

            var C = A.concat(B);
            var D = [];
            C.forEach(element => {

                var exist = D.find(x => x.id == element.id);
                if (!exist) {
                    D.push(element);
                } else {

                    if (element.items && element.items.length > 0) {

                        element.items.forEach(element2 => {

                            var exist2 = exist.items.find(x => x.id == element2.id);
                            if (!exist2) {
                                exist.items.push(element2);
                            } else {

                                if (element2.items && element2.items.length > 0) {

                                    element2.items.forEach(element3 => {

                                        var exist3 = exist2.items.find(x => x.id == element3.id);
                                        if (!exist3) {
                                            exist2.items.push(element3);
                                        }

                                    })

                                }

                            }

                        })

                    }

                }

            });

            this.totalMatch = 0;
            this.totalDoubleEvaluated = 0;
            this.setExistAorBInTree(A, B, D)

            this.C = D;

            if (this.isFinalResult) {
                this.mergeResult();
            }

            var menor = this.totalA;
            if (this.totalB < menor) {
                menor = this.totalB
            }

            if (this.totalMatch > menor) {
                this.totalMatch = menor;
            }

            if (this.totalDoubleEvaluated > menor) {
                this.totalDoubleEvaluated = menor;
            }

            this.$emit("setMatch", component, this.totalA, this.totalB, this.totalMatch, 0, 0, this.totalDoubleEvaluated);


        },
        findInTree(element, id) {

            if (Array.isArray(element)) {
                element = { unique_id: 0, items: element }
            }

            if (element.unique_id == id) {
                return element;
            } else if (element.items != null) {
                var i;
                var result = null;
                for (i = 0; result == null && i < element.items.length; i++) {
                    result = this.findInTree(element.items[i], id);
                }
                return result;
            }
            return null;
        },
        setExistAorBInTree(A, B, items) {

            if (items && items.length > 0) {

                items.forEach(item => {

                    var existsA = this.findInTree(A, item.unique_id)
                    item.existsA = false;
                    if (existsA) {
                        item.existsA = true;
                        item.whiteDotA = true;
                    }

                    var existsB = this.findInTree(B, item.unique_id)
                    item.existsB = false;
                    if (existsB) {
                        item.existsB = true;
                        item.whiteDotB = true;
                    }

                    if (item.existsA && item.existsB) {
                        item.match = true;
                        this.totalMatch++;
                        this.totalDoubleEvaluated++;
                    }

                    this.setExistAorBInTree(A, B, item.items);

                })

            }

        },
        countChildrenInTree(T) {

            var total = 0;

            if (T.length > 0) {

                T.forEach(a => {

                    if (a.items && a.items.length > 0) {

                        a.items.forEach(b => {

                            if (b.items && b.items.length > 0) {

                                b.items.forEach(c => {

                                    if (c.items && c.items.length > 0) {
                                        total = total + c.items.length;
                                    } else {
                                        total++;
                                    }

                                })

                            } else {
                                total++;
                            }
                        })

                    } else {
                        total++
                    }

                })
            }

            return total;

        },
        toggleCalculations() {
            alert("hey");
            this.showCalculation = !this.showCalculation;
        }
    }
};

</script>
