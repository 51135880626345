<template v-if="settings.options.length > 5">
  <div class="my-4 receipe-item">
    <div class="flex gap-y-8 flex-col sm:flex-row mt-10 sm:mt-auto">
      <!-- <TomSelect ref="ingredient" class="w-full sm:w-1/3 cursor mr-8" v-model="ingredient.food_id"
        :placeholder="$t('components.select_food')" @change="changeFood" :maxOptions="foods.length">
        <option value="0">{{ $t('components.select_food') }}</option>
        <option v-for="(food, key) in foods" :key="key" :value="food.id">{{ food.name }}</option>
      </TomSelect> -->
      <TomSelectV2 v-model="ingredient.food_id" :options="settings" class="w-full sm:w-1/3 cursor mr-8"
        @change="updateFood"></TomSelectV2>
      <div v-if="g_index && g_index.val != 0" class="alert show py-1 px-4 w-1/6 flex justify-center items-center mr-8"
        v-bind:class="g_index.color" role="alert">
        <Tippy data-theme="light" :content="$t('components.glycemic_index')">{{ g_index.text }}</Tippy>
      </div>
      <div v-else class="alert alert-secondary show py-1 px-4 w-1/6 flex justify-center items-center mr-8" role="alert">
        <Tippy data-theme="light" :content="$t('components.glycemic_index') + ':' + $t('components.not_apply')">-
        </Tippy>
      </div>
      <input type="text" name="chart-tooltip" class="form-control w-full sm:w-1/5 mr-8"
        :placeholder="$t('components.quantity')" aria-describedby="input-group-unit" v-model="ingredient.quantity"
        @blur="add" v-on:keyup.enter="add" @change="enableGEquivalent" />
      <div
        :class="'w-full sm:w-1/4 cursor mr-6 rounded border ' + ((showIngredientErrors && (ingredient.unit_id === '0' || ingredient.unit_id === '') && ingredient.food_id !== '0' && ingredient.food_id !== '') ? 'border-red-600' : 'border-transparent')">
        <TomSelect ref="unit" :class="''" v-model="ingredient.unit_id" :create="false"
          :placeholder="$t('components.select_unit')" @change="changeUnit">
          <option value="0">{{ $t('components.select_unit') }}</option>
          <option v-for="(unit, key) in units" :key="key" :value="unit.id">{{ unit.value }}</option>
        </TomSelect>
      </div>
      <input v-bind:class="`${ingredient.unit_id == 2 ? 'opacity-50' : 'opacity-1'}`"
        :disabled="ingredient.unit_id == 2" type="number" name="g_equivalent" class="form-control w-full sm:w-1/5 mr-8"
        placeholder="gr" aria-describedby="input-group-unit" v-model="ingredient.g_equivalent"
        v-on:keydown="getGEquivalent" @blur="add" v-on:keyup.enter="add" />
      <button v-on:click="remove(ingredient)" type="button" class="btn-outline-dark btn-rounded btn-sm remove-item">
        <XCircleIcon class="btn-source-icon" />
      </button>

    </div>
  </div>
</template>
<style>
.dark .tom-select .ts-dropdown {
  width: 100% !important;
}

.tom-select .ts-input .item {
  display: inline-flex;
  align-items: center;
}

.dark .tom-select .ts-input {
  height: 100%;
}
</style>
<script>
import { defineComponent, ref } from 'vue'
import FoodService from "/src/services/food.service";

export default defineComponent({
  setup() {

  },
  props: ['foods', 'ingredient', 'units', 'setUnits', 'add', 'remove', 'changeFood', 'glycemics_index', 'showIngredientErrors', 'clearIngredient', 'recipe'],
  data() {
    return {
      unitsAll: [],
      g_index: null,
      placeholder: [
        { id: '0', name: 'Seleccione' }
      ],
      searchOptions: [],
      settings: {
        labelField: 'name',
        valueField: 'id',
        searchField: ['name'],
        sortField: [
          { field: 'name', direction: 'asc' }
        ],
        options: [],
        loadThrottle: 1200,
        load: function () { },
      },
      init: true
    }
  },
  watch: {
    showIngredientErrors: {
      handler: function (val) {
        console.log("Show errors: ", this.showIngredientErrors, val)
      }
    },
    ingredient: {
      handler: function (val) {
        var units = this.units
        var valUnit = val.unit_id
        var unit = units.find(function (cc) {
          return cc.id == valUnit
        })
        if (!unit) {
          units.push({ id: valUnit, value: valUnit })
        }

        if (unit && this.ingredient.quantity && (unit.id == 2 || !this.ingredient.edited_g_equivalent)) {
          let quantity = this.ingredient.quantity
          if (this.ingredient.quantity.includes("/")) {
            let q = this.ingredient.quantity.trim().replace(/ /g, "").split("/")
            if (q.length > 1)
              quantity = q[0] / q[1]
          }

          if (unit && unit.g_equivalent)
            this.ingredient.g_equivalent = unit.g_equivalent * quantity
        }

        this.setUnits(units)
        // this.getIndex()
      },
      deep: true
    },
    foods: {
      handler: function (val) {
        if (this.init && this.settings.options.length < 2) {
          this.init = false;
          // this.options = [...this.options, ...val];
          this.settings.options = [...this.placeholder, ...val]
          this.searchOptions = [...this.placeholder, ...val];
          this.getIndex();
        }

        var food = this.foods.find(function (f) {
          return f.id === parseInt(this.ingredient.food_id)
        }.bind(this))

        if (!food && this.ingredient.food_id !== "") {
          this.getIndex()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.g_index = this.glycemics_index[0]
    var index = 0
    if (this.ingredient.food && this.ingredient.food.categories) {
      index = this.ingredient.food.categories.includes(5) ? 1 : index
      index = this.ingredient.food.categories.includes(6) ? 3 : index
      index = this.ingredient.food.categories.includes(96) ? 5 : index

      this.g_index = (index === 0) ? null : this.glycemics_index[index]
    }

    let self = this;

    this.settings.options = [...this.placeholder, ...this.foods]
    this.searchOptions = [...this.placeholder, ...this.foods];

    if (this.settings.options.length < 5) {
      this.getFoods()
    }

    this.settings.render = {
      item: function (item, escape) {
        if (item.hasOwnProperty('categories'))
          self.searchOptions.push(item);
        return '<div data-id="' + escape(item.id) + '" data-name="' + escape(item.name) + '">' + escape(item.name) + '</div>';
      },
      option: function (item, escape) {

        const cat = item && item.categories ? item.categories.join("-") : ""
        return '<div data-categories="' + cat + '">' + escape(item.name) + '</div>';
      }
    }
    this.settings.onItemAdd = (value, $item) => {
      const newOption = { ...$item.dataset }
      var food = this.searchOptions.find(function (f) {
        return parseInt(f.id) === parseInt(newOption.value)
      })
      newOption.food = food
      self.settings.options = [...self.settings.options, newOption]
    }

    this.settings.load = function (query, callback) {
      const params = {
        search: encodeURIComponent(query),
        pagination: {
          page: 1,
          per_page: 50,
          total: 1,
          total_pages: 1
        }
      }
      const typeDiet = this.$global.getTypeDiet()
      typeDiet.forEach((d) => {
        if (this.recipe[d])
          params[d] = 1
      })
      FoodService.getFoodItems(params, this.global_lang)
        .then(response => {
          this.searchOptions = response.data;
          callback(response.data);
        }).catch(() => {
          callback();
        });

    }.bind(this)

    // console.log(this.foods)
  },
  methods: {
    getIndex(ev) {
      var index = 0
      var _this = this

      var food = this.searchOptions.find(function (f) {
        return parseInt(f.id) === parseInt(_this.ingredient.food_id)
      })

      if (food)
        this.ingredient.food = { ...food }

      if (food && food.categories) {
        index = food.categories.includes(5) ? 1 : index
        index = food.categories.includes(6) ? 3 : index
        index = food.categories.includes(96) ? 5 : index
      }

      this.g_index = (index === 0) ? null : this.glycemics_index[index]
    },
    getGEquivalent(ev) {
      this.ingredient.edited_g_equivalent = true
    },
    enableGEquivalent(ev) {
      this.ingredient.edited_g_equivalent = false
    },
    changeUnit() {
      this.ingredient.edited_g_equivalent = false
      this.add()
    },
    updateFood(ev) {
      this.changeFood()
      this.getIndex(ev)
    },
    getFoods: async function (lang = null) {
      const params = {
        // ingredients: true, 
        pagination: {
          page: 1,
          per_page: 50,
          total: 1,
          total_pages: 1
        }
      }
      const typeDiet = this.$global.getTypeDiet()
      typeDiet.forEach((d) => {
        if (this.recipe[d])
          params[d] = 1
      })

      const newFoods = await FoodService.getFoodItems(params, this.global_lang)
      const foods = (newFoods.data) ? newFoods.data : newFoods

      this.settings.options = [...this.settings.options, ...foods]
    },

  }
})
</script>
<style>
.input-text-white .ts-input input {
  color: #fff;
}
</style>