const lang = {
  PERJUDICIAL_MODERADO_BAJO: {
    key: "PERJUDICIAL_MODERADO_BAJO",
    color: "#EC4E1A",
    level: -5,
    name: "lab.PERJUDICIAL_MODERADO_BAJO"
  },
  PERJUDICIAL_LEVE_BAJO: {
    key: "PERJUDICIAL_LEVE_BAJO",
    color: "#F1C232",
    level: -4,
    name: "lab.PERJUDICIAL_LEVE_BAJO"
  },
  SUBOPTIMO_BAJO_3: {
    key: "SUBOPTIMO_BAJO_3",
    color: "#DEDC01",
    level: -3,
    name: "lab.SUBOPTIMO_BAJO_3"
  },
  SUBOPTIMO_BAJO_2: {
    key: "SUBOPTIMO_BAJO_2",
    color: "#DEDC01",
    level: -2,
    name: "lab.SUBOPTIMO_BAJO_2"
  },
  SUBOPTIMO_BAJO: {
    key: "SUBOPTIMO_BAJO",
    color: "#95C11E",
    level: -1,
    name: "lab.SUBOPTIMO_BAJO"
  },
  OPTIMO: {
    key: "OPTIMO",
    color: "#47BA0C",
    level: 0,
    name: "lab.OPTIMO"
  },
  SUBOPTIMO_ALTO: {
    key: "SUBOPTIMO_ALTO",
    color: "#95C11E",
    level: 1,
    name: "lab.SUBOPTIMO_ALTO"
  },
  SUBOPTIMO_ALTO_2: {
    key: "SUBOPTIMO_ALTO_2",
    color: "#DEDC01",
    level: 2,
    name: "lab.SUBOPTIMO_ALTO_2"
  },
  PERJUDICIAL_LEVE_ALTO: {
    key: "PERJUDICIAL_LEVE_ALTO",
    color: "#F1C232",
    level: 3,
    name: "lab.PERJUDICIAL_LEVE_ALTO"
  },
  PERJUDICIAL_MODERADO_ALTO: {
    key: "PERJUDICIAL_MODERADO_ALTO",
    color: "#EC4E1A",
    level: 4,
    name: "lab.PERJUDICIAL_MODERADO_ALTO"
  },
  PERJUDICIAL_GRAVE_ALTO: {
    key: "PERJUDICIAL_GRAVE_ALTO",
    color: "#E63428",
    level: 5,
    name: "lab.PERJUDICIAL_GRAVE_ALTO"
  }
}
export { lang as default }
