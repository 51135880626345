<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h2 class="intro-y text-lg font-medium mt-10">{{ $t('views.sessions.text26') }}</h2>
    </div>
    <div class="flex items-baseline justify-between">
      <p>{{ $t('views.sessions.text27') }}</p>
      <div style="display:flex">
        <div class="cursor w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 mr-5">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <TomSelect class="w-full cursor" v-model="imbalanceSearch">
              <option value="0">{{ $t('views.labAnalysis.desbalance.value') }}</option>
              <option value="1">{{ $t('views.labAnalysis.desbalance.value1') }}</option>
              <option value="2">{{ $t('views.labAnalysis.desbalance.value2') }}</option>
              <option value="3">{{ $t('views.labAnalysis.desbalance.value3') }}</option>
              <option value="4">{{ $t('views.labAnalysis.desbalance.value4') }}</option>
              <option value="5">{{ $t('views.labAnalysis.desbalance.value5') }}</option>
              <option value="6">{{ $t('views.labAnalysis.desbalance.value6') }}</option>

            </TomSelect>
          </div>
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13"
              :placeholder="$t('views.sessions.text28')" v-model="searchInput" />
            <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6 mt-5 mb-12">

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap"><span class="pointer" @click="sortUpdatedAt()">{{
        $t('views.sessions.text29') }}</span>
                <ArrowDownCircleIcon v-if="sort.updatedAt == 1" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
                <ArrowUpCircleIcon v-if="sort.updatedAt == 2" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
              </th>
              <th class="whitespace-nowrap"><span class="pointer" @click="sortSession_name()">{{
        $t('views.sessions.text30') }}</span>
                <ArrowDownCircleIcon v-if="sort.session_name == 1" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
                <ArrowUpCircleIcon v-if="sort.session_name == 2" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
              </th>
              <th class="whitespace-nowrap"><span class="pointer" @click="sortAge()">{{ $t('views.sessions.text31')
                  }}</span>
                <ArrowDownCircleIcon v-if="sort.age == 1" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
                <ArrowUpCircleIcon v-if="sort.age == 2" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
              </th>
              <th class="whitespace-nowrap"><span class="pointer" @click="sortGender()">{{ $t('views.sessions.text32')
                  }}</span>
                <ArrowDownCircleIcon v-if="sort.gender == 1" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
                <ArrowUpCircleIcon v-if="sort.gender == 2" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
              </th>
              <th class="whitespace-nowrap"><span class="pointer" @click="sortHeigth()">{{ $t('views.sessions.text33')
                  }}</span>
                <ArrowDownCircleIcon v-if="sort.height == 1" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
                <ArrowUpCircleIcon v-if="sort.height == 2" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
              </th>
              <th class="whitespace-nowrap"><span class="pointer" @click="sortweight()">{{ $t('views.sessions.text34')
                  }}</span>
                <ArrowDownCircleIcon v-if="sort.weight == 1" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
                <ArrowUpCircleIcon v-if="sort.weight == 2" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
              </th>
              <th class="whitespace-nowrap"><span class="pointer" @click="sortIMC()">{{ $t('views.sessions.text35')
                  }}</span>
                <ArrowDownCircleIcon v-if="sort.imc == 1" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
                <ArrowUpCircleIcon v-if="sort.imc == 2" class="w-4 h-4 my-auto inset-y-0 ml-2 right-0" />
              </th>
              <th colspan="2" class="text-center whitespace-nowrap">{{ $t('views.sessions.text36') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, itemKey) in sessions" :key="itemKey">
              <tr class="intro-x p-5">
                <td class="p-5">
                  {{ moment(item.updatedAt).format("DD/MM/YYYY HH:mm") }}
                </td>
                <td class="p-5">
                  {{ item.session_name || $t('views.sessions.text37') }}
                </td>
                <td class="p-5">
                  {{ `${item.age ? item.age + " " + $t('views.sessions.text38') : "-"}` }}
                </td>
                <td class="p-5">
                  {{ `${item.gender ? (item.gender == "FEMALE" ? $t('views.gender', 1) : $t('views.gender', 2)) : "-"}`
                  }}
                </td>
                <td class="p-5">
                  {{ `${(item.health_profile && item.health_profile.height && item.health_profile.height > 80) ?
        (Number(item.health_profile.height) / 100) + " m" : "-"}` }}
                </td>
                <td class="p-5">
                  {{ `${(item.health_profile && item.health_profile.weight) ? Number(item.health_profile.weight) + " kg"
        : "-"}` }}
                </td>
                <td class="p-5">
                  {{ `${item.health_profile && item.health_profile.imc && (item.health_profile.imc > 5 &&
        item.health_profile.imc < 80) ? item.health_profile.imc + " " + $t('views.sessions.text35') : "-"}`
                    }} </td>
                <td class="table-report__action w-56 relative" style="width:25px">
                  <div class="flex justify-center items-center">
                    <a class="flex items-center mr-3" href="javascript:;" @click="showRecomendationComparison(item)">
                      <ShuffleIcon class="w-4 h-4 mr-1" />
                      {{ $t('views.sessions.text39') }}
                    </a>
                  </div>
                </td>
                <td class="table-report__action w-56 relative" style="width:25px">
                  <div class="flex justify-center items-center">
                    <a class="flex items-center mr-3" href="javascript:;" @click="goToPdf(item)">
                      <FileTextIcon class="w-4 h-4 mr-1" />
                      {{ $t('views.sessions.text40') }}
                    </a>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-if="loading">
              <td colspan="8" class="p-4">
                {{ $t("views.loading") }}
                <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
              </td>
            </tr>
            <tr v-if="!loading && sessions.length === 0">
              <td colspan="8" class="p-4">
                {{ $t("views.no_data") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
        v-if="sessions.length > 0">
        <ul class="pagination" v-if="pagination.total_pages > 1">
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('prev')">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li v-if="pagination.total_pages >= getNumberPgae('first')">
            <a class="pagination__link"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('first') }"
              href="javascript:;" @click="goPage(getNumberPgae('first'))">{{ getNumberPgae("first") }}</a>
          </li>
          <li v-if="pagination.total_pages >= getNumberPgae('second')">....
            <a class="pagination__link"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('second') }"
              href="javascript:;" @click="goPage(getNumberPgae('second'))">{{ getNumberPgae("second") }}</a>
          </li>
          <li
            v-if="getNumberPgae('') && getNumberPgae('second') != getNumberPgae('') && pagination.total_pages >= getNumberPgae('')">
            <a class="pagination__link"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('') }" href="javascript:;"
              @click="goPage(getNumberPgae(''))">{{ getNumberPgae("") }}</a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('next')">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(pagination.total_pages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div class="ml-auto mr-5 text-gray-600">
          {{ $t('views.pagination.show') }} {{ sessions.length == 1 ? sessions.length + " " +
        $t('views.pagination.record', 1) :
        sessions.length + " " + $t('views.pagination.record', 2) }}
        </div>
        <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagination.per_page"
          @change="() => { changePerPage() }">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>

      <!-- <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
        v-if="sessions.length > 0"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('prev')">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" v-bind:class="{'pagination__link--active': pagination.page == getNumberPgae('first')}" href="javascript:;"  @click="goPage(getNumberPgae('first'))">{{ getNumberPgae("first") }}</a>
          </li>
          <li>
            <a class="pagination__link" v-bind:class="{'pagination__link--active': pagination.page == getNumberPgae('second')}" href="javascript:;"  @click="goPage(getNumberPgae('second'))">{{ getNumberPgae("second") }}</a>
          </li>
          <li v-if="getNumberPgae('') && getNumberPgae('second') < getNumberPgae('')">
            <a class="pagination__link" v-bind:class="{}" href="javascript:;"  @click="goPage(getNumberPgae(''))">{{ getNumberPgae("") }}</a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('next')">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(pagination.total_pages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>

        <div class="hidden md:block text-gray-600 mr-2">
          {{ $t('views.pagination.show') }} {{sessions.length == 1 ? sessions.length + " "+ $t('views.pagination.record', 1) : sessions.length+" " + $t('views.pagination.record', 2) }}
        </div>

        <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagination.per_page" @change="() => { changePerPage() }">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div> -->
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<style scoped>
.cursor,
.pointer {
  cursor: pointer !important;
}

.table td.p-0 {
  padding: 0px !important;
}
</style>

<style>
.tom-select .ts-dropdown .option.active {
  background: #6c869a !important;
}

.top-bar .breadcrumb>a:not(.breadcrumb--active) {
  color: #6c869a !important;
}

.detail-food {
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: initial;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pagination li,
.pagination a,
.pagination a:focus,
.pagination a:active {
  outline: none !important;
}
</style>

<script>
import SessionsService from "/src/services/session.service";
import lang_nutrition_contents from '../../utils/lang_nutrition_contents';
import moment from 'moment';
export default {
  setup() {
  },
  data() {
    return {
      sessions: [],
      searchInput: "",
      imbalanceSearch: 0,
      pagination: {
        page: 1,
        total_pages: 3,
        per_page: 50,
        total: 0
      },
      sort: {
        updatedAt: 0,
        session_name: 0,
        age: 0,
        gender: 0,
        height: 0,
        weight: 0,
        imc: 0
      },
      moment: moment,
      loading: true
    }
  },
  watch: {
    searchInput: function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getSessionsItems();
    },
    imbalanceSearch: function (newSearch, oldSearch) {
      this.getSessionsItems();
    },
    '$i18n.locale': function (l) {
      this.getSessionsItems();
    }

  },
  created() {
    window.vue = this
    this.getSessionsItems();
  },
  methods: {
    sortUpdatedAt() {
      this.sort["updatedAt"] = this.sort["updatedAt"] + 1
      this.sort["updatedAt"] = this.sort["updatedAt"] % 3
      this.sort["session_name"] = 0
      this.sort["age"] = 0
      this.sort["gender"] = 0
      this.sort["height"] = 0
      this.sort["weight"] = 0
      this.sort["imc"] = 0
      this.getSessionsItems();
    },
    sortSession_name() {
      this.sort["session_name"] = this.sort["session_name"] + 1
      this.sort["session_name"] = this.sort["session_name"] % 3
      this.sort["updatedAt"] = 0
      this.sort["age"] = 0
      this.sort["gender"] = 0
      this.sort["height"] = 0
      this.sort["weight"] = 0
      this.sort["imc"] = 0
      this.getSessionsItems();
    },
    sortAge() {
      this.sort["age"] = this.sort["age"] + 1
      this.sort["age"] = this.sort["age"] % 3
      this.sort["updatedAt"] = 0
      this.sort["session_name"] = 0
      this.sort["gender"] = 0
      this.sort["height"] = 0
      this.sort["weight"] = 0
      this.sort["imc"] = 0
      this.getSessionsItems();
    },
    sortGender() {
      this.sort["gender"] = this.sort["gender"] + 1
      this.sort["gender"] = this.sort["gender"] % 3
      this.sort["updatedAt"] = 0
      this.sort["session_name"] = 0
      this.sort["age"] = 0
      this.sort["height"] = 0
      this.sort["weight"] = 0
      this.sort["imc"] = 0
      this.getSessionsItems();
    },
    sortHeigth() {
      this.sort["height"] = this.sort["height"] + 1
      this.sort["height"] = this.sort["height"] % 3
      this.sort["updatedAt"] = 0
      this.sort["session_name"] = 0
      this.sort["age"] = 0
      this.sort["gender"] = 0
      this.sort["weight"] = 0
      this.sort["imc"] = 0
      this.getSessionsItems();
    },
    sortweight() {
      this.sort["weight"] = this.sort["weight"] + 1
      this.sort["weight"] = this.sort["weight"] % 3
      this.sort["updatedAt"] = 0
      this.sort["session_name"] = 0
      this.sort["age"] = 0
      this.sort["gender"] = 0
      this.sort["height"] = 0
      this.sort["imc"] = 0
      this.getSessionsItems();
    },
    sortIMC() {
      this.sort["imc"] = this.sort["imc"] + 1
      this.sort["imc"] = this.sort["imc"] % 3
      this.sort["updatedAt"] = 0
      this.sort["session_name"] = 0
      this.sort["age"] = 0
      this.sort["gender"] = 0
      this.sort["height"] = 0
      this.sort["weight"] = 0
      this.getSessionsItems();
    },
    printArray(input) {
      var str = input.toString().replaceAll(",", ", ")
      var lIndex = str.lastIndexOf(",");
      if (input.length > 1) {
        str = str.substring(0, lIndex) + " y" + str.substr(lIndex + 1);
      }
      return str
    },
    getSessionsItems() {
      var params = {
        page: this.pagination.page - 1,
        limit: this.pagination.per_page,
        offset: (this.pagination.page - 1) * this.pagination.per_page,
        session_name: this.searchInput,
        sort: this.sort,
        imbalance: this.imbalanceSearch > 0 ? this.imbalanceSearch : null

      }
      var _this = this
      SessionsService.getAll(params).then((response) => {
        this.loading = false
        if (response.search && response.search != this.searchInput) {
          return;
        }

        this.sessions = response.rows
        this.pagination.total_pages = Math.ceil(response.count / this.pagination.per_page);
        this.pagination.total = response.count

        this.sessions.map(function (f) {
          return f.show = false
        })
      }).catch(function (e) {
        _this.loading = false
      });
    },
    showRecomendationComparison(session) {
      localStorage.setItem('session', JSON.stringify(session));
      this.$router.push({ path: '/recommendation-comparison' });
    },
    formatName(text) {
      return (lang_nutrition_contents[text]) ? lang_nutrition_contents[text].name : text
    },
    formatUnit(text) {
      return (lang_nutrition_contents[text]) ? lang_nutrition_contents[text].unit : ""
    },
    goPage: function (param) {
      if (param == 'prev') {
        this.pagination.page = (this.pagination.page > 1) ? this.pagination.page - 1 : 1
      } else if (param == 'next') {
        this.pagination.page = (this.pagination.page < this.pagination.total_pages) ? this.pagination.page + 1 : this.pagination.total_pages
      } else {
        this.pagination.page = param
      }

      this.getSessionsItems();
    },
    getNumberPgae(type) {
      if (type == "first") {
        return (this.pagination.page < 3) ? 1 : this.pagination.page - 1
      } else if (type == "second") {
        return (this.pagination.page > 2) ? this.pagination.page : 2
      } else {
        return (this.pagination.page < this.pagination.total_pages) ? this.pagination.page : null
      }
    },
    changePerPage() {
      this.pagination.page = 1
      this.pagination.per_page = Number(this.pagination.per_page)
      this.getSessionsItems()
    },
    getCategory(item) {
      return (item.category && item.category.name) ? item.category.name : ""
    },
    goToPdf(item) {
      localStorage.setItem('userItem', JSON.stringify(item));
      this.$router.push({ path: '/user-results' });
    },
  }
}
</script>
