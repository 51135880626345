import ApiService from "./api.service"

/**
 * LabService es un objeto que tiene las funciones de lab
 *
 * getUserLabResult --> función que a través de una petición ._GET en un enterpoint, solicita los resultados de laboratorio de un usuario
 * retorna los resultados de lab
 *
 * getLabItems --> función que a través de una petición ._GET en un enterpoint,solicita los elementos de los resultados de laboratorio
 * retorna los elementos
 *
 * saveLabItem --> función que a través de una petición POST en un enterpoint, solicita crear o actualizar los valores de lab
 * retorna los datos de la respuesta
 *
 * getRecomendationsByLabItem --> función que a través de una peticion ._GET en un enterpoint, solicita las recomendaciones basadas en el valor de laboratorio
 * retorna las recomendaciones
 */

const LabService = {
  getUserLabResult: async function(params) {
    const response = await ApiService._get(
      "/api/quiz/get-user-lab-analysis-result-per-quiz",
      params
    )
    return response.data
  },

  getLabItems: async function(params) {
    const response = await ApiService._get("/api/admin/lab", params)
    return response.data
  },
  saveLabItem: async function(params) {
    const response = await ApiService.post(
      "/api/admin/lab/save-lab-item",
      params
    )
    return response.data
  },
  saveLabItemLevel: async function(params) {
    const response = await ApiService.post(
      "/api/admin/lab/save-lab-item-level",
      params
    )
    return response.data
  },
  getRecomendationsByLabItem: async function(params) {
    const response = await ApiService._get(
      "/api/lab-analysis/display-recommendations",
      params
    )
    return response.data
  }
}

export default LabService
