import ApiService from "./api.service"

import axios from "axios"

/**
 * ReceipeService es un objeto que tiene todas las funciones de recetas
 *
 * getAll --> funcion que atraves de una peticion ._GET la cual atraves de un enterpoint,solicita el listado de recetas
 * retorna las recetas
 *
 * get -->funcion que atraves de una peticion GET la cual atraves de un enterpoint,solicita una receta a traavez de su id
 * retorna la receta
 *
 * save--> funcion que atraves de una peticion POST la cual atraves de un enterpoint, solicita crear o actulizar una receta
 * retorna la receta
 *
 * unload --> funcion que atraves de una peticion POST la cual atraves de un enterpoint, para subir una imagen a la receta
 * retorna los datos de la respuesta
 *
 * getUnits --> funcion que atraves de una peticion GET la cual atraves de un enterpoint, solicita las unicades de la receta
 * retorna las unidades.
 *
 * remove --> funcion que atraves de una peticion DELETE la cual atraves de un enterpoint, solicita eliminar una receta con un id especifico
 * retorna un mensaje de que se elimino
 *
 * getAllBenefits --> funcion que atravez de una peticion ._GET al enterpoit, solicita los beneficios relacionados con la receta,
 * retorna la respuesta del servidor
 *
 * getIg --> funcion que atravez de una peticion POST al enterpoit, solicita el indice glucemico de la receta y pasas los params para poder carcularlo
 * retorna el indice
 *
 * deleteGalleryPhoto --> funcion que atraves de una peticion ._GET la cual atraves de un enterpoint, solicita eliminar una foto especifica por el id pasado
 * retorna
 * */

const ReceipeService = {
  getAll: async function(params) {
    const response = await ApiService._get("/api/admin/food_recipe", params)
    return response.data
  },

  get: async function(id, lang = null) {
    const response = await ApiService.get("/api/admin/food_recipe/" + id, lang)
    return response.data
  },

  save: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/food_recipe/save",
      params,
      lang
    )
    return response.data
  },

  upload: async function(params) {
    const response = await ApiService.post(
      "/api/admin/food_receipe/upload-gallery",
      params
    )
    return response.data
  },

  getUnits: async function(lang = null) {
    const response = await ApiService.get("/api/admin/food_recipe/units", lang)
    return response.data
  },

  remove: async function(id) {
    const response = await ApiService.delete(
      "/api/admin/food_recipe/remove/" + id
    )
    return response.data
  },

  getAllBenefits: async function(params) {
    const response = await ApiService._get(
      "/api/admin/food_recipe/benefits/get-all",
      params
    )
    return response.data
  },

  getIG: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/food_recipe/calculate-recipe-ig-group",
      params,
      lang
    )
    return response.data
  },

  deleteGalleryPhoto: async function(photoId) {
    const response = await ApiService._get(
      "/api/admin/food_recipe/delete-gallery-photo",
      { id: photoId }
    )
    return response.data
  }
}

export default ReceipeService
