a<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h2 class="intro-y text-lg font-medium mt-10">{{ $t('views.glossary.text1') }}</h2>
      <button type="button" class="btn btn-primary w-24 h-10" @click="newGlossaryItem"><span>{{ $t('views.new')
          }}</span></button>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-12">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <p class="mr-auto">{{ $t('views.glossary.text2') }}</p>
        <div class="cursor w-full sm:w-56 mt-3 sm:mt-0 sm:ml-auto md:ml-0 mr-5">
          <!-- seleccion categorias -->
          <div class="w-full relative text-gray-700 dark:text-gray-300">
            <TomSelect class="w-full cursor" v-model="category">
              <option value="0">{{ $t('views.supplements.text3') }}</option>
              <option v-for="(cat, key) in categories" :key="key" :value="cat._id">{{ cat.name }}</option>
            </TomSelect>
          </div>
        </div>
        <div class="cursor w-full sm:w-56 mt-3 sm:mt-0 sm:ml-auto md:ml-0 mr-5">
          <!-- seleccion  estado-->
          <div class="w-full relative text-gray-700 dark:text-gray-300">
            <TomSelect class="w-full cursor" v-model="statusFilter">
              <option value="0">{{ $t('views.glossary.text16') }}</option>
              <!-- Texto para mostrar 'Todos' los estados -->
              <option value="1">{{ $t('views.glossary.UNREVIEWED') }}</option>
              <option value="2">{{ $t('views.glossary.REVIEWED') }}</option>
            </TomSelect>
          </div>
        </div>
        <!-- buscador -->
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13"
              :placeholder="$t('views.glossary.text4')" v-model="searchInput" />
            <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">
                <div class="grid supplements-grid">
                  <div>{{ $t('views.glossary.text5') }}</div>
                  <div>{{ $t('views.glossary.text6') }}</div>
                  <div>{{ $t('views.glossary.text7') }}</div>
                  <div class="ml-6">{{ $t('views.glossary.text9') }}</div>
                </div>
              </th>
              <th class="text-center whitespace-nowrap">{{ $t('views.glossary.text8') }}</th>
            </tr>
          </thead>
          <tbody class="">
            <template v-for="(item, itemKey) in terms" :key="itemKey">
              <tr class="intro-x">
                <td>
                  <div class="grid diseases-grid cursor-pointer" @click="item.show = !item.show">
                    <div>{{ item.name }}</div>
                    <div
                      class="py-1 px-2 rounded-md text-xs text-white cursor-pointer font-medium my-1 w-max tag-category bg-custom-gray-4 ">
                      {{ item.category_id?.name }}</div>
                    <div v-if="!item.show" class="flex flex-wrap">
                      <div class="truncate pr-2">{{ item.description }}</div>
                    </div>
                    <div class="alert ml-6 show py-1 px-4 w-max h-max-content text-white "
                      :class="{ 'green-ok': item.status === 2, 'red-danger': item.status === 1 }">
                      {{ $t(`views.glossary.${getNameStatus(item.status)}`) }}</div>
                  </div>
                  <div class="w-full intro-x mt-5" v-if="item.show">
                    <h2 v-if="item.description" class="dark:text-white font-medium">{{ $t('views.glossary.text7') }}:
                    </h2>
                    <div class="mt-2">{{ item.description }}</div>
                  </div>
                  <div class="w-full intro-x mt-5" v-if="item.show">
                    <h2 v-if="item.synonyms.length > 0" class="dark:text-white font-medium">{{
                      $t('views.glossary.text13') }}:
                    </h2>
                    <div class="mt-2">{{ item.synonyms.join(" , ") }}</div>
                  </div>
                  <div class="w-full intro-x mt-5" v-if="item.show">
                    <h2 v-if="item.antonyms.length > 0" class="dark:text-white font-medium">{{
                      $t('views.glossary.text15') }}:
                    </h2>
                    <div class="mt-2">{{ item.antonyms.join(" , ") }}</div>
                  </div>
                </td>
                <td class="table-report__action w-56 relative p-5" style="width:25px">
                  <div class="flex justify-center items-center">
                    <a class="flex items-center mr-3" href="javascript:;" @click="editGlossaryItem(item)">
                      <CheckSquareIcon class="w-4 h-4 mr-1" />
                      {{ $t('views.edit') }}
                    </a>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-if="loading">
              <td colspan="4" class="p-4">
                {{ $t("views.loading") }}
                <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
              </td>
            </tr>
            <tr v-if="!loading && terms.length === 0">
              <td colspan="4" class="p-4">
                {{ $t("views.no_data") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center" v-if="terms.length > 0">
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('prev')">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('first')"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('first') }"
              href="javascript:;" @click="goPage(getNumberPgae('first'))">{{ getNumberPgae("first") }}</a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('second')"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('second') }"
              href="javascript:;" @click="goPage(getNumberPgae('second'))">{{ getNumberPgae("second") }}</a>
          </li>
          <li v-if="getNumberPgae('second') != getNumberPgae('') && pagination.total_pages >= getNumberPgae('')">
            <a class="pagination__link"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('') }" href="javascript:;"
              @click="goPage(getNumberPgae(''))">{{ getNumberPgae("") }}</a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('next')">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(pagination.total_pages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div class="ml-auto mr-5 text-gray-600">
          {{ $t('views.pagination.items', { total: terms.length }) }}
        </div>
        <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagination.per_page"
          @change="() => { changePerPage() }">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<style scoped>
.cursor {
  cursor: pointer !important;
}

.table td.p-0 {
  padding: 0px !important;
}

.bg-color-1 {
  background-color: #828181;
}

.btn-primary {
  background: #6c869a !important;
  border-color: #6c869a !important;
}
</style>

<style>
.tom-select .ts-dropdown .option.active {
  background: #6c869a !important;
}

.top-bar .breadcrumb>a:not(.breadcrumb--active) {
  color: #6c869a !important;
}

.detail-food {
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: initial;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pagination li,
.pagination a,
.pagination a:focus,
.pagination a:active {
  outline: none !important;
}

.supplements-grid {
  grid-template-columns: 150px 200px 150px 150px 150px;
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 1450px) {
  .supplements-grid {
    grid-template-columns: 12vw auto 15vw 12vw 12vw;
  }
}
</style>

<script>
import GlossaryService from "/src/services/glossary.service"
import _ from 'lodash'

export default {
  setup() {
  },
  data() {
    return {
      terms: [],
      searchInput: "",
      quizToSearch: 0,
      pagination: {
        page: 1,
        total_pages: 3,
        per_page: 50,
        total: 0
      },
      categories: [],
      category: 0,
      loading: true,
      statusFilter: '',
      status: this.$global.getStatus()
    }
  },
  watch: {
    searchInput: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getAllTerms()
    }, 500),
    category: function () {
      this.pagination.page = 1
      this.getAllTerms()
    },
    statusFilter: function () { // Agregamos el watch para el estado
      this.pagination.page = 1
      this.getAllTerms()
    },
    '$i18n.locale': function (l) {
      this.getAllCategories()
    }
  },
  computed: {
  },
  created() {
    window.vue = this
    this.getAllCategories()
    this.getAllTerms()
  },
  methods: {
    getNameStatus(codeStatus) {
      switch (codeStatus) {
        case 1: return "UNREVIEWED"
        default:
          return "REVIEWED"
      }
    },
    getAllTerms() {
      this.loading = true
      var params = {
        page: this.pagination.page,
        limit: this.pagination.per_page,
        search: this.searchInput || '',
        category_id: this.category !== 0 ? this.category : '',
        status: this.statusFilter !== '' ? this.statusFilter : '',
        language: this.$i18n.locale
      }

      if (this.searchInput) {
        params.search = this.searchInput.trim().replaceAll(" ", "_")
      }

      GlossaryService.getAllTerms(params)
        .then((response) => {
          this.loading = false
          if (response.terms) {
            this.terms = response.terms.map((term) => {
              return {
                ...term,
                name: term.name || "Sin nombre",
                description: term.description || "Sin descripción",
                categories: term.categories || [],
                show: false
              }
            })
            this.pagination.total_pages = response.totalPages
            this.pagination.total = response.totalPages
          }
        })
        .catch((error) => {
          console.error("Error al obtener los términos:", error)
          this.loading = false
        })
    },

    getAllCategories() {
      GlossaryService.getAllCategories(this.$i18n.locale).then((response) => {
        this.categories = response.categories || []
      })
    },

    editGlossaryItem(glossaryItem) {
      localStorage.setItem('glossaryItem', JSON.stringify(glossaryItem))
      this.$router.push({ path: '/glossary-edit' })
    },

    newGlossaryItem() {
      localStorage.removeItem('glossaryItem')
      this.$router.push({ path: '/glossary-new' })
    },

    goPage(param) {
      if (param === 'prev') {
        this.pagination.page = (this.pagination.page > 1) ? this.pagination.page - 1 : 1
      } else if (param === 'next') {
        this.pagination.page = (this.pagination.page < this.pagination.total_pages) ? this.pagination.page + 1 : this.pagination.total_pages
      } else {
        this.pagination.page = param
      }
      this.getAllTerms()
    },

    getNumberPgae(type) {
      if (type === "first") {
        return (this.pagination.page < 3) ? 1 : this.pagination.page - 1
      } else if (type === "second") {
        return (this.pagination.page > 2) ? this.pagination.page : 2
      } else {
        return (this.pagination.page > 2) ? (this.pagination.page + 1 > this.pagination.total_pages) ? this.pagination.total_pages : this.pagination.page + 1 : 3
      }
    },

    changePerPage() {
      this.pagination.page = 1
      this.pagination.per_page = Number(this.pagination.per_page)
      this.getAllTerms()
    },

    getCat(catId) {
      const category = this.categories.find(cat => cat.id === catId)
      return category ? category.name : 'Categoría no encontrada'
    }
  }
}

</script>
