import ApiService from "./api.service"

/**
 * SupplementService es un objeto que almacena las funciones
 *
 * getAll --> función que realiza una petición ._GET al enterpoint, la cual le solicita el listado de suplementos
 * retorna el listado de suplementos
 *
 * getSuplement --> función que realiza una petición GET al enterpoint,la cual le solicita un suplemento en específico
 * retorna el suplemento
 *
 * getCategories --> función que realiza una petición GET al enterpoint,la cual le solicita el listado de categorias de los suplemento
 * retorna las categorías
 *
 * getBrands --> función que realiza una petición GET al enterpoint,la cual le solicita el listado de marcas de suplementos
 * retorna las marcas
 *
 * save --> función que realiza una petición POST al enterpoint,la cual le solicita crear o actualizar un nuevo sumplentos
 * retorna mensaje de exito o error
 *
 * saveCategory --> función que realiza una petición POST al enterpoint,la cual le solicita crear o actualizar una categoría de suplementos
 * retorna mensaje de exito o error
 *
 * getInteractionSupplement --> función que realiza una petición ._GET al enterpoint,la cual le solicita la interacción entre los suplementos
 * retorna la interacción
 *
 * saveInteractionSupplement --> función que realiza una petición POST al enterpoint,la cual le solicita crear o actualizar la interacción  entre los suplementos
 * retorna mensaje de éxito o error
 *
 *deleteInteractionSupplement --> función que realiza una petición POST al enterpoint,la cual le solicita eliminar la interacción  entre los suplementos
 * retorna mensaje de éxito o error
 *
 */

const SupplemetService = {
  getAll: async function(params) {
    const response = await ApiService._get("/api/admin/suplement", params)
    return response.data
  },

  getSupplement: async function(id, lang = null) {
    const response = await ApiService.get("/api/admin/suplement/" + id, lang)
    return response.data
  },
  getCategories: async function(lang = null) {
    const response = await ApiService.get(
      "/api/admin/suplement/categories",
      lang
    )
    return response.data
  },

  getBrands: async function(lang = null) {
    const response = await ApiService.get("/api/admin/suplement/brands", lang)
    return response.data
  },

  save: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/suplement/supplement",
      params,
      lang
    )
    return response.data
  },

  saveCategory: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/suplement/create_category",
      params,
      lang
    )
    return response.data
  },

  getInteractionSupplement: async function(params, lang = null) {
    const response = await ApiService._get(
      "/api/admin/suplement/get-supplement-interaction",
      params,
      lang
    )
    return response.data
  },

  saveInteractionSupplement: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/suplement/save-supplement-interaction",
      params,
      lang
    )
    return response.data
  },

  deleteInteractionSupplement: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/suplement/delete-supplement-interaction",
      params,
      lang
    )
    return response.data
  }
}

export default SupplemetService
