const lang_nutrition_contents = {
  energy_value: {
    name: "Energía",
    unit: "cal"
  },
  energy_unit: {
    name: "Unidad de energía",
    unit: ""
  },
  energy_value_kj: {
    name: "Energía Kj",
    unit: "kj"
  },
  monounsaturated_fat: {
    name: "Grasas monoinsaturadas",
    unit: "gr"
  },
  polyunsaturated_fat: {
    name: "Grasas poliinsaturadas",
    unit: "gr"
  },
  trans_fat: {
    name: "Grasas trans",
    unit: "gr"
  },
  saturated_fat: {
    name: "Grasas saturadas",
    unit: "gr"
  },
  alcohol: {
    name: "Alcohol",
    unit: "gr"
  },
  sugar_alcohols: {
    name: "Alcoholes de azúcar",
    unit: "gr"
  },
  starch: {
    name: "Almidón, total",
    unit: "gr"
  },
  sugar: {
    name: "Azúcares totales",
    unit: "gr"
  },
  calcium: {
    name: "Calcio",
    unit: "mg"
  },
  carbohydrates: {
    name: "Carbohidratos",
    unit: "gr"
  },
  available_carbohydrates: {
    name: "Carbohidratos disponibles",
    unit: "gr"
  },
  total_carotenoids: {
    name: "Carotenoides totales",
    unit: "ug"
  },
  cholesterol: {
    name: "Colesterol (gc)",
    unit: "mg"
  },
  calculated_energy: {
    name: "Energía calculada",
    unit: "kj"
  },
  equivalents_of_retinol_activity_of_vitamin_a: {
    name: "Equivalente del retinol de la vitamina a",
    unit: "ug"
  },
  niacin_equivalents: {
    name: "Equivalentes de niacina, total",
    unit: "mg"
  },
  sterols: {
    name: "Esteroles, total",
    unit: "mg"
  },
  fiber: {
    name: "Fibra total",
    unit: "gr"
  },
  fiber_insoluble_in_water: {
    name: "Fibra, insoluble en agua",
    unit: "gr"
  },
  folate: {
    name: "Folato, total",
    unit: "ug"
  },
  fructose: {
    name: "Fructosa",
    unit: "gr"
  },
  phosphor: {
    name: "Fósforo",
    unit: "mg"
  },
  galactose: {
    name: "Galactosa",
    unit: "gr"
  },
  glucose: {
    name: "Glucosa",
    unit: "gr"
  },
  fat: {
    name: "Grasa total",
    unit: "gr"
  },
  iron: {
    name: "Hierro total",
    unit: "mg"
  },
  lactose: {
    name: "Lactosa",
    unit: "gr"
  },
  magnesium: {
    name: "Magnesio",
    unit: "mg"
  },
  maltose: {
    name: "Maltosa",
    unit: "gr"
  },
  niacin_preformed_nicotinic_acid_nicotinamide: {
    name: "Niacina, preformada (ácido nicotínico + nicotinamida)",
    unit: "mg"
  },
  polysaccharides_non_cellulosic_soluble_in_water: {
    name: "Polisacáridos, no celulósicos, solubles en agua",
    unit: "gr"
  },
  potassium: {
    name: "Potasio",
    unit: "mg"
  },
  protein: {
    name: "Proteína total",
    unit: "gr"
  },
  riboflavin: {
    name: "Riboflavina",
    unit: "mg"
  },
  sucrose: {
    name: "Sacarosa",
    unit: "gr"
  },
  sodium: {
    name: "Sal",
    unit: "mg"
  },
  selenium: {
    name: "Selenio total",
    unit: "ug"
  },
  sodio: {
    name: "Sodio",
    unit: "mg"
  },
  thiamine_vitamin_b1: {
    name: "Tiamina (vitamina b1)",
    unit: "mg"
  },
  tryptophan: {
    name: "Triptófano",
    unit: "mg"
  },
  vitamin_b12_cobalamin: {
    name: "Vitamina b-12 (cobalamina)",
    unit: "ug"
  },
  vitamin_c_ascorbic_acid: {
    name: "Vitamina c (ácido ascórbico)",
    unit: "mg"
  },
  vitamin_d: {
    name: "Vitamina d",
    unit: "ug"
  },
  vitamin_e_alphatocopferol: {
    name: "Vitamina e alfatocopferol",
    unit: "mg"
  },
  vitamin_k_total: {
    name: "Vitamina k, total",
    unit: "ug"
  },
  vitamin_pyridoxine_hydrochloride: {
    name: "Vitamina piridoxina (clorhidrato)",
    unit: "mg"
  },
  vitamin_a: {
    name: "Vitamina a",
    unit: "%"
  },
  vitamin_c: {
    name: "Vitamina c",
    unit: "%"
  },
  iodine: {
    name: "Yoduro (yodo)",
    unit: "ug"
  },
  zinc: {
    name: "Zinc",
    unit: "mg"
  },
  omega_3: {
    name: "Omega 3",
    unit: "%"
  },
  omega_6: {
    name: "Omega 6",
    unit: "%"
  },
  total_saturated_fatty_acids: {
    name: "Ácidos grasos saturados totales",
    unit: "gr"
  },
  total_fatty_acids: {
    name: "Ácidos grasos totales",
    unit: "gr"
  },
  fatty_acids_monounsaturated_cis: {
    name: "Ácidos grasos, monoinsaturados totales cis",
    unit: "gr"
  },
  fatty_acids_polyunsaturated_n3: {
    name: "Ácidos grasos, poliinsaturados n-3 totales",
    unit: "gr"
  },
  fatty_acids_polyunsaturated_n6: {
    name: "Ácidos grasos, poliinsaturados n-6 totales",
    unit: "gr"
  },
  fatty_acids_total_polyunsaturated: {
    name: "Ácidos grasos, poliinsaturados totales",
    unit: "gr"
  },
  fatty_acids_trans: {
    name: "Ácidos grasos, trans",
    unit: "gr"
  },
  total_organic_acids: {
    name: "Ácidos orgánicos totales",
    unit: "gr"
  },
  colina: {
    name: "Colina",
    unit: "mg"
  },
  tirosina: {
    name: "Tirosina",
    unit: "mg"
  },
  copper: {
    name: "Cobre",
    unit: "mg"
  },
  manganese: {
    name: "Manganeso",
    unit: "mg"
  },
  chlorine: {
    name: "Cloro",
    unit: "gr"
  },
  chromium: {
    name: "Cromo",
    unit: "mg"
  },
  fluorine: {
    name: "Fluor",
    unit: "ug"
  },
  leucine: {
    name: "Leucina",
    unit: "mg"
  },
  isoleucine: {
    name: "Isoleucina",
    unit: "mg"
  },
  valine: {
    name: "Valina",
    unit: "mg"
  },
  threonine: {
    name: "Treonina",
    unit: "mg"
  },
  arginine: {
    name: "Arginina",
    unit: "mg"
  },
  histidine: {
    name: "Histidina",
    unit: "mg"
  },
  ornithine: {
    name: "Ornitina",
    unit: "mg"
  },
  methionine: {
    name: "Metionina",
    unit: "mg"
  },
  vitamin_b5: {
    name: "Vitamina B5 (Ácido pantoténico)",
    unit: "mg"
  },
  biotin: {
    name: "Biotina",
    unit: "ug"
  },
  soluble_fiber: {
    name: "Fibra soluble",
    unit: "gr"
  },
  unsaturated_fat: {
    name: "Grasas insaturadas",
    unit: "mg"
  },
  omega_9: {
    name: "Omega 9",
    unit: "mg"
  },
  oxalate: {
    name: "Oxalato",
    unit: "mg"
  }
}
export { lang_nutrition_contents as default }
