const TOKEN_KEY = "token"
const IS_ADMIN = "is_admin"

/**
 *TokenServices es un objeto que almacena todas las funciones relacionadas con el almacenamiento local
 *
 * getToken --> función que retorna el token de acceso almacenado en el localStore
 *
 * saveToken --> función que guarda el token de acceso almacenado en el local y lo guarda bajo una clave específica,
 * esto permite que el token este disponible para su uso posterior
 *
 * removeToken --> función que elimina el token de acceso almacenado en el localStore
 *
 * setIsAdmin --> función que  establece un indicador de que el usuario es administrador en el local storage. Si el indicador está en true, tiene los permisos
 *
 * isAdmin --> función que  establece un indicador en true por lo que es administrador y puede acceder a los permisos
 *
 * isVerify --> función que comprueba si pasaron en un periodo de 2 horas desde la ultima verificación de seguridad
 *
 * isLocal --> función que  verificar si existe una variable llamada "local"  en el localStorage
 * */

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
  },

  setIsAdmin() {
    localStorage.setItem(IS_ADMIN, true)
  },

  isAdmin() {
    return localStorage.getItem(IS_ADMIN)
  },

  isVerify() {
    var lastSecurityCheckpoint = localStorage.getItem("lastSecurityCheckpoint")
    return (
      lastSecurityCheckpoint &&
      Number(lastSecurityCheckpoint) > new Date().getTime() - 1000 * 7200
    )
  },

  isLocal() {
    return localStorage.getItem("local")
  }
}

/**
 * UserInfo --> es un objeto que tiene la función save() la cual guarda la información del usuario
 * y get() que te trae la información del usuario y te la pasa a cadena de texto
 *  */

const UserInfo = {
  save(info) {
    localStorage.setItem("user", user)
  },

  get() {
    return JSON.parse(localStorage.getItem("INFO"))
  }
}

/**
 * Recipes es un objeto de funciones para gestionar el estado de almacenamiento de las recetas
 *
 * setIsSaving --> función que establece si se guardo o no una receta en el localStorage
 *
 * isSaving --> función que obtiene el estado actual de guardado de la receta
 *
 * removeIsSaving --> elimina el estado de la receta
*/

const Recipes = {
  setIsSaving(value) {
    localStorage.setItem("recipeIsSaving", value)
  },

  isSaving() {
    return localStorage.getItem("recipeIsSaving")
  },

  removeIsSaving() {
    return localStorage.removeItem("recipeIsSaving")
  }
}

export { TokenService, UserInfo, Recipes }
