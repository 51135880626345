<template>
  <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium mr-10">
      {{ $t("views.food.text18") }}
    </h2>
    <div class="w-full sm:w-auto mt-3 sm:mt-0 mr-8 ">
      <div class="w-56 relative text-gray-700 dark:text-gray-300">
        <input type="text" class="form-control w-56 pr-10 placeholder-theme-13" :placeholder="$t('views.food.text19')"
          @keyup="searchNutritionItem" />
        <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
      </div>
    </div>

    <button type="button" :disabled="isReadOnly"
      class="alert alert-secondary show mb-2 cursor-pointer font-bold ml-auto" role="alert" @click="setServing">{{
        `${$t('views.food.text8')} ${food.serving_quantity ? food.serving_quantity : '100'} ${food.serving_unit ?
          food.serving_unit : 'gr'} ` }}</button>
    <button type="button" :disabled="isReadOnly"
      :class="(food.nutritional_content.energy_value ? 'alert-secondary' : 'bg-theme-32') + (errorEnergy ? ' border border-theme-6 ' : '')"
      class="alert show mb-2 cursor-pointer font-bold ml-4" role="alert" @click="setEnergy">{{ `Energia
      ${food.nutritional_content.energy_value ? food.nutritional_content.energy_value + " kcal" : ''} ` }}</button>

  </div>
  <div class="sm:grid grid-cols-3 gap-3 gap-x-6 mt-3 intro-y" v-show="!type">
    <template v-for="(n, nKey) in show_nutritional_content">
      <div class="overflow-hidden" :key="nKey" v-if="!hiddenKey.includes(nKey)" :style="'order:' + getOrder(nKey)">
        <div class="form-label max-w-label truncate">
          <Tippy data-theme="light" :content="formatName(nKey)">{{ formatName(nKey) }}</Tippy>
        </div>
        <div class="flex gap-3">
          <div v-if="food.nutritional_content" class="input-group w-full">
            <input :type="(nKey == 'energy_unit') ? 'text' : 'number'"
              :class="(nKey == 'energy_value') ? 'bg-theme-32' : ''" :readonly="isReadOnly" name="chart-tooltip"
              class="form-control w-full focus-none" placeholder="" aria-describedby="input-group-unit"
              :id="'input-nutrional-content-' + nKey" v-model.lazy="food.nutritional_content[nKey]" />
            <div id="input-group-unit" class="input-group-text">{{ formatUnit(nKey) }}</div>
          </div>
          <div v-if="food.nutritional_content_by_percent && !nonPercent.includes(nKey)" class="input-group w-full">
            <input :type="(nKey == 'energy_unit') ? 'text' : 'number'"
              :class="(nKey == 'energy_value') ? 'bg-theme-32' : ''" :readonly="isReadOnly" name="chart-tooltip"
              class="form-control w-full focus-none" placeholder="" aria-describedby="input-group-unit_by-percent"
              v-model.lazy="food.nutritional_content_by_percent[nKey]" />
            <div id="input-group-unit_by-percent" class="input-group-text">%</div>
          </div>
          <div v-if="nonPercent.includes(nKey)" class="input-group w-full"></div>
        </div>
      </div>
    </template>
  </div>
</template>
<style>
.form-switch .form-check-input[type=checkbox] {
  background-image: none;
  border-radius: 9999px;
  height: 24px;
  padding: 1px;
  position: relative;
  width: 38px;
  outline: none;
}

.form-switch .form-check-input[type=checkbox]:checked {
  background-color: #6c869a;
  border-color: #6c869a;
}

.dark .form-switch .form-check-input[type=checkbox]:checked {
  background-color: #3f4965;
  border-color: #3f4965;
}

.form-switch .form-check-input[type=checkbox]:before {
  border-radius: 9999px;
  bottom: 0;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 25%);
  content: "";
  height: 20px;
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  top: 0;
  transition-duration: .2s;
  transition-property: margin-left;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  width: 20px;
  background-color: white;
  background-image: none;
  opacity: 1;
}

.form-switch .form-check-input[type=checkbox]:checked:before {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
  margin-left: 14px;
}

.form-control.focus-none:focus {
  box-shadow: none;
}
</style>
<script>
import { indexOf } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {

  },
  props: ['food', 'setType', 'type', 'setServing', 'isReadOnly', 'setEnergy', 'errorEnergy'],
  data() {
    return {
      nutritional_content: this.$global.getNutritionalContents(),
      show_nutritional_content: this.$global.getNutritionalContents(),
      nonPercent: ['oxalate', 'purine'],
      hiddenKey: ['id', 'id_api', 'food_id', 'updatedAt', 'createdAt', 'deletedAt', 'vitamin_c', 'calculated_energy', 'energy_value', 'niacin_preformed_nicotinic_acid_nicotinamide', 'energy_value_kj', 'energy_unit', 'fatty_acids_monounsaturated_cis', 'fatty_acids_total_polyunsaturated', 'fatty_acids_trans', 'total_fatty_acids', 'total_saturated_fatty_acids'],
    }
  },
  mounted() {
    var sort = Object.keys(this.show_nutritional_content)
      .sort((a, b) => {
        var aa = this.$t('views.lang_nutrition_contents.' + a) ? this.$t('views.lang_nutrition_contents.' + a + '.name') : a
        aa = aa.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        var bb = this.$t('views.lang_nutrition_contents.' + b) ? this.$t('views.lang_nutrition_contents.' + b + '.name') : b
        bb = bb.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        if (aa.toLowerCase() < bb.toLowerCase()) {
          return -1;
        }
        if (bb.toLowerCase() > aa.toLowerCase()) {
          return 1;
        }
        return 0;

      }).reduce((obj, key) => {
        obj[key] = this.show_nutritional_content[key];
        return obj;
      }, {});
    this.show_nutritional_content = sort

  },

  watch: {
    'food.nutritional_content.monounsaturated_fat': function (value) {
      this.food.nutritional_content_by_percent.monounsaturated_fat = value ? (value * 100 / 38000).toFixed(2) : "";

      if (value && this.food.nutritional_content.polyunsaturated_fat) {
        this.food.nutritional_content.unsaturated_fat = Number(value) + Number(this.food.nutritional_content.polyunsaturated_fat)
      }

    },
    'food.nutritional_content.polyunsaturated_fat': function (value) {
      this.food.nutritional_content_by_percent.polyunsaturated_fat = value ? (value * 100 / 16000).toFixed(2) : "";

      if (value && this.food.nutritional_content.monounsaturated_fat) {
        this.food.nutritional_content.unsaturated_fat = Number(value) + Number(this.food.nutritional_content.monounsaturated_fat)
      }
    },
    'food.nutritional_content.omega_3': function (value) {
      this.food.nutritional_content_by_percent.omega_3 = value ? (value * 100 / 1600).toFixed(2) : "";
    },
    'food.nutritional_content.omega_6': function (value) {
      this.food.nutritional_content_by_percent.omega_6 = value ? (value * 100 / 17000).toFixed(2) : "";
    },
    'food.nutritional_content.vitamin_a': function (value) {
      this.food.nutritional_content_by_percent.vitamin_a = value ? (value * 100 / 3000).toFixed(2) : "";
    },
  },
  methods: {
    formatName(text) {
      return (this.$t('views.lang_nutrition_contents.' + text)) ? this.$t('views.lang_nutrition_contents.' + text + '.name') : ""
    },
    formatUnit(text) {
      return (this.$t('views.lang_nutrition_contents.' + text)) ? this.$t('views.lang_nutrition_contents.' + text + '.unit') : ""
    },
    getOrder(text) {
      var types = this.$global.getTypeNutrient()
      var i = 0
      var order = "9999"

      Object.keys(types).forEach((type, i) => {
        if (types[type].includes(text)) {
          var index = types[type].indexOf(text)
          order = (i * 100) + index
        }
      })






      return order
    },
    searchNutritionItem: function (ev) {
      var allowed = ev.target.value.trim()

      allowed = allowed.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
      var _this = this
      const filtered = Object.keys(this.nutritional_content)
        .filter(function (key) {
          var text = _this.formatName(key).toLowerCase()
          text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          return text.includes(allowed)
        }).reduce((obj, key) => {
          obj[key] = this.nutritional_content[key];
          return obj;
        }, {});

      this.show_nutritional_content = filtered
    }
  }
})
</script>
