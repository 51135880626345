import ApiService from "./api.service"

/**
 * Objeto que tiene las funciones de AI, Y guarda la lógica de la petición POST para crear contenido en AI, pasando los parametros especificos y devolviendo el contenido generado a la API
 * */

const AIService = {
  generateContent: async function(params) {
    const response = await ApiService.post("/api/ai/generate-content", params)

    return response.data
  }
}

export default AIService
