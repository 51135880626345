<template>
  <div>
    <div id="tiny-slider-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mx-6">
              <div v-html="recomendations" class="center-mode">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="save-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.labAnalysis.text15') }}</div>
        <div class="text-gray-600 mt-1">
        </div>
      </div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ $t('views.labAnalysis.text16', { name: item.name || item.key }) }}</h2>
    </div>
    <div class="grid grid-cols-1 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <label for="crud-form-1" class="form-label">{{ $t('views.labAnalysis.text17') }}</label>
            <input id="crud-form-1" type="text" name="chart-tooltip" class="form-control w-full" placeholder=""
              v-model="item.name" disabled />
          </div>
          <!-- <div class="mt-3">
            <label for="crud-form-2" class="form-label">Category</label>
            <TomSelect
              id="crud-form-2"
              v-model="categories"
              class="w-full"
              multiple
              add-precedence
            >
              <option value="1">Sport & Outdoor</option>
              <option value="2">PC & Laptop</option>
              <option value="3">Smartphone & Tablet</option>
              <option value="4">Photography</option>
            </TomSelect>
          </div> -->
          <div class="mt-3">
            <label for="crud-form-3" class="form-label">{{ $t('views.labAnalysis.text18') }}</label>
            <div class="input-group">
              <input id="crud-form-3" type="text" class="form-control" placeholder="ej: mg/dL"
                aria-describedby="input-group-1" v-model="item.unit" />
            </div>
            <div v-if="validations.unit" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}</div>
          </div>
          <div class="mt-3">
            <label class="form-label">{{ $t('views.labAnalysis.text20') }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">{{ $t('views.labAnalysis.text21') }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-3"
                  v-model="item.min_female" />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-3" class="input-group-text">{{ $t('views.labAnalysis.text22') }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-4"
                  v-model="item.max_female" />
              </div>
            </div>
            <div class="form-help">{{ $t('views.labAnalysis.text23') }}</div>
            <div v-if="validations.female_reference" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">{{ $t('views.labAnalysis.text24') }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">{{ $t('views.labAnalysis.text21') }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-3"
                  v-model="item.min_male" />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-3" class="input-group-text">{{ $t('views.labAnalysis.text22') }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-4"
                  v-model="item.max_male" />
              </div>
            </div>
            <div class="form-help">{{ $t('views.labAnalysis.text23') }}</div>
            <div v-if="validations.male_reference" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}</div>
          </div>

          <div class="mt-3">
            <label class="form-label">{{ 'Valores de doble check' }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">{{ 'Hasta' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-3"
                  v-model="item.check_up_to" />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-3" class="input-group-text">{{ 'Desde' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-4"
                  v-model="item.check_from" />
              </div>
            </div>
            <div class="form-help">{{ $t('views.labAnalysis.text23') }}</div>
            <div v-if="validations.check" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}</div>
          </div>


          <div class="mt-3">
            <label class="form-label">{{ 'Valores de doble check para mujeres' }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">{{ 'Hasta' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-3"
                  v-model="item.check_up_to_female" />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-3" class="input-group-text">{{ 'Desde' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-4"
                  v-model="item.check_from_female" />
              </div>
            </div>
            <div class="form-help">{{ $t('views.labAnalysis.text23') }}</div>
            <div v-if="validations.check_female" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}</div>
          </div>


          <div class="mt-3">
            <label class="form-label">{{ 'Valores de doble check para hombres' }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">{{ 'Hasta' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-3"
                  v-model="item.check_up_to_male" />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-3" class="input-group-text">{{ 'Desde' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-4"
                  v-model="item.check_from_male" />
              </div>
            </div>
            <div class="form-help">{{ $t('views.labAnalysis.text23') }}</div>
            <div v-if="validations.check_male" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}</div>
          </div>


          <div class="mt-3">
            <label class="form-label">{{ 'Valores de error' }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">{{ 'Hasta' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-3"
                  v-model="item.error_up_to" />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-3" class="input-group-text">{{ 'Desde' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-4"
                  v-model="item.error_from" />
              </div>
            </div>
            <div class="form-help">{{ $t('views.labAnalysis.text23') }}</div>
            <div v-if="validations.error" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}</div>
          </div>


          <div class="mt-3">
            <label class="form-label">{{ 'Valores de error para mujeres' }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">{{ 'Hasta' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-3"
                  v-model="item.error_up_to_female" />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-3" class="input-group-text">{{ 'Desde' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-4"
                  v-model="item.error_from_female" />
              </div>
            </div>
            <div class="form-help">{{ $t('views.labAnalysis.text23') }}</div>
            <div v-if="validations.error_female" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}</div>
          </div>


          <div class="mt-3">
            <label class="form-label">{{ 'Valores de error para hombres' }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">{{ 'Hasta' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-3"
                  v-model="item.error_up_to_male" />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-3" class="input-group-text">{{ 'Desde' }}</div>
                <input type="number" class="form-control" placeholder="" aria-describedby="input-group-4"
                  v-model="item.error_from_male" />
              </div>
            </div>
            <div class="form-help">{{ $t('views.labAnalysis.text23') }}</div>
            <div v-if="validations.error_male" class="text-theme-6 mt-2">{{ $t('views.labAnalysis.text19') }}</div>
          </div>




          <div class="text-right mt-5">
            <button v-on:click="saveMainlab" type="button" class="btn btn-primary w-24">
              <LoadingIcon v-if="loading" icon="three-dots" class="" />
              <span v-else>{{ $t('views.save') }}</span>
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <div v-if="subLevelBoth.length > 0 || subLevelMale.length > 0 || subLevelFemale.length > 0">

      <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">{{ $t('views.labAnalysis.text23', { name: item.name }) }}</h2>
      </div>
      <div class="intro-y grid grid-cols-12 gap-6 mt-5">


        <!-- AMBOS -->
        <div v-if="subLevelBoth.length > 0" class="col-span-12 lg:col-span-12">
          <div class="intro-y box">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto">{{ $t('views.labAnalysis.text26') }}</h2>
              <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"></div>
            </div>
            <div id="boxed-accordion" class="p-5">
              <div class="preview">
                <div id="faq-accordion-2" class="accordion accordion-boxed">
                  <div v-for="(each) in subLevelBoth" class="accordion-item" :key="each.id">
                    <div id="faq-accordion-content-1" class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-accordion-collapse-5" aria-expanded="true"
                        aria-controls="faq-accordion-collapse-5" v-on:click="getRecomendations(each.id)">
                        <div class="flex flex-col sm:flex-row items-center">
                          <h2 class="font-small text-base mr-auto">
                            <span style="display: inline-flex">
                              {{ subLevelName(each.level_id) }} {{ each.gender == "MALE" ?
                "(" + $t('views.labAnalysis.text27', 1) + ")" : "" }} {{ each.gender == "FEMALE" ?
                "(" + $t('views.labAnalysis.text27', 2) + ")" : "" }}
                              <div v-bind:style="{ background: subLevelExtra(each.level_id).color, color: '#000' }"
                                class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium ml-3">
                                {{ $t('views.labAnalysis.text37', { from: each.min, to: each.max }) }}</div>
                            </span>
                          </h2>
                          <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
                            <!-- Última modificación: {{humanDate(each.updatedAt)}} -->
                          </div>
                        </div>
                      </button>
                    </div>
                    <div id="faq-accordion-collapse-5" class="accordion-collapse collapse"
                      aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-2">
                      <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                        <div>
                          <div class="mt-3">
                            <label for="crud-form-4" class="form-label">{{ $t('views.labAnalysis.text28') }}</label>
                            <div class="input-group">
                              <input id="crud-form-4" type="number" class="form-control" placeholder=""
                                aria-describedby="input-group-2" v-model="each.min" />
                              <div id="input-group-2" class="input-group-text">{{ item.unit }}</div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <label for="crud-form-4" class="form-label">{{ $t('views.labAnalysis.text29') }}</label>
                            <div class="input-group">
                              <input id="crud-form-4" type="number" class="form-control" placeholder=""
                                aria-describedby="input-group-2" v-model="each.max" />
                              <div id="input-group-2" class="input-group-text">{{ item.unit }}</div>

                            </div>
                          </div>

                          <div v-if="each.insulina != null || each.cortisol != null" class="mt-3">
                            <label class="form-label">{{ $t('views.labAnalysis.text30') }}</label>
                            <div
                              v-bind:class="{ 'grid-cols-1': (each.insulina != null || each.cortisol != null), 'grid-cols-2': (each.insulina != null && each.cortisol != null) }"
                              class="sm:grid gap-2">
                              <div v-if="each.insulina != null" class="input-group">
                                <div id="input-group-3" class="input-group-text">{{ $t('views.labAnalysis.text31') }}
                                </div>
                                <input type="number" class="form-control" placeholder="Ej: 8"
                                  aria-describedby="input-group-3" v-model="each.insulina" />
                              </div>
                              <div v-if="each.cortisol != null" class="input-group mt-2 sm:mt-0">
                                <div id="input-group-4" class="input-group-text">{{ $t('views.labAnalysis.text32') }}
                                </div>
                                <input type="number" class="form-control" placeholder="Ej: -10"
                                  aria-describedby="input-group-4" v-model="each.cortisol" />
                              </div>
                            </div>
                          </div>
                          <div v-if="Number(each.min) > Number(each.max)" class="text-theme-6 mt-2">{{
                $t('views.labAnalysis.text33') }}</div>
                          <div v-if="each.min.length == 0 || each.max.length == 0" class="text-theme-6 mt-2">{{
                $t('views.labAnalysis.text34') }}</div>
                          <!-- <h2 class="mt-3 font-medium text-base mr-auto">Otras herramientas</h2>
              <div class="mt-3">
                <label></label>
                <div class="mt-2">
                  <div class="form-check">
                    <input
                      id="checkbox-switch-7"
                      class="form-check-switch"
                      type="checkbox"
                      v-model="each.both"
                      v-on:change=genderChange(each)
                    />
                    <label class="form-check-label" for="checkbox-switch-7"
                      >Usar lo mismo para mujer</label
                    >
                  </div>
                </div>
              </div> -->
                          <!-- <div class="mt-3">
                <label></label>
                <div class="mt-2">
                  <div class="form-check">
                    <input
                      id="checkbox-switch-5"
                      class="form-check-switch"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-5"
                      >No aplica para Hombre</label>
                  </div>
                </div>
              </div> -->
                          <button
                            v-bind:disabled="Number(each.min) > Number(each.max) || each.min.length == 0 || each.max.length == 0"
                            v-on:click="saveLabLevel(each, $event)" class="btn btn-primary mt-5">{{ $t('views.save')
                            }}</button>
                          <a v-if="queryUrl.test" data-toggle="modal" data-target="#tiny-slider-modal-preview" href="#">
                            <button style="float: right" type="button" class="btn btn-outline-secondary mt-5">
                              <span>{{ $t('views.labAnalysis.text35') }}</span>
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- AMBOS -->

        <!-- BEGIN: Boxed Accordion -->

        <div v-if="subLevelBoth.length == 0 && subLevelFemale.length > 0" class="col-span-12 lg:col-span-6">
          <div class="intro-y box">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto">{{ $t('views.labAnalysis.text36') }}</h2>
              <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
              </div>
            </div>
            <div id="boxed-accordion" class="p-5">
              <div class="preview">
                <div id="faq-accordion-2" class="accordion accordion-boxed">
                  <div v-for="(each, index) in subLevelFemale" class="accordion-item" :key="each.id">
                    <div id="faq-accordion-content-1" class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-accordion-collapse-5" aria-expanded="true"
                        aria-controls="faq-accordion-collapse-5" v-on:click="getRecomendations(each.id)">
                        <div class="flex flex-col sm:flex-row items-center">
                          <h2 class="font-small text-base mr-auto">
                            {{ subLevelName(each.level_id) }} <small>(De {{ each.min }} a {{ each.max }})</small>
                          </h2>
                          <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
                            <!-- Última modificación: {{humanDate(each.updatedAt)}} -->
                          </div>
                        </div>
                      </button>
                    </div>
                    <div id="faq-accordion-collapse-5" class="accordion-collapse collapse"
                      aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-2">
                      <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                        <div>
                          <div class="mt-3">
                            <label for="crud-form-4" class="form-label">{{ $t('views.labAnalysis.text28') }}</label>
                            <div class="input-group">
                              <input id="crud-form-4" type="number" class="form-control" placeholder=""
                                aria-describedby="input-group-2" v-model="each.min" />
                              <div id="input-group-2" class="input-group-text">{{ item.unit }}</div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <label for="crud-form-4" class="form-label">{{ $t('views.labAnalysis.text29') }}</label>
                            <div class="input-group">
                              <input id="crud-form-4" type="number" class="form-control" placeholder=""
                                aria-describedby="input-group-2" v-model="each.max" />
                              <div id="input-group-2" class="input-group-text">{{ item.unit }}</div>
                            </div>
                          </div>
                          <div v-if="each.insulina != null || each.cortisol != null" class="mt-3">
                            <label class="form-label">{{ $t('views.labAnalysis.text30') }}</label>
                            <div
                              v-bind:class="{ 'grid-cols-1': (each.insulina != null || each.cortisol != null), 'grid-cols-2': (each.insulina != null && each.cortisol != null) }"
                              class="sm:grid gap-2">
                              <div v-if="each.insulina != null" class="input-group">
                                <div id="input-group-3" class="input-group-text">{{ $t('views.labAnalysis.text31') }}
                                </div>
                                <input type="number" class="form-control" placeholder="Ej: 8"
                                  aria-describedby="input-group-3" v-model="each.insulina" />
                              </div>
                              <div v-if="each.cortisol != null" class="input-group mt-2 sm:mt-0">
                                <div id="input-group-4" class="input-group-text">{{ $t('views.labAnalysis.text32') }}
                                </div>
                                <input type="number" class="form-control" placeholder="Ej: -10"
                                  aria-describedby="input-group-4" v-model="each.cortisol" />
                              </div>
                            </div>
                          </div>
                          <div v-if="Number(each.min) > Number(each.max)" class="text-theme-6 mt-2">{{
                $t('views.labAnalysis.text33') }}</div>
                          <div v-if="each.min.length == 0 || each.max.length == 0" class="text-theme-6 mt-2">{{
                $t('views.labAnalysis.text34') }}</div>
                          <!-- <h2 class="mt-3 font-medium text-base mr-auto">Otras herramientas</h2>
              <div class="mt-3">
                <label></label>
                <div class="mt-2">
                  <div class="form-check">
                    <input
                      id="checkbox-switch-7"
                      class="form-check-switch"
                      type="checkbox"
                      v-model="each.both"
                      v-on:change=genderChange(each)
                    />
                    <label class="form-check-label" for="checkbox-switch-7"
                      >Usar lo mismo para hombre</label
                    >
                  </div>
                </div>
              </div> -->
                          <!-- <div class="mt-3">
                <label></label>
                <div class="mt-2">
                  <div class="form-check">
                    <input
                      id="checkbox-switch-5"
                      class="form-check-switch"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-5"
                      >No aplica para Mujer</label>
                  </div>
                </div>
              </div> -->
                          <button
                            v-bind:disabled="Number(each.min) > Number(each.max) || each.min.length == 0 || each.max.length == 0"
                            v-on:click="saveLabLevel(each, $event)" class="btn btn-primary mt-5">{{ $t('views.save')
                            }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="subLevelBoth.length == 0 && subLevelMale.length > 0"
          v-bind:class="{ 'lg:col-span-12': subLevelFemale.length == 0, 'lg:col-span-6': subLevelFemale.length > 0 }"
          class="col-span-12">
          <div class="intro-y box">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto">{{ $t('views.labAnalysis.text37') }}</h2>
              <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
              </div>
            </div>
            <div id="boxed-accordion" class="p-5">
              <div class="preview">
                <div id="faq-accordion-2" class="accordion accordion-boxed">
                  <div v-for="(each, index) in subLevelMale" class="accordion-item" :key="each.id">
                    <div id="faq-accordion-content-1" class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-accordion-collapse-5" aria-expanded="true"
                        aria-controls="faq-accordion-collapse-5" v-on:click="getRecomendations(each.id)">
                        <div class="flex flex-col sm:flex-row items-center">
                          <h2 class="font-small text-base mr-auto">
                            {{ subLevelName(each.level_id) }} <small>({{ $t('views.labAnalysis.text37', {
                from: each.min,
                to: each.max
              }) }})</small>
                          </h2>
                          <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
                            <!-- Última modificación: {{humanDate(each.updatedAt)}} -->
                          </div>
                        </div>
                      </button>
                    </div>
                    <div id="faq-accordion-collapse-5" class="accordion-collapse collapse"
                      aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-2">
                      <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                        <div>
                          <div class="mt-3">
                            <label for="crud-form-4" class="form-label">{{ $t('views.labAnalysis.text28') }}</label>
                            <div class="input-group">
                              <input id="crud-form-4" type="number" class="form-control" placeholder=""
                                aria-describedby="input-group-2" v-model="each.min" />
                              <div id="input-group-2" class="input-group-text">{{ item.unit }}</div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <label for="crud-form-4" class="form-label">{{ $t('views.labAnalysis.text29') }}</label>
                            <div class="input-group">
                              <input id="crud-form-4" type="number" class="form-control" placeholder=""
                                aria-describedby="input-group-2" v-model="each.max" />
                              <div id="input-group-2" class="input-group-text">{{ item.unit }}</div>
                            </div>

                          </div>
                          <div v-if="each.insulina != null || each.cortisol != null" class="mt-3">
                            <label class="form-label">{{ $t('views.labAnalysis.text30') }}</label>
                            <div
                              v-bind:class="{ 'grid-cols-1': (each.insulina != null || each.cortisol != null), 'grid-cols-2': (each.insulina != null && each.cortisol != null) }"
                              class="sm:grid gap-2">
                              <div v-if="each.insulina != null" class="input-group">
                                <div id="input-group-3" class="input-group-text">{{ $t('views.labAnalysis.text31') }}
                                </div>
                                <input type="number" class="form-control" placeholder="Ej: 8"
                                  aria-describedby="input-group-3" v-model="each.insulina" />
                              </div>
                              <div v-if="each.cortisol != null" class="input-group mt-2 sm:mt-0">
                                <div id="input-group-4" class="input-group-text">{{ $t('views.labAnalysis.text32') }}
                                </div>
                                <input type="number" class="form-control" placeholder="Ej: -10"
                                  aria-describedby="input-group-4" v-model="each.cortisol" />
                              </div>
                            </div>
                          </div>
                          <div v-if="Number(each.min) > Number(each.max)" class="text-theme-6 mt-2">{{
                $t('views.labAnalysis.text33') }}</div>
                          <div v-if="each.min.length == 0 || each.max.length == 0" class="text-theme-6 mt-2">{{
                            $t('views.labAnalysis.text34') }}</div>
                          <!-- <h2 class="mt-3 font-medium text-base mr-auto">Otras herramientas</h2>
              <div class="mt-3">
                <label></label>
                <div class="mt-2">
                  <div class="form-check">
                    <input
                      id="checkbox-switch-7"
                      class="form-check-switch"
                      type="checkbox"
                      v-model="each.both"
                      v-on:change=genderChange(each)
                    />
                    <label class="form-check-label" for="checkbox-switch-7"
                      >Usar lo mismo para mujer</label
                    >
                  </div>
                </div>
              </div> -->
                          <!-- <div class="mt-3">
                <label></label>
                <div class="mt-2">
                  <div class="form-check">
                    <input
                      id="checkbox-switch-5"
                      class="form-check-switch"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-5"
                      >No aplica para Hombre</label>
                  </div>
                </div>
              </div> -->
                          <button
                            v-bind:disabled="Number(each.min) > Number(each.max)  || each.min.length == 0 || each.max.length == 0"
                            v-on:click="saveLabLevel(each, $event)" class="btn btn-primary mt-5">{{ $t('views.save')
                            }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- END: Boxed Accordion -->
      </div>

    </div>
    <div v-else class="intro-y box mt-5">
      <div class="flex flex-col sm:flex-row items-center p-5">
        <h2 class="font-medium text-base mr-auto">{{ $t('views.labAnalysis.text39') }}</h2>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dark .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  --tw-text-opacity: 1;
  color: #fff;
}

.mt-3 {
  color: #fff;
}

/* .btn-primary{
  background: #6c869a !important;
  border-color: #6c869a !important;
} */

.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
<script>
import LabService from "/src/services/lab.service";
import lab_lang from "/src/utils/lab_langs";
import Toastify from 'toastify-js'
export default {
  setup() {
  },
  data() {
    return {
      item: JSON.parse(localStorage.labItem),
      validations: {
        name: false,
        unit: false,
        male_reference: false,
        female_reference: false,
        check: false,
        check_male: false,
        check_female: false,
        error: false,
        error_male: false,
        error_female: false
      },
      loading: false,
      recomendations: null,
      queryUrl: this.$route.query
    }
  },
  computed: {
    hasAtLeastOneMale: function () {
      return this.item.lab_item_levels.find((a) => {
        return a.gender == "MALE" && this.item.ignore_lab_item_level != "MALE" && this.item.ignore_lab_item_level != "BOTH"
      }) != null
    },
    subLevelBoth: function () {
      return this.item.lab_item_levels.filter((a) => {
        var parsedQuizPoints = JSON.parse(a.quiz_points || "{}")
        if (parsedQuizPoints.INSULINA) {
          a.insulina = parsedQuizPoints.INSULINA
        }
        if (parsedQuizPoints.CORTISOL) {
          a.cortisol = parsedQuizPoints.CORTISOL
        }
        return a.gender == "BOTH" && this.item.ignore_lab_item_level != "BOTH"
      })
    },
    subLevelMale: function () {
      return this.item.lab_item_levels.filter((a) => {
        return a.gender == "MALE" && this.item.ignore_lab_item_level != "MALE" && this.item.ignore_lab_item_level != "BOTH"
      })
    },
    subLevelFemale: function () {
      return this.item.lab_item_levels.filter((a) => {
        return (a.gender == "FEMALE") && this.item.ignore_lab_item_level != "FEMALE" && this.item.ignore_lab_item_level != "BOTH"
      })
    }
  },
  created() {
    if (!this.item.name) {
      this.item.name = this.item.key
    }
    if (this.item.min != null) {
      this.item.min_male = `${this.item.min}`
      this.item.min_female = `${this.item.min}`
    }
    if (this.item.max != null) {
      this.item.max_male = `${this.item.max}`
      this.item.max_female = `${this.item.max}`
    }
  },
  watch: {
    '$i18n.locale': function (l) {
      this.getRecomendations();
    }
  },
  methods: {
    getRecomendations(level_id) {
      this.recomendations = null
      LabService.getRecomendationsByLabItem({ lab_item_id: 3, lab_level_id: level_id }).then((serverResponse) => {
        this.recomendations = serverResponse
      })
    },
    saveSuccess() {
      Toastify({
        node: cash('#save-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('labItem');
      this.$router.push({ path: '/lab-analysis-list' });
    },
    subLevelName(param) {
      if (lab_lang[param]) {
        // console.log(lab_lang[param].name)
        return this.$t("views." + lab_lang[param].name)
      } else {
        return param.replaceAll("_", " ")
      }
    },
    subLevelExtra(param) {
      if (lab_lang[param]) {
        return lab_lang[param]
      } else {
        return {}
      }
    },
    genderChange(item) {
      console.log("genderChange: ", item);
    },
    humanDate(param) {
      var date = new Date(param)
      return date.toLocaleString()
    },
    saveMainlab(a) {

      // this.validations.unit = (this.item.unit == "")

      this.validations.female_reference = false
      if (this.item.min_female == "" && this.item.max_female == "") {


        this.item.min_female == null
        this.item.max_female == null

      } else {
        if (Number(this.item.min_female) > Number(this.item.max_female)) {
          // console.log("A1", this.item.min_female, typeof this.item.min_female, this.item.max_female, typeof this.item.max_female, this.item.min_female > this.item.max_female);
          this.validations.female_reference = true
        }
        if (this.item.min_female == "" && this.item.max_female != "") {
          // console.log("A2");
          this.validations.female_reference = true
        }
        if (this.item.min_female != "" && this.item.max_female == "") {
          // console.log("A3");
          this.validations.female_reference = true
        }
      }

      this.validations.male_reference = false
      if (this.item.min_male == "" && this.item.max_male == "") {

        this.item.min_male == null
        this.item.max_male == null

      } else {
        if (Number(this.item.min_male) > Number(this.item.max_male)) {
          // console.log("B1");
          this.validations.male_reference = true
        }
        if (this.item.min_male == "" && this.item.max_male != "") {
          // console.log("B2");
          this.validations.male_reference = true
        }
        if (this.item.min_male != "" && this.item.max_male == "") {
          // console.log("B3");
          this.validations.male_reference = true
        }
      }

      const validations_values = [["error", ""], ["error", "_male"], ["error", "_female"], ["check", ""], ["check", "_male"], ["check", "_female"]]

      validations_values.forEach(type => {
        var to = this.item[`${type[0]}_up_to${type[1]}`]
        var from = this.item[`${type[0]}_from${type[1]}`]
        this.validations[`${type[0]}${type[1]}`] = false
        if (to == "" && from == "") {
          this.item[`${type[0]}_up_to${type[1]}`] = null
          this.item[`${type[0]}_from${type[1]}`] = null
        } else if (Number(from) > Number(to) || from == "" || to == "") {
          this.validations[`${type[0]}${type[1]}`] = true
        }
      })


      if ((
        this.validations.unit ||
        this.validations.female_reference ||
        this.validations.male_reference ||
        this.validations.error ||
        this.validations.error_male ||
        this.validations.error_female ||
        this.validations.check ||
        this.validations.check_male ||
        this.validations.check_female
      )) {
        return
      } else {
        var toPost = {
          id: this.item.id,
          key: this.item.key,
          unit: this.item.unit
        }
        if ((this.item.min_male == this.item.min_female) && (this.item.max_female == this.item.max_male)) {
          toPost.min = Number(this.item.min_male)
          toPost.max = Number(this.item.max_male)
        } else {
          toPost.min_male = (this.item.min_male == "") ? null : Number(this.item.min_male)
          toPost.max_male = (this.item.max_male == "") ? null : Number(this.item.max_male)
          toPost.min_female = (this.item.min_female == "") ? null : Number(this.item.min_female)
          toPost.max_female = (this.item.max_female == "") ? null : Number(this.item.max_female)
        }

        toPost.error_up_to = (this.item.error_up_to == "") ? null : Number(this.item.error_up_to)
        toPost.error_from = (this.item.error_from == "") ? null : Number(this.item.error_from)
        toPost.error_up_to_male = (this.item.error_up_to_male == "") ? null : Number(this.item.error_up_to_male)
        toPost.error_from_male = (this.item.error_from_male == "") ? null : Number(this.item.error_from_male)
        toPost.error_up_to_female = (this.item.error_up_to_female == "") ? null : Number(this.item.error_up_to_female)
        toPost.error_from_female = (this.item.error_from_female == "") ? null : Number(this.item.error_from_female)

        toPost.check_up_to = (this.item.check_up_to == "") ? null : Number(this.item.check_up_to)
        toPost.check_from = (this.item.check_from == "") ? null : Number(this.item.check_from)
        toPost.check_up_to_male = (this.item.check_up_to_male == "") ? null : Number(this.item.check_up_to_male)
        toPost.check_from_male = (this.item.check_from_male == "") ? null : Number(this.item.check_from_male)
        toPost.check_up_to_female = (this.item.check_up_to_female == "") ? null : Number(this.item.check_up_to_female)
        toPost.check_from_female = (this.item.check_from_female == "") ? null : Number(this.item.check_from_female)

        // console.log("enviar al servidor");
        // console.log(this.item, toPost);
        this.loading = true
        LabService.saveLabItem(toPost).then((serverResponse) => {
          // console.log("Server: ", serverResponse);
          this.loading = false
          this.saveSuccess()

        }).catch((error) => {
          // console.log("Error: ", error);
          this.loading = false

        })
      }

    },
    saveLabLevel(labLevel, event) {

      var toPost = {
        id: labLevel.id,
        level_id: labLevel.level_id,
        gender: labLevel.gender,
        min: labLevel.min,
        max: labLevel.max,
      }


      if ((labLevel.insulina != undefined && labLevel.insulina != "") || (labLevel.cortisol != undefined && labLevel.cortisol != "")) {
        var quiz_points = {}
        if (labLevel.insulina) {
          quiz_points.INSULINA = labLevel.insulina
        }
        if (labLevel.cortisol) {
          quiz_points.CORTISOL = labLevel.cortisol
        }
        toPost.quiz_points = JSON.stringify(quiz_points)
      }

      // quiz_points: params.quiz_points || null,

      this.loading = true
      LabService.saveLabItemLevel(toPost).then((serverResponse) => {
        // console.log("Server: ", serverResponse);
        this.loading = false
        this.saveSuccess()

      }).catch((error) => {
        // console.log("Error: ", error);
        this.loading = false

      })

    }
  }
}
</script>
<style></style>
