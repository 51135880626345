import ApiService from "./api.service"

/**
 * Objeto que tiene las funciones de Seccions
 *
 * getAll --> es una función que realiza una petición GET para solicitar datos, a un enterpoit específico.
 * espera que se complete la solicitud y devuelve lo que recibe
 *
 * updateContact --> es una función que realiza una petición POST para actualizar información, a través de un enterpoint solicitando que le actualice la info de un contac con un id especifico
 * una vez actualizado retorna los datos
 *
 * destroy --> es una función que realiza una petición DELETE para borrar información, atrás de un enterpoint específico. Utiliza el slug(cadena de texto que se escribe contacto-ble) para identificar que usuario eliminar
 * espera que la Api responda y da la información proporcionada como respuesta
 * */

const SessionService = {
  getAll: async function(params) {
    const response = await ApiService._get("api/session/get", params)
    return response.data
  },

  updateContact: async function(id, contact) {
    const response = await ApiService.post("api/update-contact/" + id, contact)
    return response.data
  },

  destroy: async function(slug) {
    const response = await ApiService.delete("api/delete-contact/" + slug)
    return response.data
  }
}

export default SessionService
