import ApiService from "./api.service"
const TOKEN_KEY = "access_token"

/**
 * Se declara el objeto userService
 *
 * loginAs --> es una función que realiza una petición ._GET , la cual a través del enterpoint solicita ingresar como otro usuario,
 * espera la respuesta se complete y devuelva la respuesta del servidor
 *
 * delete--> función que realiza una petición ._GET, la cual a través del enterpoit especifico solicita eliminar una sección especifica, la información específica la obtiene de los parms
 * una vez se complete la solicitud devuelve la respuesta (ej: se elimino la seccion)
 *
 * duplicate --> función que realiza una petición ._GET, la cual a través de un enterpoint específico solicita duplicar una sección la cual se identifica con los parámetros que se le pasa
 * una vez se complete la solicitud devuelve la respuesta
 *
 * notifyError -->función que realiza una petición POST , la cual a través de un enterpoint específico, notifica al equipo sobre un error.
 * Los detalles del error están en params, una vez la solicitud se proceso el servidor envía una respuesta
 *
 * getPorfile --> función que realiza una petición ._GET, la cual a través de un enterpoint específico, solicita la información sobre el perfil de salud de un usuario.
 * una vez se completa la solicitud, se envía la respuesta que antes se muestra en consola
 *
 * verifyAdmin --> función que realiza una petición POST, la cual a través de un enterpoint específico, verifica si las credenciales son válidas
 * una vez completada la solicitud, se envía la respuesta
 *
 * verifyAdminToken -->es igual que verifyAdmin pero con el token de autenticidad
 * verifyMasterKey --> es igual que verifyAdmin pero con la clave maestra
 *
 * logout --> función que para cerrar la sesión de usuario.
 * Primero elimina la cabecera HTTP, los tokens de acceso y guarda las preferencias del usuario (idioma, color , etc), luego limpia el local storage  y restaura las preferencias para cuando ingrese nuevamente.
 * cuando finaliza retorna true que indica que el cierre de la sección se  finalizó.
 *
 * getUserPermission --> función que a través de una petición ._GET solicita a un enterpoint específico la lista de permisos asociadas a ese usuario
 * cuando retorna la lista.
 *
 * getAdminUser --> función que a través de una petición ._GET  solicita a un enterpoint específico un listado de usuarios con rol administrador
 * cuanto termina retorna la lista
 *  */

const UserService = {
  loginAs: async function(params) {
    const response = await ApiService._get("/login-as", params)
    return response.data
  },
  delete: async function(params) {
    const response = await ApiService._get("/session/destroy", params)
    return response.data
  },
  duplicate: async function(params) {
    const response = await ApiService._get("/session/duplicate", params)
    return response.data
  },
  notifyError: async function(params) {
    const response = await ApiService.post("/api/dev-support/save", params)
    return response.data
  },
  getProfile: async function(params) {
    const response = await ApiService._get("/api/health-profile/get")
    console.log(response.data)
    return response.data
  },
  verifyAdmin: async function(params) {
    const response = await ApiService.post("/api/verify-admin", params)
    return response.data
  },
  verifyAdminToken: async function(params) {
    const response = await ApiService._get("/api/verify-admin-token", params)
    return response.data
  },
  verifyMasterKey: async function(params) {
    const response = await ApiService.post("/api/verify-masterkey", params)
    return response.data
  },
  logout: async function() {
    ApiService.removeHeader()
    localStorage.removeItem("token")
    localStorage.removeItem("access_token")
    var preferences = localStorage.getItem("preferences")
    localStorage.clear()
    localStorage.setItem("preferences", preferences)

    return true
  },
  getUserPermissions: async function() {
    const response = await ApiService._get("/api/admin/permissions/get-by-user")
    return response.data
  },

  getAllUsers: async function() {
    return true
    const response = await ApiService._get("/api/admin/permissions/get-by-user")
    return response.data
  },

  getAdminUsers: async function() {
    const response = await ApiService._get("/api/admin/users/get-admins")
    return response.data
  }
}

export default UserService
