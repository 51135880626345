import { createI18n } from "vue-i18n"
import es_views from "./locals/es/views.json"
import es_components from "./locals/es/components.json"
import en_views from "./locals/en/views.json"
import en_components from "./locals/en/components.json"

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : navigator.language.split("-")[0],
  fallbackLocale: "es", // set fallback locale
  silentFallbackWarn: true,
  globalInjection: true,
  messages: {
    es: {
      views: es_views,
      components: es_components
    },
    en: {
      views: en_views,
      components: en_components
    }
  }
})

export default i18n
