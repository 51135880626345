<template>
  <AlertMessage id_alert="diseaseEdit" ref="alertMessage" class="dark myAuto" v-bind:message="'<p>Alerta</p>'" />
  <div>
    <div id="save-disease-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.disease.text9') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="remove-disease-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.disease.text10') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div class="intro-y mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t('views.disease.text11') }}: {{ disease.name }}
      </h2>
    </div>
    <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="intro-y box p-5">
          <div>
            <label for="crud-form-1" class="form-label ">{{ $t('views.disease.text12') }}</label>
            <div class="input-group">
              <input id="crud-form-1" type="text" name="chart-tooltip" class="form-control w-full"
                :placeholder="$t('views.disease.text12')" v-model="disease.name" maxlength="110" />
            </div>
            <div v-if="validations.name" class="text-theme-6 mt-2">
              {{ $t('views.disease.text13') }}
            </div>
          </div>
          <div class="mt-5">
            <label for="crud-form-3" class="form-label">{{ $t('views.disease.text5') }}</label>
            <div class="input-group">
              <textarea id="crud-form-3" type="text" class="form-control" :placeholder="$t('views.disease.text5')"
                aria-describedby="input-group-1" rows="5" v-model="disease.description" maxlength="2000"></textarea>
            </div>
          </div>
          <div class="text-right mt-5">
            <button v-on:click="confirmRemove" type="button" class="btn btn-outline-dark w-24 mr-5">
              <LoadingIcon v-if="removeLoading" icon="three-dots" class="" />
              <span v-else>{{ $t('views.delete') }}</span>
            </button>
            <button v-on:click="saveDisease" type="button" class="btn btn-primary w-24">
              <LoadingIcon v-if="loading" icon="three-dots" class="" />
              <span v-else>{{ $t('views.save') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dark .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  --tw-text-opacity: 1;
  color: #fff;
}

.mt-3 {
  color: #fff;
}

.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}

.max-w-label {
  max-width: 90%;
}

.dark .form-check-input[type=checkbox] {
  background-color: #182033 !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.dark .nutrient {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dark .nutrient .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.dark .nutrient .form-check-label {
  border-color: #6c869a;
}

.dark .nutrient:hover input~.form-check-label {
  background-color: rgba(108, 134, 154, 0.5);
}

/* When the checkbox is checked, add a blue background */
.form-check-input[type=checkbox]:checked {
  background-color: #6c869a;
  border-color: #6c869a;
  color: white;
}

.dark .nutrient input:checked~.form-check-label {
  background-color: #6c869a;
  color: white;
}

.form-check-input.checked[type=radio] {
  border-color: #6c869a !important;
}

.form-check-input[type=radio]:before {
  background-color: #6c869a !important;
}

.dark .form-check-input.checked[type=radio] {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.dark .form-check-input.checked[type=radio]:before {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}
</style>
<style>
.receipe-item:last-child .remove-item {
  visibility: hidden;
}
</style>
<script>
import DiseaseService from "/src/services/diseases.service";
import Toastify from "toastify-js";
import AlertMessage from "@/components/alert/Main.vue"

export default {
  components: {
    AlertMessage
  },
  setup() { },
  data() {
    return {
      validations: {
        name: false
      },
      loading: false,
      removeLoading: false,
      disease: {
        name: "",
        description: "",
      },
      activeLang: 'lang__' + (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      langs: this.$global.getLangs(),
      lang_key: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      global_lang: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      getData: true
    };
  },
  watch: {
    '$i18n.locale': function (l) {
      this.getDisease();
    },
    activeLang: function () {
      this.lang_key = this.activeLang.replace("lang__", "")
    }
  },
  created() {
    this.getDisease();
  },
  methods: {
    getDisease: async function () {
      this.disease = (localStorage.diseaseItem) ? JSON.parse(localStorage.diseaseItem) : this.disease
    },
    saveSuccessDrug() {
      Toastify({
        node: cash('#save-disease-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('diseaseItem');
      this.$router.push({ path: '/disease-list' });
    },
    saveDisease: async function () {
      this.validations.name = (this.disease.name === "")

      if (this.validations.name)
        return

      this.loading = true
      var serverResponse = await DiseaseService.save(this.disease, this.lang_key)
      this.loading = false
      if (serverResponse && serverResponse.data && serverResponse.data.length > 0 && !this.disease.id) {
        this.disease.id = serverResponse.data[0].id
      }
      this.saveSuccessDrug()
    },
    confirmRemove() {
      var message = "<p class='text-base px-4'>" + this.$t('views.disease.text16') + "</p>"
      this.$refs.alertMessage.show(message, true, true, this.removeDrug)
    },
    removeDrug() {
      this.removeLoading = true
      this.$refs.alertMessage.hide()
      DiseaseService.remove(this.disease.id).then(function (response) {
        this.removeLoading = false
        this.removeSuccessDrug()
      }.bind(this)).catch((error) => {
        this.removeLoading = false
      })
    },
    removeSuccessDrug() {
      Toastify({
        node: cash('#remove-disease-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('diseaseItem');
      this.$router.push({ path: '/disease-list' });
    },
    changeLang(l) {
      this.activeLang = l
    }
  },
};

</script>
<style></style>
