<template>
  <VerifyAdmin v-bind:check="true" v-bind:id="'initVerify'" ref="isAdmin" class="dark" />
  <div>
    <div class="text-welcome text-xl" v-html="text"></div>
    <div id="access-denied-modal" class="modal overflow-y-auto flex" v-bind:class="{ 'show': error }" tabindex="-1"
      aria-hidden="false" data-v-d4317972="">
      <div class="modal-dialog" data-v-d4317972="">
        <div class="modal-content realative p-5 bg-transparent" data-v-d4317972="">
          <div class="modal-body check p-10 text-center border-2 border-red-600 font-bold text-2xl" data-v-d4317972="">
            {{ $t('views.access_denied') }}
          </div>
          <div class="absolute w-1/12 h-1/4 top-0 left-0 border-t-2 border-l-2 border-red-900"></div>
          <div class="absolute w-1/12 h-1/4 top-0 right-0 border-t-2 border-r-2 border-red-900"></div>
          <div class="absolute w-1/12 h-1/4 bottom-0 left-0 border-b-2 border-l-2 border-red-900"></div>
          <div class="absolute w-1/12 h-1/4 bottom-0 right-0 border-b-2 border-r-2 border-red-900"></div>
        </div>
      </div>
    </div>
  </div>
  <div id="masterKey" data-backdrop="static" tabindex="-1" aria-hidden="true"
    class="modal overflow-y-auto dark bg-transparent">
    <div class="modal-dialog ">
      <div class="modal-content mt-16">
        <div class="modal-body px-5 py-10">
          <div class="text-center">
            <h2 class="intro-y text-lg font-medium">
              <span>{{ $t('components.verify_admin.text1') }}</span>
            </h2>
            <p class="w-96 m-auto my-5 mb-8">
              <span v-html="$t('components.verify_admin.text8', { user: username })"></span>
            </p>
            <input type="password" style="display:none">
            <div class="input-group">
              <input id="crud-form-4" type="password" class="form-control placeholder-gray-500"
                v-bind:placeholder="$t('components.verify_admin.text9')" aria-describedby="input-group-2"
                v-model="masterKey.key" @change="masterKey.errorMessage = ''" v-on:keypress.enter="send"
                v-bind:disabled="masterKey.isLoading" autocomplete="off" />
              <div id="input-group-2" class="input-group-text">
                <button v-bind:disabled="masterKey.isLoading" @click="send" type="button" class="btn btn-primary w-24">
                  <Tippy v-if="!masterKey.isLoading" data-theme="light"
                    v-bind:content="$t('components.verify_admin.text4')">
                    {{ $t('components.verify_admin.text4') }}
                  </Tippy>
                  <Tippy v-if="masterKey.isLoading" data-theme="light"
                    v-bind:content="$t('components.verify_admin.text5')">
                    <LoadingIcon icon="tail-spin" class="text-white w-3 h-3" color="white" />
                  </Tippy>
                </button>
              </div>
            </div>
            <Tippy v-if="masterKey.errorMessage" class="ml-1 mt-2" style="float: left;color: red;" data-theme="light"
              content=masterKey.errorTip>
              {{ masterKey.errorMessage }}
            </Tippy>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dark .modal-body {
  --tw-border-opacity: 1;
  border-color: rgba(49, 58, 85, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));

}

.dark #crud-form-4 {
  color: rgb(226, 232, 240) !important;
}

#crud-form-4::placeholder {
  color: black;
}

.dark #crud-form-4::placeholder {
  color: rgb(226, 232, 240) !important;
}

.modal.show>.modal-dialog {
  margin-top: auto;
  margin-bottom: auto;
}

.modal-body.check {
  background-color: #541111;
  color: #f0040a;
  border-color: #6f0305
}

.text-welcome {
  color: #00e676;
  font-family: Source Code Pro, monospace;
  margin: 0;
  color: transparent;
  text-shadow: 0 0 8px rgb(0 230 118 / 90%);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

body {
  background: #000000 !important;
}

#access-denied-modal {
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 0px;
  z-index: 10000;
}
</style>

<script>
import UserService from "/src/services/user.service"
import ApiService from "/src/services/api.service"
import { TokenService, UserInfo } from "/src/services/storage.service"
import VerifyAdmin from '@/components/verify-admin/Main.vue'
import { useStore } from '@/store'

export default {
  components: {
    VerifyAdmin
  },
  setup() { },
  data() {
    return {
      token: "",
      error: false,
      isAdmin: false,
      requestPin: true,
      text: ` Lorem ipsum dolor sit amet consectetur adipisicing elit. *Voluptates, debitis excepturi! **
                Dolorem, rerum? Illo dolor officiis, recusandae nostrum itaque, *beatae sapiente corporis blanditiis quaerat numquam *ut reiciendis perferendis? Laborum, pariatur?**
                Lorem ipsum dolor sit amet consectetur adipisicing elit.*
                Non ex error beatae inventore *perferendis enim fugiat optio *aliquam similique, cum rerum nesciunt veritatis, *laudantium aliquid laborum maxime *molestias dolorum explicabo?**
                Lorem ipsum,* dolor sit amet consectetur adipisicing elit.** Dicta recusandae iusto laudantium alias rerum,* animi laborum beatae consequatur quod eveniet eum,* non magnam neque eaque dolores omnis nesciunt debitis sapiente`,
      masterKey: {
        isLoading: false,
        key: "",
        errorMessage: ""
      },
      username: ""
    }
  },
  computed: {
    accessDeniedMessage() {
      const accessDeniedMessage = computed(() => useStore().state.main.accessDeniedMessage).value
      console.log(accessDeniedMessage)
      return accessDeniedMessage
    }
  },
  created() {
    window.vue = this
    this.isAdmin = TokenService.isAdmin()
  },
  mounted() {
    this.setText()
    setTimeout(function () {
      this.getToken()
    }.bind(this), 1200)

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('t');

    if (token) {
      let query = Object.assign({}, this.$route.query);
      delete query.t;
      ApiService.setHeader();
      this.$router.replace({ query });

      //  this.validateAcessViaConsoleRedirect(token);
    }

  },
  unmounted() {

    if (document.getElementById("initVerify")) {
      document.getElementById("initVerify").remove()
    }
  },
  methods: {
    getToken() {
      var _this = this
      var token = localStorage.getItem("token")
      UserService.verifyAdminToken({ token })
        .then((response) => {
          if (response && response.valid === true) {
            this.username = "<span class='uppercase'>" + response.username + "</span>"
            TokenService.setIsAdmin()
            cash("#masterKey").modal('show')
          } else {
            setTimeout(function () {
              _this.showError()
            }, 6000)
          }
        })
        .catch(function (error) {
          console.log(error)
          setTimeout(function () {
            _this.showError()
          }, 6000)
        })
    },
    showError() {
      this.error = true
      setTimeout(function () {
        window.location.href = process.env.VUE_APP_CLIENT_URL
      }, 3000)
    },
    setText() {
      var _this = this

      let chars = _this.text.split('')
      _this.text = ''
      let i = 0
      let setT = setInterval(function () {
        if (chars[i] === '*') {
          _this.text += '</br>'
        } else {
          _this.text += chars[i]
        }
        i++

        if (i === chars.length || _this.error) {
          clearInterval(setT)
        }
      }, 30)

    },
    validateAcessViaConsoleRedirect(token) {

      localStorage.token = token;
      ApiService.setHeader();

      this.setText()
      this.getToken()

      UserService.getUserPermissions().then(result => {
        useStore().dispatch('sideMenu/updateMenu', result)
        TokenService.setIsAdmin()
        localStorage.lastSecurityCheckpoint = (new Date()).getTime();
        this.$router.push({ path: "/" })
      });
    },
    send() {
      this.masterKey.errorMessage = ""
      this.masterKey.isLoading = true
      UserService.verifyMasterKey({
        "input": this.masterKey.key
      }).then((response) => {
        // console.log(response)

        if (response.valid) {
          setTimeout(function () {
            // this.$refs.isAdmin.init()
            this.masterKey.isLoading = false
            cash("#masterKey").modal('hide')
            localStorage.lastSecurityCheckpoint = (new Date()).getTime()
            this.$router.push({ path: "/" })
          }.bind(this), 1200)
        }
        if (!response.valid && !response.out) {
          this.masterKey.isLoading = false
          this.masterKey.errorMessage = `${this.$t('components.verify_admin.text10')}`
        }
        if (!response.valid && response.out) {
          this.masterKey.isLoading = false
          this.masterKey.errorMessage = ``
          setTimeout(function () {
            localStorage.clear();
            location.href = "/";
          }.bind(this), 1200)
        }

        localStorage.valid = (new Date()).getTime()
      }).catch((error) => {
        this.masterKey.isLoading = false
        this.masterKey.errorMessage = `${this.$t('components.verify_admin.text10')}`
        console.log(error);

      })
    }
  }
}
</script>