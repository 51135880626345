<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h2 class="intro-y text-lg font-medium mt-10">{{ $t('views.activities.text1') }}</h2>
      <button type="button" class="btn btn-primary w-24 h-10" @click="newActivity"><span>{{ $t('views.new')
          }}</span></button>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-12">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <p class="mr-auto">{{ $t('views.activities.text2') }}</p>
      </div>

      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <div class="w-full grid sm:grid-cols-2 lg:grid-cols-6 xl:grid-cols-4 gap-6 mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-full relative lg:col-span-2 xl:col-span-1 text-gray-700 dark:text-gray-300">
            <input type="text" class="form-control w-full box pr-10 placeholder-theme-13"
              :placeholder="$t('views.activities.text4')" v-model="searchInput" />
            <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
          </div>

          <div class="w-full relative lg:col-span-2 xl:col-span-1 text-gray-700 dark:text-gray-300">
            <TomSelect class="w-full cursor energy_levels" v-model="energy_levels"
              :placeholder="$t('views.activities.text3')">
              <option :value="''">{{ $t('views.activities.text3') }}</option>
              <option v-for="n in 5" :key="n" :value="n">{{ $t('views.energy.' + n) }}</option>
            </TomSelect>
          </div>

          <div class="w-full relative lg:col-span-2 xl:col-span-1 text-gray-700 dark:text-gray-300">
            <TomSelect class="w-full cursor moments" v-model="search_moments"
              :placeholder="$t('views.activities.text5')">
              <option :value="''">{{ $t('views.activities.text5') }}</option>
              <option v-for="(moment, key) in moments" :key="key" :value="moment">{{ $t('views.moments.' + moment) }}
              </option>
            </TomSelect>
          </div>

          <div class="w-full lg:col-span-3 xl:col-span-1 relative text-gray-700 dark:text-gray-300">
            <TomSelect class="w-full cursor weather" v-model="search_weathers"
              :placeholder="$t('views.activities.text6')">
              <option :value="''">{{ $t('views.activities.text6') }}</option>
              <option v-for="(weather, key) in weathers" :key="key" :value="weather">{{ $t('views.weathers.' + weather) }}
              </option>
            </TomSelect>
          </div>

          <div class="w-full sm:col-span-2 lg:col-span-3 xl:col-span-4 relative text-gray-700 dark:text-gray-300">
            <TomSelect class="w-full cursor moods" v-model="search_moods" :placeholder="$t('views.activities.text7')">
              <option value="0">{{ $t('views.activities.text7') }}</option>
              <option v-for="(mood, key) in moods" :key="key" :value="mood.id">{{ mood.value }}</option>
            </TomSelect>
          </div>

        </div>
      </div>


      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">
                <div class="grid activities-grid">
                  <div>{{ $t('views.activities.text4') }}</div>
                  <div>{{ $t('views.activities.text8') }}</div>
                </div>
              </th>
              <th class="text-center whitespace-nowrap">{{ $t('views.activities.text12') }}</th>
            </tr>
          </thead>
          <tbody class="">
            <template v-for="(item, itemKey) in activities" :key="itemKey">
              <tr class="intro-x">
                <td>
                  <div class="grid activities-grid cursor-pointer" @click="item.show = !item.show">
                    <div v-bind:class="item.show ? 'font-semibold' : ''">{{ item.name }}</div>
                    <div v-if="!item.show" class="flex flex-wrap">
                      <div>{{ item.langs[lang_key].benefits }}</div>
                    </div>
                  </div>
                  <div class="w-full intro-x mt-5" v-if="item.show">
                    <div>{{ item.langs[lang_key].benefits }}</div>
                    <div class="mt-2">
                      <h2 class="dark:text-white font-medium inline">{{ $t('views.activities.text3') }}: </h2>
                      <span>{{ $t('views.energy.' + item.energy_level) }}</span>
                    </div>
                    <div class="mt-2">
                      <h2 class="dark:text-white font-medium inline">{{ $t('views.activities.text5') }}: </h2>
                      <div class="inline-flex">
                        <template v-for="(item, itemKey) in item.healthy_activity_moments" :key="itemKey">
                          <span class="attribute-item">{{ $t('views.moments.' + item.moment) }}</span>
                        </template>
                      </div>
                    </div>
                    <div class="mt-2">
                      <h2 class="dark:text-white font-medium inline">{{ $t('views.activities.text6') }}: </h2>
                      <div class="inline-flex">
                        <template v-for="(item, itemKey) in item.healthy_activity_weathers" :key="itemKey">
                          <span class="attribute-item">{{ $t('views.weathers.' + item.weather) }}</span>
                        </template>
                      </div>
                    </div>
                    <div class="mt-2" v-if="moods.length > 0">
                      <h2 class="dark:text-white font-medium inline">{{ $t('views.activities.text7') }}: </h2>
                      <div class="inline-flex">
                        <template v-for="(mood, itemKey) in item.healthy_activity_moods" :key="itemKey">
                          <span class="attribute-item">{{ getMood(mood.mood_id) }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="table-report__action w-56 relative p-5" style="width:25px">
                  <div class="flex justify-center items-center">
                    <a class="flex items-center mr-3" href="javascript:;" @click="editActivity(item)">
                      <CheckSquareIcon class="w-4 h-4 mr-1" />
                      {{ $t('views.edit') }}
                    </a>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-if="loading">
              <td colspan="4" class="p-4">
                {{ $t("views.loading") }}
                <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
              </td>
            </tr>
            <tr v-if="!loading && activities.length === 0">
              <td colspan="4" class="p-4">
                {{ $t("views.no_data") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
        v-if="activities.length > 0">
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('prev')">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('first')"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('first') }" href="javascript:;"
              @click="goPage(getNumberPgae('first'))">{{ getNumberPgae("first") }}</a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('second')"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('second') }"
              href="javascript:;" @click="goPage(getNumberPgae('second'))">{{ getNumberPgae("second") }}</a>
          </li>
          <li v-if="getNumberPgae('second') != getNumberPgae('') && pagination.total_pages >= getNumberPgae('')">
            <a class="pagination__link"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('') }" href="javascript:;"
              @click="goPage(getNumberPgae(''))">{{ getNumberPgae("") }}</a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('next')">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(pagination.total_pages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div class="ml-auto mr-5 text-gray-600">
          {{ $t('views.pagination.items', { total: activities.length }) }}
        </div>
        <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagination.per_page"
          @change="() => { changePerPage() }">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<style>
.activities-grid {
  grid-template-columns: 0.25fr 0.75fr;
  grid-gap: 10px;
}

.attribute-item {
  margin-right: 0.3rem;
  display: flex;
}

.attribute-item::after {
  content: ", ";
}

.attribute-item:last-child::after {
  content: "";
}

.items.ts-input.not-full {
  position: relative;
}

.items.ts-input.not-full:not(.has-items)::after {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
}

.moments .items.ts-input.not-full:not(.has-items)::after {
  content: "Momento del dia";
}

.weather .items.ts-input.not-full:not(.has-items)::after {
  content: "Clima";
}

.moods .items.ts-input.not-full:not(.has-items)::after {
  content: "Estado anímico";
}

.energy_levels .items.ts-input.not-full:not(.has-items)::after {
  content: "Nivel de energía";
}
</style>

<script>
import ActivitiesService from "/src/services/activities.service";

export default {
  setup() {
  },
  data() {
    return {
      activities: [],
      moods: [],
      searchInput: "",
      quizToSearch: 0,
      pagination: {
        page: 1,
        total_pages: 3,
        per_page: 50,
        total: 0
      },
      bg: 1,
      loading: true,
      lang_key: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      weathers: this.$global.getWeathers(),
      moments: this.$global.getMoments(),
      energy_levels: [],
      search_moments: [],
      search_moods: [],
      search_weathers: []
    }
  },
  watch: {
    searchInput: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getAll();
    }, 500),
    '$i18n.locale': function (l) {
      this.getAll();
    },
    search_moments: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getAll();
    }, 500),
    search_moods: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getAll();
    }, 500),
    search_weathers: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getAll();
    }, 500),
    energy_levels: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getAll();
    }, 500)

  },
  created() {
    window.vue = this
    this.getAll();
    this.getAllMoods();
  },
  methods: {
    getAll() {
      var params = {
        pagination: this.pagination
      }
      if (this.searchInput != "") {
        params.search = this.searchInput.replaceAll(" ", "_")
      }

      if (this.search_moments.length > 0) {
        params.moments = this.search_moments
      }

      if (this.search_moods.length > 0) {
        params.mood_ids = this.search_moods
      }

      if (this.search_weathers.length > 0) {
        params.weathers = this.search_weathers
      }

      if (this.energy_levels.length > 0) {
        params.energy_levels = this.energy_levels
      }

      var _this = this
      ActivitiesService.getAll(params).then((response) => {
        this.loading = false
        this.activities = (response.data) ? response.data : response
        this.activities = this.activities.map(s => {
          s.show = false
          const a_langs = []

          for (const l in s.healthy_activity_langs) {
            const lang = s.healthy_activity_langs[l]
            a_langs[lang.lang] = lang
          }
          s.langs = a_langs

          return s
        })

        this.pagination.total_pages = (response.pagination.total_pages) ? response.pagination.total_pages : 0
        this.pagination.total = (response.pagination.total) ? response.pagination.total : response.data.length
      }).catch(function (e) {
        _this.loading = false
      });
    },
    getAllMoods() {
      ActivitiesService.getAllMoods(this.lang_key).then((response) => {
        this.moods = (response.data) ? response.data : response
        function sortName(a, b) {
          if (a.value < b.value) return -1
          if (a.value > b.value) return 1;
          return 0;
        }

        this.moods.sort(sortName)

      })
    },
    getMood(id) {
      const mood = this.moods.find(m => m.id == id)
      return mood ? mood.value : ''
    },
    editActivity(activityItem) {
      localStorage.setItem('activityItem', JSON.stringify(activityItem));
      this.$router.push({ path: '/activity-edit' });
    },
    newActivity() {
      localStorage.removeItem('activityItem');
      this.$router.push({ path: '/activity-new' });
    },
    goPage: function (param) {
      if (param == 'prev') {
        this.pagination.page = (this.pagination.page > 1) ? this.pagination.page - 1 : 1
      } else if (param == 'next') {
        this.pagination.page = (this.pagination.page < this.pagination.total_pages) ? this.pagination.page + 1 : this.pagination.total_pages
      } else {
        this.pagination.page = param
      }
      this.getAll();
    },
    getNumberPgae(type) {
      if (type == "first") {
        return (this.pagination.page < 3) ? 1 : this.pagination.page - 1
      } else if (type == "second") {
        return (this.pagination.page > 2) ? this.pagination.page : 2
      } else {
        return (this.pagination.page > 2) ? (this.pagination.page + 1 > this.pagination.total_pages) ? this.pagination.total_pages : this.pagination.page + 1 : 3
      }
    },
    changePerPage() {
      this.pagination.page = 1
      this.pagination.per_page = Number(this.pagination.per_page)
      this.getAll()
    }
  }
}
</script>