<template>
  <div>
    <div id="save-food-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t("views.food.text11") }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t("views.food.text12") }}: {{ lang_inputs[global_lang].name }}
      </h2>
    </div>
    <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="lang__tabs nav nav-tabs flex-col sm:flex-row text-gray-600 rounded-b-none" role="tablist">
          <a v-for="(lang, key) in langs" :key="key" data-toggle="tab" @click="changeLang('lang__' + lang.key)"
            :data-target="'#lang__' + lang.key" href="javascript:;"
            class="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center rounded-t-md"
            :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''" :id="'lang__' + lang.key + '-tab'" role="tab"
            :aria-controls="'lang__' + lang.key" aria-selected="false"> {{ $t('components.langs.' + lang.key + '.name')
            }}
          </a>
        </div>

        <div v-for="(lang, key) in langs" :key="key" :class="'post__lang__' + lang.key" class="tab-content">
          <div :id="'lang__' + lang.key" class="tab-pane " :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''"
            role="tabpanel" :aria-labelledby="'lang__' + lang.key + '-tab'">

            <!-- BEGIN: Form Layout -->
            <div class="intro-y box p-5 rounded-tl-none">
              <div>
                <label :for="'crud-form-1-' + lang_key" class="form-label">
                  {{ $t('views.food.text13') }}
                </label>
                <div class="input-group">
                  <input :id="'crud-form-1-' + lang_key" type="text" name="chart-tooltip" class="form-control w-full"
                    :placeholder="$t('views.food.text13')" v-model="lang_inputs[lang_key].name" />
                </div>
                <div v-if="validations.name" class="text-theme-6 mt-2">
                  {{ $t("views.food.text14") }}
                </div>
              </div>
              <div class="mt-3">
                <label for="crud-form-3" class="form-label">{{ $t("views.food.text16") }}</label>
                <div class="input-group">
                  <TomSelect ref="category" class="w-full cursor" v-model="foodCategories"
                    :placeholder="$t('views.food.text17')" :plugins="['dropdown_input']">
                    <option value="0">{{ $t("views.food.text17") }}</option>
                    <option v-for="(cat, key) in categories" :key="key" :value="cat.id">{{ cat.name }}</option>
                  </TomSelect>
                </div>
                <div v-if="validations.category" class="text-theme-6 mt-2">
                  {{ $t("views.food.text14") }}
                </div>
              </div>
              <Nutrients :food="food" />
              <div class="text-right mt-5">
                <button v-on:click="saveFood" type="button" class="btn btn-primary w-24">
                  <LoadingIcon v-if="loading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.food.text20') }}</span>
                </button>
              </div>
            </div>
            <!-- END: Form Layout -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dark .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  --tw-text-opacity: 1;
  color: #fff;
}

.mt-3 {
  color: #fff;
}

.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}

.max-w-label {
  max-width: 90%;
}

.dark .form-check-input[type=checkbox] {
  background-color: #182033 !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.dark .nutrient {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dark .nutrient .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.dark .nutrient .form-check-label {
  border-color: #6c869a;
}

.dark .nutrient:hover input~.form-check-label {
  background-color: rgba(108, 134, 154, 0.5);
}

/* When the checkbox is checked, add a blue background */
.form-check-input[type=checkbox]:checked {
  background-color: #6c869a;
  border-color: #6c869a;
  color: white;
}

.dark .nutrient input:checked~.form-check-label {
  background-color: #6c869a;
  color: white;
}

.form-check-input[type=radio]:checked {
  border-color: #6c869a !important;
}

.form-check-input[type=radio]:before {
  background-color: #6c869a !important;
}

.dark .form-check-input[type=radio]:checked {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.dark .form-check-input[type=radio]:before {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}
</style>
<style>
.receipe-item:last-child .remove-item {
  visibility: hidden;
}
</style>
<script>
import { TokenService } from '/src/services/storage.service'
import Toastify from "toastify-js";
import AlertMessage from "@/components/alert/Main.vue"
import FoodService from "/src/services/food.service";
import Nutrients from "@/components/nutrition/Main.vue"

export default {
  components: {
    AlertMessage,
    Nutrients
  },
  setup() { },
  data() {
    return {
      activeLang: 'lang__' + localStorage.lang,
      langs: this.$global.getLangs(),
      lang_key: localStorage.lang,
      food: (localStorage.foodItem) ? JSON.parse(localStorage.foodItem) : {},
      validations: {
        name: false,
        description: false,
        category: false,
      },
      lang_inputs: [],
      global_lang: localStorage.lang,
      categories: [],
      foodCategories: [],
      nutritional_content: this.$global.getNutritionalContents(),
      show_nutritional_content: {},
      loading: false,
    };
  },
  watch: {
    '$i18n.locale': function (l) {
      this.getCategories();
    },
    activeLang: function () {
      this.lang_key = this.activeLang.replace("lang__", "")

    }
  },
  computed: {

  },
  created() {
    this.lang_inputs = this.langs.map(l => l.key).reduce((x, y) => (x[y] = { name: "" }, x), {});
    this.lang_inputs[this.lang_key]["name"] = this.food["name"]
    this.show_nutritional_content = this.nutritional_content

    this.getFood();
    this.getCategories();
  },
  mounted() {

  },
  methods: {
    getFood: function () {
      var food = this.food;
      if (food.id) {
        var _this = this
        Object.keys(this.lang_inputs).map(function (lang) {
          FoodService.getFood(food.id, lang).then((response) => {
            _this.food = (response.data) ? response.data : response;
            _this.foodCategories = _this.food.categories
            Object.keys(_this.lang_inputs[lang]).map(function (key) {
              _this.lang_inputs[lang][key] = _this.food[key]
            })
          });
        });
      } else {
        this.food.nutritional_content = this.nutritional_content
      }
    },
    getCategories: function (lang = null) {
      FoodService.getCategories(lang).then((response) => {
        this.categories = (response.data) ? response.data : response
      });
    },
    saveFood() {
      this.validations.name = (this.food.name == "")

      if ((
        this.validations.name
      )) {
        return
      } else {
        this.loading = true
        this.food.categories = this.foodCategories

        Object.keys(this.food.nutritional_content).map(function (key, index) {
          this.food.nutritional_content[key] = (this.food.nutritional_content[key] === "") ? null : this.food.nutritional_content[key];
        }.bind(this));

        Object.keys(this.lang_inputs).map(function (lang) {
          Object.keys(this.lang_inputs[lang]).map(function (key) {
            this.food[key] = this.lang_inputs[lang][key]
          }.bind(this))

          if (this.food.id) {
            FoodService.saveFood(this.food, lang).then((serverResponse) => {
              this.loading = false
              this.saveSuccessFood()
            }).catch((error) => {
              this.loading = false
            })
          } else {
            FoodService.createFood(this.food, lang).then((serverResponse) => {
              this.loading = false
              this.saveSuccessFood()
            }).catch((error) => {
              this.loading = false
            })
          }
        }.bind(this))


      }
    },
    saveSuccessFood() {
      Toastify({
        node: cash('#save-food-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('foodItem');
      this.$router.push({ path: '/food-list' });
    },

    changeLang(l) {
      this.activeLang = l
    }
  },
};

</script>
<style></style>
