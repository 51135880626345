import ApiService from "./api.service"

/**
 *FoodService --> es un objeto el cual tiene las funciones de alimentos
 *
 * getFoodItems --> función que a través de una petición ._GET al enterpoit, solicita una lista de alimentos basados en los parámetros y el idioma que se pasó
 * retorna la lista de alimentos
 *
 * getFood --> función que a través de una petición GET al enterpoint, solicita un alimento específico con el id
 *  retorna el alimento
 *
 * getCategories --> función que a través de una petición GET al enterpoit, solicita la lista de alimentos según las categorías
 * retorna la lista
 *
 * getCategoriesTree --> igual?
 *
 * createFood --> función que a través de una petición POST al enterpoint, crea un nuevo alimento con los params mandados
 * retorna el alimento creado
 *
 * saveFood --> función que a través de una petición POST al enterpoint, solicita guardar o actualizar información con los datos que obtiene de los params
 * retorna el alimento actualizado
 */

const FoodService = {
  getFoodItems: async function(params, lang) {
    const response = await ApiService._get("/api/admin/food", params, lang)
    return response.data
  },

  getFood: async function(id, lang = null) {
    const response = await ApiService.get("/api/admin/food/" + id, lang)
    return response.data
  },

  getCategories: async function(lang = null) {
    const response = await ApiService.get("/api/admin/food/categories", lang)
    return response.data
  },

  getCategoriesTree: async function(lang = null) {
    const response = await ApiService.get(
      "/api/admin/food/categories-tree",
      lang
    )
    return response.data
  },

  createFood: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/food/create",
      params,
      lang
    )
    return response.data
  },

  saveFood: async function(params, lang = null) {
    const response = await ApiService.post("/api/admin/food/save", params, lang)
    return response.data
  }
}

export default FoodService
