import ApiService from "./api.service"

/**
 *MedicationService es un objeto que tiene las funciones de medicamentos
 *
 * getAll --> función que a través de una petición ._GET se accede a un enterpoint específico y solicita un listado de medicamentos con una parametros
 * retorna la lista medicamentos
 *
 * getDrug --> función que a través de una petición GET se accede a un enterpoint específico y solicita la droga de un medicamento el cual lo llama con el id
 * retorna la respuesta
 *
 * save --> función que a través de una petición POST se accede a un enterpoint específico y solicita crear o actualizar un medicamento con la info obtenida de los params
 * retorna la respuesta
 *
 * remove -->función que a través de una petición GET se accede a un enterpoint específico y solicita eliminar un medicamento específico
 * retorna un mensaje de exito o error
 *
 * getAllFamilies --> función que a través de una petición GET se accede a un enterpoint específico y solicita un listado de familia de medicamentos
 * retorna la lista de familia de medicamentos
 *
 * saveFamily --> función que a través de una petición POST se accede a un enterpoint específico y solicita crear o actualizar una familia de medicamentos
 * retorna la familia de medicamentos
 *
 * removeFamily --> función que a través de una petición GET se accede a un enterpoint específico y solicita eliminar una familia de medicamentos específica
 * retorna la familia de medicamentos eliminada o un mensaje.
 *
 * addMedicineSupplement --> función que a través de una petición POST se accede a un enterpoint específico y solicita crear o actualizar una interacción entre la familia de medicamentos y un suplemento
 * retorna la respuesta de la interacción.
 *
 * getMedicineSupplement --> función que a través de una petición ._GET se accede a un enterpoint específico y solicita obtener la información entre una interacción de la familia de medicamentos y un suplemento
 * retorna la interacción.
 *
 * deleteMedicineSupplement --> función que a través de una petición ._GET se accede a un enterpoint específico y solicita eliminar la información entre una interacción de la familia de medicamentos y un suplemento
 * retorna la respuesta de esa petición.
 *  */

const MedicationService = {
  getAll: async function(params) {
    const response = await ApiService._get(
      "/api/admin/medication/get-drug",
      params
    )
    return response.data
  },

  getDrug: async function(id, lang = null) {
    const response = await ApiService.get(
      "/api/admin/medication/get-drug?id=" + id,
      lang
    )
    return response.data
  },

  save: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/medication/save-drug",
      params,
      lang
    )
    return response.data
  },

  remove: async function(id, lang = null) {
    const response = await ApiService.get(
      "/api/admin/medication/delete-drug?id=" + id,
      lang
    )
    return response.data
  },

  getAllFamilies: async function() {
    const response = await ApiService.get(
      "/api/admin/medication/get-medication-class"
    )
    return response.data
  },

  saveFamily: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/medication/save-medication-class",
      params,
      lang
    )
    return response.data
  },

  removeFamily: async function(id, lang = null) {
    const response = await ApiService.get(
      "/api/admin/medication/delete-medication-class?id=" + id,
      lang
    )
    return response.data
  },

  addMedicineSupplement: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/medication/save-supplement-interaction",
      params,
      lang
    )
    return response.data
  },

  getMedicineSupplement: async function(params, lang = null) {
    const response = await ApiService._get(
      "/api/admin/medication/get-supplement-interaction",
      params,
      lang
    )
    return response.data
  },

  deleteMedicineSupplement: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/medication/delete-supplement-interaction",
      params,
      lang
    )
    return response.data
  }
}

export default MedicationService
