<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h2 class="intro-y text-lg font-medium mt-10">{{ $t('views.disease.text1') }}</h2>
      <button type="button" class="btn btn-primary w-24 h-10" @click="newDiseaseItem"><span>{{ $t('views.new')
          }}</span></button>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-12">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <p class="mr-auto">{{ $t('views.disease.text2') }}</p>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13"
              :placeholder="$t('views.disease.text4')" v-model="searchInput" />
            <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">
                <div class="grid diseases-grid">
                  <div>{{ $t('views.disease.text4') }}</div>
                  <div>{{ $t('views.disease.text5') }}</div>
                </div>
              </th>
              <th class="text-center whitespace-nowrap">{{ $t('views.disease.text6') }}</th>
            </tr>
          </thead>
          <tbody class="">
            <template v-for="(item, itemKey) in diseases" :key="itemKey">
              <tr class="intro-x">
                <td>
                  <div class="grid diseases-grid cursor-pointer" @click="item.show = !item.show">
                    <div>{{ item.name }}</div>
                    <div v-if="!item.show" class="flex flex-wrap">
                      <div>{{ item.description }}</div>
                    </div>
                  </div>
                  <div class="w-full intro-x mt-5" v-if="item.show">
                    <h2 v-if="item.description" class="dark:text-white font-medium">{{ $t('views.disease.text5') }}:
                    </h2>
                    <div>{{ item.description }}</div>
                  </div>
                </td>
                <td class="table-report__action w-56 relative p-5" style="width:25px">
                  <div class="flex justify-center items-center">
                    <a class="flex items-center mr-3" href="javascript:;" @click="editDiseaseItem(item)">
                      <CheckSquareIcon class="w-4 h-4 mr-1" />
                      {{ $t('views.edit') }}
                    </a>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-if="loading">
              <td colspan="4" class="p-4">
                {{ $t("views.loading") }}
                <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
              </td>
            </tr>
            <tr v-if="!loading && diseases.length === 0">
              <td colspan="4" class="p-4">
                {{ $t("views.no_data") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
        v-if="diseases.length > 0">
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('prev')">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('first')"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('first') }" href="javascript:;"
              @click="goPage(getNumberPgae('first'))">{{ getNumberPgae("first") }}</a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('second')"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('second') }"
              href="javascript:;" @click="goPage(getNumberPgae('second'))">{{ getNumberPgae("second") }}</a>
          </li>
          <li v-if="getNumberPgae('second') != getNumberPgae('') && pagination.total_pages >= getNumberPgae('')">
            <a class="pagination__link"
              v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('') }" href="javascript:;"
              @click="goPage(getNumberPgae(''))">{{ getNumberPgae("") }}</a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('next')">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(pagination.total_pages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div class="ml-auto mr-5 text-gray-600">
          {{ $t('views.pagination.items', { total: diseases.length }) }}
        </div>
        <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagination.per_page"
          @change="() => { changePerPage() }">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<style scoped>
.cursor {
  cursor: pointer !important;
}

.table td.p-0 {
  padding: 0px !important;
}

.bg-color-1 {
  background-color: #828181;
}

.btn-primary {
  background: #6c869a !important;
  border-color: #6c869a !important;
}
</style>

<style>
.tom-select .ts-dropdown .option.active {
  background: #6c869a !important;
}

.top-bar .breadcrumb>a:not(.breadcrumb--active) {
  color: #6c869a !important;
}

.detail-food {
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: initial;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pagination li,
.pagination a,
.pagination a:focus,
.pagination a:active {
  outline: none !important;
}

.diseases-grid {
  grid-template-columns: 150px 200px 150px 150px 150px;
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 1450px) {
  .diseases-grid {
    grid-template-columns: 12vw auto 15vw 12vw 12vw;
  }
}
</style>

<script>
import DiseaseService from "/src/services/diseases.service";
import _ from 'lodash';

export default {
  setup() {
  },
  data() {
    return {
      diseases: [],
      searchInput: "",
      quizToSearch: 0,
      pagination: {
        page: 1,
        total_pages: 3,
        per_page: 50,
        total: 0
      },
      bg: 1,
      loading: true,
      brands: [],
    }
  },
  watch: {
    searchInput: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getAll();
    }, 500),
    '$i18n.locale': function (l) {
      this.getAll();
    }
  },
  created() {
    window.vue = this
    this.getAll();
  },
  methods: {
    getAll() {
      var params = {
        pagination: this.pagination
      }
      if (this.searchInput != "") {
        params.search = this.searchInput.replaceAll(" ", "_")
      }

      var _this = this
      DiseaseService.getAll(params).then((response) => {
        this.loading = false
        this.diseases = (response.data) ? response.data : response
        this.diseases = this.diseases.map(s => {
          s.show = false
          return s
        })

        this.pagination.total_pages = (response.pagination.total_pages) ? response.pagination.total_pages : 0
        this.pagination.total = (response.pagination.total) ? response.pagination.total : response.data.length
      }).catch(function (e) {
        _this.loading = false
      });
    },
    editDiseaseItem(diseaseItem) {
      localStorage.setItem('diseaseItem', JSON.stringify(diseaseItem));
      this.$router.push({ path: '/disease-edit' });
    },
    newDiseaseItem(diseaseItem) {
      localStorage.removeItem('diseaseItem');
      this.$router.push({ path: '/disease-new' });
    },
    goPage: function (param) {
      if (param == 'prev') {
        this.pagination.page = (this.pagination.page > 1) ? this.pagination.page - 1 : 1
      } else if (param == 'next') {
        this.pagination.page = (this.pagination.page < this.pagination.total_pages) ? this.pagination.page + 1 : this.pagination.total_pages
      } else {
        this.pagination.page = param
      }
      this.getAll();
    },
    getNumberPgae(type) {
      if (type == "first") {
        return (this.pagination.page < 3) ? 1 : this.pagination.page - 1
      } else if (type == "second") {
        return (this.pagination.page > 2) ? this.pagination.page : 2
      } else {
        return (this.pagination.page > 2) ? (this.pagination.page + 1 > this.pagination.total_pages) ? this.pagination.total_pages : this.pagination.page + 1 : 3
      }
    },
    changePerPage() {
      this.pagination.page = 1
      this.pagination.per_page = Number(this.pagination.per_page)
      this.getAll()
    }
  }
}
</script>
