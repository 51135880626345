<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <button v-if="type === 'button'" type="button"
    class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-dark-3 rounded-md w-full"
    @click="switchMode">
    <SunIcon v-if="darkMode" class="w-4 h-4 mr-2" />
    <MoonIcon v-else class="w-4 h-4 mr-2" />
    {{ (!darkMode) ? $t('components.dark_mode') : $t('components.light_mode') }}
  </button>
  <div v-else
    class="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box dark:bg-dark-2 border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-10"
    @click="switchMode">
    <div class="mr-4 text-gray-700 dark:text-gray-300">{{ $t('components.dark_mode') }}</div>
    <div :class="{ 'dark-mode-switcher__toggle--active': darkMode }" class="dark-mode-switcher__toggle border"></div>
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  props: ["type"],
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)

    const setDarkModeClass = () => {
      darkMode.value
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    }



    const switchMode = () => {
      store.dispatch('main/setDarkMode', !darkMode.value)
      setDarkModeClass()
    }

    onMounted(() => {
      setDarkModeClass()
    })

    return {
      switchMode,
      darkMode
    }
  }
})
</script>
