const countries = [
  { lang: "es-AR", name: "Argentina", flag: "https://flagcdn.com/ar.svg" },
  { lang: "es-BO", name: "Bolivia", flag: "https://flagcdn.com/bo.svg" },
  { lang: "es-CL", name: "Chile", flag: "https://flagcdn.com/cl.svg" },
  { lang: "es-CO", name: "Colombia", flag: "https://flagcdn.com/co.svg" },
  { lang: "es-CR", name: "Costa Rica", flag: "https://flagcdn.com/cr.svg" },
  { lang: "es-CU", name: "Cuba", flag: "https://flagcdn.com/cu.svg" },
  {
    lang: "es-DO",
    name: "República Dominicana",
    flag: "https://flagcdn.com/do.svg"
  },
  { lang: "es-EC", name: "Ecuador", flag: "https://flagcdn.com/ec.svg" },
  { lang: "es-SV", name: "El Salvador", flag: "https://flagcdn.com/sv.svg" },
  {
    lang: "es-GQ",
    name: "Guinea Ecuatorial",
    flag: "https://flagcdn.com/gq.svg"
  },
  { lang: "es-GT", name: "Guatemala", flag: "https://flagcdn.com/gt.svg" },
  { lang: "es-HN", name: "Honduras", flag: "https://flagcdn.com/hn.svg" },
  { lang: "es-MX", name: "México", flag: "https://flagcdn.com/mx.svg" },
  { lang: "es-NI", name: "Nicaragua", flag: "https://flagcdn.com/ni.svg" },
  { lang: "es-PA", name: "Panamá", flag: "https://flagcdn.com/pa.svg" },
  { lang: "es-PY", name: "Paraguay", flag: "https://flagcdn.com/py.svg" },
  { lang: "es-PE", name: "Perú", flag: "https://flagcdn.com/pe.svg" },
  { lang: "es-PR", name: "Puerto Rico", flag: "https://flagcdn.com/pr.svg" },
  { lang: "es-ES", name: "España", flag: "https://flagcdn.com/es.svg" },
  { lang: "es-UY", name: "Uruguay", flag: "https://flagcdn.com/uy.svg" },
  { lang: "es-VE", name: "Venezuela", flag: "https://flagcdn.com/ve.svg" },
  {
    lang: "es-EH",
    name: "Sahara Occidental",
    flag: "https://flagcdn.com/eh.svg"
  },
  {
    lang: "en-AS",
    name: "Samoa Americana",
    flag: "https://flagcdn.com/as.svg"
  },
  { lang: "en-AI", name: "Anguilla", flag: "https://flagcdn.com/ai.svg" },
  { lang: "en-AQ", name: "Antártida", flag: "https://flagcdn.com/aq.svg" },
  {
    lang: "en-AG",
    name: "Antigua y Barbuda",
    flag: "https://flagcdn.com/ag.svg"
  },
  { lang: "en-AU", name: "Australia", flag: "https://flagcdn.com/au.svg" },
  { lang: "en-BS", name: "Las Bahamas", flag: "https://flagcdn.com/bs.svg" },
  { lang: "en-BB", name: "Barbados", flag: "https://flagcdn.com/bb.svg" },
  { lang: "en-BZ", name: "Belice", flag: "https://flagcdn.com/bz.svg" },
  { lang: "en-BM", name: "Islas Bermudas", flag: "https://flagcdn.com/bm.svg" },
  { lang: "en-BW", name: "Botswana", flag: "https://flagcdn.com/bw.svg" },
  {
    lang: "en-IO",
    name: "Territorio Británico del Océano Índico",
    flag: "https://flagcdn.com/io.svg"
  },
  { lang: "en-CM", name: "Camerún", flag: "https://flagcdn.com/cm.svg" },
  { lang: "en-CA", name: "Canadá", flag: "https://flagcdn.com/ca.svg" },
  { lang: "en-KY", name: "Islas Caimán", flag: "https://flagcdn.com/ky.svg" },
  {
    lang: "en-CX",
    name: "Isla de Navidad",
    flag: "https://flagcdn.com/cx.svg"
  },
  {
    lang: "en-CC",
    name: "Cocos (Keeling)",
    flag: "https://flagcdn.com/cc.svg"
  },
  { lang: "en-CK", name: "Islas Cook", flag: "https://flagcdn.com/ck.svg" },
  { lang: "en-DM", name: "Dominica", flag: "https://flagcdn.com/dm.svg" },
  { lang: "en-FK", name: "Islas Malvinas", flag: "https://flagcdn.com/fk.svg" },
  { lang: "en-FJ", name: "Islas Fiji", flag: "https://flagcdn.com/fj.svg" },
  { lang: "en-GM", name: "El Gambia", flag: "https://flagcdn.com/gm.svg" },
  { lang: "en-GH", name: "Ghana", flag: "https://flagcdn.com/gh.svg" },
  { lang: "en-GI", name: "Gibraltar", flag: "https://flagcdn.com/gi.svg" },
  { lang: "en-GD", name: "Granada", flag: "https://flagcdn.com/gd.svg" },
  { lang: "en-GU", name: "Guam", flag: "https://flagcdn.com/gu.svg" },
  { lang: "en-GY", name: "Guayana", flag: "https://flagcdn.com/gy.svg" },
  {
    lang: "en-HM",
    name: "Islas Heard y McDonald",
    flag: "https://flagcdn.com/hm.svg"
  },
  {
    lang: "en-HK",
    name: "Hong Kong S.A.R.",
    flag: "https://flagcdn.com/hk.svg"
  },
  { lang: "en-JM", name: "Jamaica", flag: "https://flagcdn.com/jm.svg" },
  { lang: "en-KE", name: "Kenia", flag: "https://flagcdn.com/ke.svg" },
  { lang: "en-KI", name: "Kiribati", flag: "https://flagcdn.com/ki.svg" },
  { lang: "en-LS", name: "Lesoto", flag: "https://flagcdn.com/ls.svg" },
  { lang: "en-LR", name: "Liberia", flag: "https://flagcdn.com/lr.svg" },
  { lang: "en-MW", name: "Malawi", flag: "https://flagcdn.com/mw.svg" },
  { lang: "en-MH", name: "Islas Marshall", flag: "https://flagcdn.com/mh.svg" },
  { lang: "en-MU", name: "Mauricio", flag: "https://flagcdn.com/mu.svg" },
  { lang: "en-MS", name: "Montserrat", flag: "https://flagcdn.com/ms.svg" },
  { lang: "en-NA", name: "Namibia", flag: "https://flagcdn.com/na.svg" },
  { lang: "en-NR", name: "Nauru", flag: "https://flagcdn.com/nr.svg" },
  { lang: "en-NZ", name: "Nueva Zelanda", flag: "https://flagcdn.com/nz.svg" },
  { lang: "en-NG", name: "Nigeria", flag: "https://flagcdn.com/ng.svg" },
  { lang: "en-NU", name: "Niue", flag: "https://flagcdn.com/nu.svg" },
  { lang: "en-NF", name: "Isla Norfolk", flag: "https://flagcdn.com/nf.svg" },
  {
    lang: "en-MP",
    name: "Islas Marianas del Norte",
    flag: "https://flagcdn.com/mp.svg"
  },
  { lang: "en-PK", name: "Pakistán", flag: "https://flagcdn.com/pk.svg" },
  { lang: "en-PW", name: "Palau", flag: "https://flagcdn.com/pw.svg" },
  {
    lang: "en-PG",
    name: "Papúa Nueva Guinea",
    flag: "https://flagcdn.com/pg.svg"
  },
  { lang: "en-PH", name: "Filipinas", flag: "https://flagcdn.com/ph.svg" },
  { lang: "en-PN", name: "Isla Pitcairn", flag: "https://flagcdn.com/pn.svg" },
  { lang: "en-SH", name: "Santa Helena", flag: "https://flagcdn.com/sh.svg" },
  {
    lang: "en-KN",
    name: "San Cristóbal y Nieves",
    flag: "https://flagcdn.com/kn.svg"
  },
  { lang: "en-LC", name: "Santa Lucía", flag: "https://flagcdn.com/lc.svg" },
  {
    lang: "en-VC",
    name: "San Vicente y las Granadinas",
    flag: "https://flagcdn.com/vc.svg"
  },
  { lang: "en-SL", name: "Sierra Leona", flag: "https://flagcdn.com/sl.svg" },
  { lang: "en-SG", name: "Singapur", flag: "https://flagcdn.com/sg.svg" },
  { lang: "en-SB", name: "Islas Salomón", flag: "https://flagcdn.com/sb.svg" },
  {
    lang: "en-GS",
    name: "Georgia del Sur",
    flag: "https://flagcdn.com/gs.svg"
  },
  { lang: "en-SS", name: "Sudán del Sur", flag: "https://flagcdn.com/ss.svg" },
  { lang: "en-SZ", name: "Swazilandia", flag: "https://flagcdn.com/sz.svg" },
  { lang: "en-TK", name: "Tokelau", flag: "https://flagcdn.com/tk.svg" },
  { lang: "en-TO", name: "Tonga", flag: "https://flagcdn.com/to.svg" },
  {
    lang: "en-TT",
    name: "Trinidad y Tobago",
    flag: "https://flagcdn.com/tt.svg"
  },
  { lang: "en-TC", name: "Turks y Caicos", flag: "https://flagcdn.com/tc.svg" },
  { lang: "en-TV", name: "Tuvalu", flag: "https://flagcdn.com/tv.svg" },
  { lang: "en-UG", name: "Uganda", flag: "https://flagcdn.com/ug.svg" },
  { lang: "en-GB", name: "Reino Unido", flag: "https://flagcdn.com/gb.svg" },
  { lang: "en-US", name: "Estados Unidos", flag: "https://flagcdn.com/us.svg" },
  {
    lang: "en-UM",
    name: "Estados Unidos Islas menores alejadas de",
    flag: "https://flagcdn.com/um.svg"
  },
  {
    lang: "en-VI",
    name: "Islas Vírgenes (EEUU)",
    flag: "https://flagcdn.com/vi.svg"
  },
  { lang: "en-ZM", name: "Zambia", flag: "https://flagcdn.com/zm.svg" },
  { lang: "en-ZW", name: "Zimbabue", flag: "https://flagcdn.com/zw.svg" }

  // { lang : "ps-AF", name:	"Afganistán", flag : "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg"},
  // { lang : "sq-AL", name:	"Albania", flag : "https://flagcdn.com/al.svg"},
  // { lang : "ar-DZ", name:	"Argelia", flag : "https://flagcdn.com/dz.svg"},
  // { lang : "ca-AD", name:	"Andorra", flag : "https://flagcdn.com/ad.svg"},
  // { lang : "pt-AO", name:	"Angola", flag : "https://flagcdn.com/ao.svg"},
  // { lang : "hy-AM", name:	"Armenia", flag : "https://flagcdn.com/am.svg"},
  // { lang : "nl-AW", name:	"Aruba", flag : "https://flagcdn.com/aw.svg"},
  // { lang : "de-AT", name:	"Austria", flag : "https://flagcdn.com/at.svg"},
  // { lang : "az-AZ", name:	"Azerbaiyán", flag : "https://flagcdn.com/az.svg"},
  // { lang : "ar-BH", name:	"Bahrein", flag : "https://flagcdn.com/bh.svg"},
  // { lang : "bn-BD", name:	"Bangladesh", flag : "https://flagcdn.com/bd.svg"},
  // { lang : "be-BY", name:	"Belarús", flag : "https://flagcdn.com/by.svg"},
  // { lang : "nl-BE", name:	"Bélgica", flag : "https://flagcdn.com/be.svg"},
  // { lang : "fr-BJ", name:	"Benin", flag : "https://flagcdn.com/bj.svg"},
  // { lang : "dz-BT", name:	"Bhután", flag : "https://flagcdn.com/bt.svg"},
  // { lang : "bs-BA", name:	"Bosnia y Herzegovina", flag : "https://flagcdn.com/ba.svg"},
  // { lang : "no-BV", name:	"Isla Bouvet", flag : "https://flagcdn.com/bv.svg"},
  // { lang : "pt-BR", name:	"Brasil", flag : "https://flagcdn.com/br.svg"},
  // { lang : "ms-BN", name:	"Brunei", flag : "https://flagcdn.com/bn.svg"},
  // { lang : "bg-BG", name:	"Bulgaria", flag : "https://flagcdn.com/bg.svg"},
  // { lang : "fr-BF", name:	"Burkina Faso", flag : "https://flagcdn.com/bf.svg"},
  // { lang : "fr-BI", name:	"Burundi", flag : "https://flagcdn.com/bi.svg"},
  // { lang : "km-KH", name:	"Camboya", flag : "https://flagcdn.com/kh.svg"},
  // { lang : "pt-CV", name:	"Cabo Verde", flag : "https://flagcdn.com/cv.svg"},
  // { lang : "fr-CF", name:	"República Centroafricana", flag : "https://flagcdn.com/cf.svg"},
  // { lang : "fr-TD", name:	"Chad", flag : "https://flagcdn.com/td.svg"},
  // { lang : "zh-CN", name:	"China", flag : "https://flagcdn.com/cn.svg"},
  // { lang : "ar-KM", name:	"Comoras", flag : "https://flagcdn.com/km.svg"},
  // { lang : "fr-CG", name:	"Congo", flag : "https://flagcdn.com/cg.svg"},
  // { lang : "fr-CD", name:	"Congo La República Democrática Del", flag : "https://flagcdn.com/cd.svg"},
  // { lang : "fr-CI", name:	"Costa de Marfil", flag : "https://flagcdn.com/ci.svg"},
  // { lang : "hr-HR", name:	"Croacia (Hrvatska)", flag : "https://flagcdn.com/hr.svg"},
  // { lang : "el-CY", name:	"Chipre", flag : "https://flagcdn.com/cy.svg"},
  // { lang : "cs-CZ", name:	"República Checa", flag : "https://flagcdn.com/cz.svg"},
  // { lang : "da-DK", name:	"Dinamarca", flag : "https://flagcdn.com/dk.svg"},
  // { lang : "fr-DJ", name:	"Djibouti", flag : "https://flagcdn.com/dj.svg"},
  // { lang : "ar-EG", name:	"Egipto", flag : "https://flagcdn.com/eg.svg"},
  // { lang : "ti-ER", name:	"Eritrea", flag : "https://flagcdn.com/er.svg"},
  // { lang : "et-EE", name:	"Estonia", flag : "https://flagcdn.com/ee.svg"},
  // { lang : "am-ET", name:	"Etiopía", flag : "https://flagcdn.com/et.svg"},
  // { lang : "fo-FO", name:	"Islas Faroe", flag : "https://flagcdn.com/fo.svg"},
  // { lang : "fi-FI", name:	"Finlandia", flag : "https://flagcdn.com/fi.svg"},
  // { lang : "fr-FR", name:	"Francia", flag : "https://flagcdn.com/fr.svg"},
  // { lang : "fr-GF", name:	"Guayana francés", flag : "https://flagcdn.com/gf.svg"},
  // { lang : "fr-PF", name:	"Polinesia francés", flag : "https://flagcdn.com/pf.svg"},
  // { lang : "fr-TF", name:	"Territorios Franceses del Sur", flag : "https://flagcdn.com/tf.svg"},
  // { lang : "fr-GA", name:	"Gabón", flag : "https://flagcdn.com/ga.svg"},
  // { lang : "ka-GE", name:	"Georgia", flag : "https://flagcdn.com/ge.svg"},
  // { lang : "de-DE", name:	"Alemania", flag : "https://flagcdn.com/de.svg"},
  // { lang : "el-GR", name:	"Grecia", flag : "https://flagcdn.com/gr.svg"},
  // { lang : "kl-GL", name:	"Tierra Verde", flag : "https://flagcdn.com/gl.svg"},
  // { lang : "fr-GP", name:	"Guadalupe", flag : "https://flagcdn.com/gp.svg"},
  // { lang : "fr-GN", name:	"Guinea", flag : "https://flagcdn.com/gn.svg"},
  // { lang : "pt-GW", name:	"Guinea-Bissau", flag : "https://flagcdn.com/gw.svg"},
  // { lang : "fr-HT", name:	"Haití", flag : "https://flagcdn.com/ht.svg"},
  // { lang : "hu-HU", name:	"Hungría", flag : "https://flagcdn.com/hu.svg"},
  // { lang : "is-IS", name:	"Islandia", flag : "https://flagcdn.com/is.svg"},
  // { lang : "hi-IN", name:	"India", flag : "https://flagcdn.com/in.svg"},
  // { lang : "id-ID", name:	"Indonesia", flag : "https://flagcdn.com/id.svg"},
  // { lang : "fa-IR", name:	"Irán", flag : "https://flagcdn.com/ir.svg"},
  // { lang : "ar-IQ", name:	"Irak", flag : "https://flagcdn.com/iq.svg"},
  // { lang : "ga-IE", name:	"Irlanda", flag : "https://flagcdn.com/ie.svg"},
  // { lang : "he-IL", name:	"Israel", flag : "https://flagcdn.com/il.svg"},
  // { lang : "it-IT", name:	"Italia", flag : "https://flagcdn.com/it.svg"},
  // { lang : "ja-JP", name:	"Japón", flag : "https://flagcdn.com/jp.svg"},
  // { lang : "ar-JO", name:	"Jordán", flag : "https://flagcdn.com/jo.svg"},
  // { lang : "kk-KZ", name:	"Kazajistán", flag : "https://flagcdn.com/kz.svg"},
  // { lang : "ko-KP", name:	"Corea del Norte", flag : "https://flagcdn.com/kp.svg"},
  // { lang : "ko-KR", name:	"Corea del Sur", flag : "https://flagcdn.com/kr.svg"},
  // { lang : "ar-KW", name:	"Kuwait", flag : "https://flagcdn.com/kw.svg"},
  // { lang : "ky-KG", name:	"Kirguistán", flag : "https://flagcdn.com/kg.svg"},
  // { lang : "lo-LA", name:	"Laos", flag : "https://flagcdn.com/la.svg"},
  // { lang : "lv-LV", name:	"Letonia", flag : "https://flagcdn.com/lv.svg"},
  // { lang : "ar-LB", name:	"Líbano", flag : "https://flagcdn.com/lb.svg"},
  // { lang : "ar-LY", name:	"Libia", flag : "https://flagcdn.com/ly.svg"},
  // { lang : "de-LI", name:	"Liechtenstein", flag : "https://flagcdn.com/li.svg"},
  // { lang : "lt-LT", name:	"Lituania", flag : "https://flagcdn.com/lt.svg"},
  // { lang : "fr-LU", name:	"Luxemburgo", flag : "https://flagcdn.com/lu.svg"},
  // { lang : "zh-MO", name:	"Macau S.A.R.", flag : "https://flagcdn.com/mo.svg"},
  // { lang : "mk-MK", name:	"Macedonia", flag : "https://flagcdn.com/mk.svg"},
  // { lang : "fr-MG", name:	"Madagascar", flag : "https://flagcdn.com/mg.svg"},
  // { lang : "dv-MV", name:	"Maldivas", flag : "https://flagcdn.com/mv.svg"},
  // { lang : "fr-ML", name:	"Mali", flag : "https://flagcdn.com/ml.svg"},
  // { lang : "mt-MT", name:	"Malta", flag : "https://flagcdn.com/mt.svg"},
  // { lang : "fr-MQ", name:	"Martinica", flag : "https://flagcdn.com/mq.svg"},
  // { lang : "ar-MR", name:	"Mauritania", flag : "https://flagcdn.com/mr.svg"},
  // { lang : "fr-YT", name:	"Mayotte", flag : "https://flagcdn.com/yt.svg"},
  // { lang : "ro-MD", name:	"Moldavia", flag : "https://flagcdn.com/md.svg"},
  // { lang : "fr-MC", name:	"Mónaco", flag : "https://flagcdn.com/mc.svg"},
  // { lang : "mn-MN", name:	"Mongolia", flag : "https://flagcdn.com/mn.svg"},
  // { lang : "ar-MA", name:	"Marruecos", flag : "https://flagcdn.com/ma.svg"},
  // { lang : "pt-MZ", name:	"Mozambique", flag : "https://flagcdn.com/mz.svg"},
  // { lang : "my-MM", name:	"Myanmar", flag : "https://flagcdn.com/mm.svg"},
  // { lang : "ne-NP", name:	"Nepal", flag : "https://flagcdn.com/np.svg"},
  // { lang : "nl-NL", name:	"Holanda", flag : "https://flagcdn.com/nl.svg"},
  // { lang : "fr-NC", name:	"Nueva Caledonia", flag : "https://flagcdn.com/nc.svg"},
  // { lang : "fr-NE", name:	"Níger", flag : "https://flagcdn.com/ne.svg"},
  // { lang : "no-NO", name:	"Noruega", flag : "https://flagcdn.com/no.svg"},
  // { lang : "ar-OM", name:	"Omán", flag : "https://flagcdn.com/om.svg"},
  // { lang : "ar-PS", name:	"Territorio Palestino Ocupado", flag : "https://flagcdn.com/ps.svg"},
  // { lang : "pl-PL", name:	"Polonia", flag : "https://flagcdn.com/pl.svg"},
  // { lang : "pt-PT", name:	"Portugal", flag : "https://flagcdn.com/pt.svg"},
  // { lang : "ar-QA", name:	"Katar", flag : "https://flagcdn.com/qa.svg"},
  // { lang : "fr-RE", name:	"Reunión", flag : "https://flagcdn.com/re.svg"},
  // { lang : "ro-RO", name:	"Rumania", flag : "https://flagcdn.com/ro.svg"},
  // { lang : "ru-RU", name:	"Rusia", flag : "https://flagcdn.com/ru.svg"},
  // { lang : "rw-RW", name:	"Ruanda", flag : "https://flagcdn.com/rw.svg"},
  // { lang : "fr-PM", name:	"San Pedro y Miquelón", flag : "https://flagcdn.com/pm.svg"},
  // { lang : "sm-WS", name:	"Samoa", flag : "https://flagcdn.com/ws.svg"},
  // { lang : "it-SM", name:	"San Marino", flag : "https://flagcdn.com/sm.svg"},
  // { lang : "pt-ST", name:	"Santo Tomé y Príncipe", flag : "https://flagcdn.com/st.svg"},
  // { lang : "ar-SA", name:	"Arabia Saudita", flag : "https://flagcdn.com/sa.svg"},
  // { lang : "fr-SN", name:	"Senegal", flag : "https://flagcdn.com/sn.svg"},
  // { lang : "sr-RS", name:	"Serbia", flag : "https://flagcdn.com/rs.svg"},
  // { lang : "fr-SC", name:	"Seychelles", flag : "https://flagcdn.com/sc.svg"},
  // { lang : "sk-SK", name:	"Eslovaquia", flag : "https://flagcdn.com/sk.svg"},
  // { lang : "sl-SI", name:	"Eslovenia", flag : "https://flagcdn.com/si.svg"},
  // { lang : "so-SO", name:	"Somalia", flag : "https://flagcdn.com/so.svg"},
  // { lang : "af-ZA", name:	"Sudáfrica", flag : "https://flagcdn.com/za.svg"},
  // { lang : "si-LK", name:	"Sri Lanka", flag : "https://flagcdn.com/lk.svg"},
  // { lang : "ar-SD", name:	"Sudán", flag : "https://flagcdn.com/sd.svg"},
  // { lang : "nl-SR", name:	"Surinam", flag : "https://flagcdn.com/sr.svg"},
  // { lang : "no-SJ", name:	"Svalbard y Jan Mayen", flag : "https://flagcdn.com/sj.svg"},
  // { lang : "sv-SE", name:	"Suecia", flag : "https://flagcdn.com/se.svg"},
  // { lang : "de-CH", name:	"Suiza", flag : "https://flagcdn.com/ch.svg"},
  // { lang : "ar-SY", name:	"Siria", flag : "https://flagcdn.com/sy.svg"},
  // { lang : "zh-TW", name:	"Taiwán", flag : "https://flagcdn.com/tw.svg"},
  // { lang : "tg-TJ", name:	"Tayikistán", flag : "https://flagcdn.com/tj.svg"},
  // { lang : "sw-TZ", name:	"Tanzania", flag : "https://flagcdn.com/tz.svg"},
  // { lang : "th-TH", name:	"Tailandia", flag : "https://flagcdn.com/th.svg"},
  // { lang : "fr-TG", name:	"Togo", flag : "https://flagcdn.com/tg.svg"},
  // { lang : "ar-TN", name:	"Túnez", flag : "https://flagcdn.com/tn.svg"},
  // { lang : "tr-TR", name:	"Turquía", flag : "https://flagcdn.com/tr.svg"},
  // { lang : "tk-TM", name:	"Turkmenistán", flag : "https://flagcdn.com/tm.svg"},
  // { lang : "uk-UA", name:	"Ucrania", flag : "https://flagcdn.com/ua.svg"},
  // { lang : "ar-AE", name:	"Emiratos Árabes Unidos", flag : "https://flagcdn.com/ae.svg"},
  // { lang : "uz-UZ", name:	"Uzbekistán", flag : "https://flagcdn.com/uz.svg"},
  // { lang : "bi-VU", name:	"Vanuatu", flag : "https://flagcdn.com/vu.svg"},
  // { lang : "la-VA", name:	"Estado de la Ciudad del Vaticano (Santa Sede)", flag : "https://flagcdn.com/va.svg"},
  // { lang : "vi-VN", name:	"Vietnam", flag : "https://flagcdn.com/vn.svg"},
  // { lang : "fr-WF", name:	"Wallis y Futuna", flag : "https://flagcdn.com/wf.svg"},
  // { lang : "ar-YE", name:	"Yemen", flag : "https://flagcdn.com/ye.svg"},
]

export { countries as default }
