import ApiService from "./api.service"

/**
 * DiseaseServices es un objeto que almacena todas las funciones que se utilizan
 *
 * getAll --> función que a través de una petición ._GET se accede a un enterpoint específico y solicita un listado de enfermedades con una parametros
 * devuelve la lista enfermedades
 *
 * save --> función que realiza una petición POST al enterpoint, para enviar los datos junto con el idioma que se especificó  (información para crear o actualizar un recursos en el servidor).
 * la función espera la RÉPONSE de la api y una vez que la recibe , devuelve los datos guardados o actualizados obtenidos.
 *
 * remove --> función que realiza una petición GET al enterpoint, a la cual le solicita que el id con el idioma especificado se elimine ,
 * la función espera la RÉPONSE de la api y muestra la respuesta.
 *
 * getSuplementInteraction --> función que realiza una petición GET al enterpoint al cual se le especifica el id del suplemento, le solicita la interacción entre el suplemento y la enfermedad
 * la función devuelve los datos solicitados de la interacción
 *
 * saveSuplementInteraction --> función que realiza una petición POST al enterpoint al cual se le especifica el id del suplemento, le agrega información sobre la interacción de suplemento y la enfermedad
 * la función devuelve éxito o error si se guardo o no la info
 *
 * removeSuplementInteraction --> función que realiza una petición POST al enterpoint al cual se le especifica el id del suplemento, elimina la interacción de suplemento y la enfermedad
 * la función devuelve éxito o error si se elimino o no la info
 * */

const DiseaseService = {
  getAll: async function(params) {
    const response = await ApiService._get("/api/admin/disease/get-all", params)
    return response.data
  },

  save: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/disease/save",
      params,
      lang
    )
    return response.data
  },

  remove: async function(id, lang = null) {
    const response = await ApiService.get(
      "/api/admin/disease/delete?id=" + id,
      lang
    )
    return response.data
  },

  getSupplementInteraction: async function(id, lang = null) {
    const response = await ApiService.get(
      "/api/admin/disease/get-supplement-interaction?supplement_id=" + id,
      lang
    )
    return response.data
  },

  saveSupplementInteraction: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/disease/save-supplement-interaction",
      params,
      lang
    )
    return response.data
  },

  removeSupplementInteraction: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/disease/delete-supplement-interaction",
      params,
      lang
    )
    return response.data
  }
}

export default DiseaseService
