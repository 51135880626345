
/**
 *
 * getAll --> función que a través de una petición GET al enterpoint, solicita
 *
 * getAllCategories --> función que a través de una petición GET al enterpoit, solicita la lista de categorias
 *
 * createTerm --> función que a través de una petición POST al enterpoint, crea un nuevo alimento con los params mandados
 * retorna el alimento creado
 *
 * saveTerm --> función que a través de una petición POST al enterpoint, solicita guardar o actualizar información con los datos que obtiene de los params
 * retorna el alimento actualizado
 */

import ApiService from "./api.service"
import ApiMockService from "./api.mock.service"

const GlossaryService = {
  getAllTerms: async function(params) {
    if (params.language==="es"){
      params.language = "";
    }
    if(params.category_id===0){
      params.category_id = "";
    }
    const response = await ApiService.get(
      "api/glossary/terms/all?page="+params.page+"&limit="+params.limit+"&search="+params.search+"&category_id="+params.category_id+"&status="+params.status+"&language="+params.language
    )
    return response.data
  },
  getAllCategories: async function(params) {
    const response = await ApiService.get(
      "api/glossary/categories/all?page=1&limit=20&keyword="
    )
    return response.data
  },
  getTerm: async function(id, lang = null) {
    const response = await ApiService.get("api/glossary/terms/id/" + id, lang)
    return response.data
  },
  createTerm: async function(params) {
    const response = await ApiService.post(
      "api/glossary/terms/create",
      params
    )
    return response.data
  },
  updateTerm: async function(id, params) {
    const response = await ApiService.put("api/glossary/terms/update/" + id, params)
    return response.data
  }

}

export default GlossaryService
