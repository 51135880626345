<template>
    <div>
        <a class="flex items-center justify-center mr-3 my-8" href="javascript:;" @click="generateReport()">
            <CheckSquareIcon class="w-4 h-4 mr-1" />
            {{ $t('views.pdf.text1') }}
        </a>
    </div>
    <div class="w-9/12 mx-auto bg-white mt-8 rounded-2xl document pt-9">
        <div class=" pb-9 px-20" ref="document">
            <div class="text-custom-gray-6 w-full">
                <div class="items-baseline text-center boxx">
                    <p class="text-lg text-custom-gray-7 font-black">{{ $t('views.pdf.text2') }}</p>
                </div>
                <div class="flex mt-16 text-lg boxx">
                    <div class="bg-custom-green-1 w-1 mr-5"></div>
                    <span class="font-bold">{{ $t('views.pdf.text3') }}</span>
                </div>
                <div class="grid grid-cols-3 mt-8 boxx">
                    <div>
                        <div class="font-bold">{{ $t('views.pdf.text4') }}</div>
                        <div>{{ user.session_name }}</div>
                    </div>
                    <div>
                        <div class="font-bold">{{ $t('views.pdf.text5') }}</div>
                        <div>{{ user.num_analysis }}</div>
                    </div>
                    <div>
                        <div class="font-bold">{{ $t('views.pdf.text6') }}</div>
                        <div>{{ today }}</div>
                    </div>
                </div>
                <div class="flex mt-14 text-lg boxx">
                    <div class="bg-custom-green-1 w-1 mr-5"></div>
                    <span class="font-bold">{{ $t('views.pdf.text7') }}</span>
                </div>
                <template v-if="loaded && loadedLabs">
                    <div class="pl-10 pt-8 boxx" v-if="labs != ''">
                        <div class="flex items-center">
                            <div class="w-2 h-2 bg-gray-600 rounded-full mr-5"></div>
                            <span class="text-lg font-bold w-full">{{ $t('views.pdf.text8') }}</span>
                        </div>
                        <div class="pl-10 pt-3">
                            <div class="flex">
                                <div class="w-2 h-2 border-1 border-gray-600 bg-transparent rounded-full mr-5 mt-2">
                                </div>
                                <span class="w-full">{{ labs }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="pl-10 pt-8">
                        <div class="flex items-center boxx">
                            <div class="w-2 h-2 bg-gray-600 rounded-full mr-5"></div>
                            <span class="text-lg font-bold">{{ $t('views.pdf.text9') }}</span>
                        </div>
                        <template v-for="(item, itemKey) in recommendations" :key="itemKey">
                            <div class="pl-10 " v-if="item.items.length > 0">
                                <div class="flex boxx py-8 items-center">
                                    <div class="w-2 h-2 border-1 border-gray-600 bg-transparent rounded-full mr-5 ">
                                    </div>
                                    <span class="w-full font-bold">{{ item.title }}</span>
                                </div>
                                <template v-for="(subitem, subitemKey) in item.items" :key="subitemKey">
                                    <div class="pl-10 "
                                        v-if="(itemKey === 'foods' && subitem.items.length > 0) || itemKey !== 'foods'">
                                        <div class="flex boxx "
                                            :class="itemKey === 'foods' && subitemKey > 0 ? ' pt-12' : ' pt-2'">
                                            <div class="w-2 h-2 bg-gray-600 mr-5 mt-2"></div>
                                            <span class="w-full">{{ subitem.title }} </span>
                                        </div>
                                        <div class="pl-10"
                                            v-if="subitem.items.length > 0 && typeof subitem.items[0] === 'object'">
                                            <template v-for="(subI, subIKey) in subitem.items" :key="subIKey">
                                                <div class="flex boxx items-center "
                                                    :class="itemKey === 'foods' ? 'pt-6 pb-4' : ''"
                                                    v-if="subI.items.length > 0 && hasItems(subI.items)">
                                                    <div class="w-2 h-2 bg-gray-600 rounded-full mr-5"></div>
                                                    <span class="w-full font-bold">{{ subI.title }}</span>
                                                </div>
                                                <template v-for="(subII, subIIKey) in subI.items" :key="subIIKey">
                                                    <div v-if="typeof subII === 'object' && subII.items.length > 0"
                                                        class="mt-2">
                                                        <div class="flex pl-10 boxx items-center">
                                                            <div
                                                                class="w-2 h-2 border-1 border-gray-600 bg-transparent rounded-full mr-5 ">
                                                            </div>
                                                            <span class="w-full">{{ subII.title }}</span>
                                                        </div>
                                                        <div class="mt-2 pl-8">
                                                            <template v-for="(subIII, subIIIKey) in subII.items"
                                                                :key="subIIKey">
                                                                <div class="flex mt-2 pl-10 boxx items-center">
                                                                    <div class="w-1 h-1 border border-gray-600 mr-5">
                                                                    </div>
                                                                    <span class="w-full">{{ subIII }}</span>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>

                                                    <div v-if="typeof subII !== 'object'"
                                                        class="flex mt-2 pl-10 boxx items-center">
                                                        <div
                                                            class="w-2 h-2 border-1 border-gray-600 bg-transparent rounded-full mr-5 ">
                                                        </div>
                                                        <span class="w-full">{{ subII }}</span>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="pl-10" v-else>
                                            <template v-for="(subI, subIKey) in subitem.items" :key="subIKey">
                                                = <div class="flex mt-2 boxx items-center">
                                                    <div class="w-2 h-2 bg-gray-600 rounded-full  mr-5 "></div>
                                                    <span class="w-full">{{ subI }}</span>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                    <div class="pl-10 mt-8 hidden boxx">
                        <div class="flex items-center">
                            <div class="w-2 h-2 bg-gray-600 rounded-full mr-5"></div>
                            <span class="text-lg font-bold">{{ $t('views.pdf.text10') }}</span>
                        </div>
                        <div class="pl-10 mt-3">
                            <div class="flex">
                                <div class="w-2 h-2 border-1 border-gray-600 bg-transparent rounded-full mr-5 mt-2">
                                </div>
                                <span class="w-full">{{ conclusions }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-if="!loaded || !loadedLabs" class="mt-5">
                    {{ $t("views.loading") }}
                    <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.cursor {
    cursor: pointer !important;
}

.table td.p-0 {
    padding: 0px !important;
}
</style>

<style>
.tom-select .ts-dropdown .option.active {
    background: #6c869a !important;
}

.top-bar .breadcrumb>a:not(.breadcrumb--active) {
    color: #6c869a !important;
}

.detail-food {
    display: grid;
    grid-auto-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: initial;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.pagination li,
.pagination a,
.pagination a:focus,
.pagination a:active {
    outline: none !important;
}

.box {
    background-color: transparent;
}

.document {
    max-width: 790px;
}
</style>

<script>
import QuizService from "/src/services/quiz.service";
import LabService from "/src/services/lab.service";
import html2pdf from '@/assets/js/html2pdf.bundle.min.js'
import moment from 'moment';

var index = 7

export default {
    components: {
    },
    setup() { },
    data() {
        return {
            user: (localStorage.userItem) ? JSON.parse(localStorage.userItem) : {},
            today: moment().format("DD/MM/YYYY"),
            recommendations: {},
            conclusions: "",
            loaded: false,
            loadedLabs: false,
            labs: "",
            break: 35
        }
    },
    watch: {
        loaded: function () {
            if (this.loaded && this.loadedLabs) {
                this.setBreakPage()
            }
        },
        loadedLabs: function () {
            if (this.loaded && this.loadedLabs) {
                this.setBreakPage()
            }
        },
    },
    created() {
        index = 7
        window.vue = this
        this.getQuizId()
    },
    mounted() {
        this.user.num_analysis = "00000369"
        this.recommendations = {
            //   healthGoals : {
            //         title : " Secciones de la plataforma que te recomendamos recorrer para mejorar tu perfil de salud:",
            //         items: []
            //   },
            foods: {
                title: this.$t('views.pdf.text11'),
                class: "",
                items: [
                    {
                        title: this.$t('views.pdf.text12'),
                        items: [],
                    },
                    {
                        title: this.$t('views.pdf.text13'),
                        items: [],
                    },
                    {
                        title: this.$t('views.pdf.text14'),
                        items: [],
                    }
                ],
            },
            supplemnts: {
                title: this.$t('views.pdf.text15'),
                items: [],
            },
            supplementsToSuspend: {
                title: this.$t('views.pdf.text16'),
                items: [],
            },
            supplementsToReduce: {
                title: this.$t('views.pdf.text17'),
                items: [],
            },
            exercises: {
                title: this.$t('views.pdf.text18'),
                items: [],
                class: "",
            },
            sports: {
                title: this.$t('views.pdf.text29'),
                items: [],
                class: "",
            },
            exercisesToReduce: {
                title: this.$t('views.pdf.text19'),
                items: [],
            },
            exercisesToSuspend: {
                title: this.$t('views.pdf.text20'),
                items: [],
            },
            lab: {
                title: this.$t('views.pdf.text21'),
                items: [],
            }
        }
        this.conclusions = ``
    },
    methods: {
        getQuizId() {
            QuizService.getQuizMenuToEdit({
                user_id: this.user.id
            }).then((response) => {
                var quizzes = []
                response.map((item) => {
                    if (item.quiz_id) {
                        quizzes.push({ quiz_id: item.quiz_id })
                    }
                    if (item.quizzes) {
                        item.quizzes.map((a) => {
                            if (a.quiz_id) {
                                quizzes.push({ quiz_id: a.quiz_id })
                            }
                            if (a.quizzes) {
                                a.quizzes.map((b) => {
                                    if (b.quiz_id) {
                                        quizzes.push({ quiz_id: b.quiz_id })
                                    }
                                })
                            }
                        })
                    }
                })

                this.getRecommendations(quizzes)
                this.getLabs()

            })
        },
        async getRecommendations(quizzes) {
            var data = await QuizService.getRecommendationsPdf({
                user_id: this.user.id,
                //filter: quizzes,
                clear_cache: 1,
                client_hour: 23,
                hour: 23,
            });


            /** healthGoals */
            // this.recommendations.healthGoals.items = data.healthGoals.map(hG => { return {title : hG.name, items: []}})
            /** healthGoals */

            /** Foods */
            var foods = data.foodTree

            foods = this.sortGroupFoods(foods, "ADD", true)
            this.recommendations.foods.items[0].items = foods

            foods = data.foodTree
            foods = this.sortGroupFoods(foods, "REDUCE")
            this.recommendations.foods.items[1].items = foods

            foods = data.foodTree
            foods = this.sortGroupFoods(foods, "SUSPEND")
            this.recommendations.foods.items[2].items = foods

            // this.recommendations.foods = data.foodTree



            /** Foods */

            /** Supplements */
            var supplemnts = data.supplements //.filter(s => s.increase === true)
            this.recommendations.supplemnts.items = supplemnts.map(s => { return { title: s.name, items: [] } })
            this.recommendations.supplementsToSuspend.items = data.supplements_to_suspend.map(s => { return { title: s.name, items: [] } })
            this.recommendations.supplementsToReduce.items = data.supplements_to_reduce.map(s => { return { title: s.name, items: [] } })
            /** Supplements */

            /** Exercise */
            this.recommendations.exercises.items = data.suggested_exercises.map(s => { return { title: s.name, items: [] } })
            this.recommendations.sports.items = data.suggested_sports.map(s => { return { title: s.name, items: [] } })
            // this.recommendations.exercisesToSuspend.items = data.exercisesToSuspend.map(s => { return {title : s.name, items: []}})
            // this.recommendations.exercisesToReduce.items = data.exercisesToReduce.map(s => { return {title : s.name, items: []}})
            /** Exercise */

            data = await QuizService.getRecommendations({
                user_id: this.user.id,
                filter: quizzes
            });

            /** Lab */
            this.recommendations.lab.items = data.labItems.map(s => { return { title: s.name, items: [] } })
            /** Lab */

            this.loaded = true

        },
        async getLabs(quizzes) {
            var data = await LabService.getUserLabResult({
                user_id: this.user.id
            });
            if (data && data.length > 0)
                var notShow = ["SUBOPTIMO_BAJO", "OPTIMO", "SUBOPTIMO_ALTO"]
            var labs = data[0].filter(lab => !notShow.includes(lab.result))
            labs = labs.map(l => l.lab_item_name)
            this.labs = labs.join(", ")
            this.loadedLabs = true
        },
        generateReport() {
            html2pdf(this.$refs.document, {
                margin: [0.8, 0],
                filename: 'ilongevity.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 192, letterRendering: true },
                jsPDF: { unit: 'in', format: 'A4' },
                pagebreak: { before: '.beforeClass' } //mode : ['css', 'legacy'],
            })
        },
        sortGroupFoods(foods, type, primary = false) {
            var catGroups = [
                { title: this.$t('views.pdf.text26'), id: "vegetables", items: [] },
                { title: this.$t('views.pdf.text22'), id: "meats", items: [] },
                { title: this.$t('views.pdf.text23'), id: "condiments_spices", items: [] },
                { title: this.$t('views.pdf.text24'), id: "fish_seafood", items: [] },
                { title: this.$t('views.pdf.text25'), id: "fruits", items: [] },
                { title: this.$t('views.pdf.text27'), id: "grains_seeds", items: [] },
                { title: this.$t('views.pdf.text28'), id: "animal_origin", items: [] },
                { title: "Drinks", id: "drinks", items: [] }
            ]
            const catNotInclude = primary ? [64, 809] : [] // Cereales
            const foodNotInclude = primary ? [809] : [] // Soja
            function compareName(a, b) {
                if (a < b) return -1
                if (a > b) return 1;
                return 0;
            }


            catGroups.map(catGroup => {
                if (foods.hasOwnProperty(catGroup.id)) {
                    catGroup.items = foods[catGroup.id].items.find(f => f.type === type).items
                    catGroup.items = catGroup.items.map(f => {
                        f.title = f.category_name
                        return f
                    })

                    catGroup.items = catGroup.items.map(f => {
                        f.items = f.items.map(it => it.name)
                        f.items.sort(compareName)
                        return f
                    })
                    // catGroup.items.sort(compareName)
                }

                return catGroup
            })

            return catGroups
        },
        setBreakPage() {
            var h = 0
            setTimeout(function () {
                var boxes = document.getElementsByClassName("boxx");
                for (let i = 0; i < boxes.length; i++) {
                    let style = getComputedStyle(boxes[i]);
                    let mt = parseInt(style.marginTop);
                    h = h + boxes[i].offsetHeight + mt
                    if (h >= 900) {
                        boxes[i].classList.add("beforeClass")
                        boxes[i].classList.add(h)
                        h = 0
                    }
                }
            }, 60)

        },
        hasItems(items) {
            if (!items || items.length == 0) return false;

            var has = false
            items.map(item => {
                if (item.items.length > 0) {
                    has = true
                }
            })

            return has;

        }
    }
}
</script>
