import faker from "./faker"
import helper from "./helper"
import lodash from "./lodash"
import lab_langs from "./lab_langs"
import global from "./global"

export default app => {
  app.use(faker)
  app.use(helper)
  app.use(lodash)
  app.use(global)
  lab_langs
}
