<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h2 class="intro-y text-lg font-medium mt-10">Calcular</h2>
    </div>
    <p>A continuación podrás calcular la fase del ciclo menstrual, tomando en cuenta que el ciclo es regular y tiene una
      duracion de 28 dias.</p>

    <div class="grid grid-cols-12 gap-2 mt-5">
      <div class="col-span-4">
        <label for="vertical-form-2" class="form-label">Último periodo</label>
        <Litepicker v-model="lastPeriod" class="datepicker form-control w-56 block" placeholder="Último periodo">
        </Litepicker>
      </div>
      <div class="col-span-4">
        <label for="vertical-form-2" class="form-label">Fecha actual</label>
        <Litepicker v-model="currentDate" class="datepicker form-control w-56 block" placeholder="Fecha actual">
        </Litepicker>
      </div>
      <button type="button" class="btn btn-primary w-24 h-10 col-span-4 mt-6" data-v-12c4b311="" @click="calculate">
        <span data-v-12c4b311="">Calcular</span>
      </button>
    </div>
    <template v-if="cycle != '' && init">
      <div class="alert alert-secondary-soft show flex items-center my-5" role="alert">
        {{ cycle }}
      </div>
    </template>
  </div>
</template>

<style scoped>
.cursor {
  cursor: pointer !important;
}

.table td.p-0 {
  padding: 0px !important;
}
</style>

<style>
.tom-select .ts-dropdown .option.active {
  background: #6c869a !important;
}

.top-bar .breadcrumb>a:not(.breadcrumb--active) {
  color: #6c869a !important;
}

.detail-food {
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: initial;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pagination li,
.pagination a,
.pagination a:focus,
.pagination a:active {
  outline: none !important;
}
</style>

<script>
import Calendar from "@/components/calendar/Main.vue"

export default {
  components: {
    Calendar
  },
  setup() { },
  data() {
    return {
      foods: [],
      lastPeriod: "",
      currentDate: "",
      cycle: "",
      init: false
    }
  },
  watch: {
    lastPeriod: function () {
      this.calculateCycle();
    },
    currentDate: function () {
      this.calculateCycle();
    }
  },
  created() {
    window.vue = this
  },
  methods: {
    calculate() {
      this.init = true
      this.calculateCycle()
    },
    calculateCycle() {
      var last_period_date = new Date(this.lastPeriod)
      var current_date = new Date(this.currentDate)
      var cycle_duration = 28

      var d_time = current_date.getTime() - last_period_date.getTime();
      var d_days = d_time / (1000 * 3600 * 24);

      var total_cycles = parseInt(d_days / cycle_duration)
      var actual_cycle_days = d_days - (total_cycles * cycle_duration)

      var ovulation = cycle_duration / 2;
      var end_folicular = ovulation - 3
      var start_lutea = ovulation + 3

      switch (true) {
        case (actual_cycle_days > 0 && actual_cycle_days < end_folicular):
          this.cycle = " FASE FOLICULAR"
          break;
        case (actual_cycle_days >= end_folicular && actual_cycle_days <= start_lutea):
          this.cycle = " FASE DE OVULACIÓN"
          break;
        case (actual_cycle_days > start_lutea):
          this.cycle = " FASE LUTEA"
          break;
        default:
          this.cycle = "CICLO IRREGULAR"
      }


    }
  }
}
</script>
