<template>
  <div ref="fileUploadRef" v-file-upload="{ props, emit }" class="dropzone relative">
    <div class="dz-message">
      <h1 class="text-2xl opacity-40">{{ $t('components.dropzone.text1') }}</h1>
      <h2 class="text-1xl opacity-40">{{ $t('components.dropzone.text2') }}</h2>
      <h2 class="text-1xl opacity-40">{{ $t('components.dropzone.text3') }}</h2>
    </div>
  </div>
</template>
<style>
.dropzone .dz-preview div.dz-image,
.dropzone .dz-preview.dz-file-preview div.dz-image {
  border-radius: 0px;
}

.dropzone div.dz-preview.dz-image-preview,
.dropzone div.dz-preview.dz-file-preview {
  background-color: transparent;
  border: solid 1px #6c869a;
  padding: 15px;
}

.dropzone .dz-preview .dz-details div.dz-filename {
  display: none !important;
}

.dropzone .dz-preview a.dz-remove {
  margin-top: 10px;
  visibility: hidden;
  text-align: center;
  position: relative;
}

.dropzone .dz-preview a.dz-remove::before {
  content: "Remover";
  visibility: visible;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -25px;
}
</style>
<script>
import { defineComponent, inject, ref, onMounted } from 'vue'
import { init } from './index'

export default defineComponent({
  directives: {
    fileUpload: {
      mounted(el, { value }) {
        init(el, value.props)
      }
    }
  },
  data() {
    return {
      total: 0
    }
  },
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    refKey: {
      type: String,
      default: null
    },
    events: {
      type: Object,
      default: () => ({
        addedFile: function (file) {
        },
        removedFile: function (file) {
        },
        showMessage: function (file) {
        },
      }),
    },
    existFiles: {
      type: Array,
      default: []
    },
  },
  setup(props, context) {
    const fileUploadRef = ref()
    const bindInstance = () => {
      if (props.refKey) {
        const bind = inject(`bind[${props.refKey}]`)
        if (bind) {
          bind(fileUploadRef.value)
        }
      }
    }

    onMounted(() => {
      bindInstance()
    })

    return {
      props,
      ...context,
      fileUploadRef
    }
  }
})
</script>
